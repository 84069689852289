import { Flex, Result } from 'antd';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';

import { Button } from '@/shared/ui/Button';

export const NotFoundPage = observer(function NotFoundPage() {
	const navigate = useNavigate();
	return (
		<Flex flex="1 100%" align="center" justify="center">
			<Helmet>
				<title>SECAI AIR</title>
			</Helmet>
			<Result
				status="404"
				title="404"
				subTitle="К сожалению, страница, которую вы посетили, не существует."
				extra={
					<Button onClick={() => navigate('/')} variant="primary">
						На главную
					</Button>
				}
			/>
		</Flex>
	);
});
