import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import { v4 } from 'uuid';

import {
	ChatDto,
	ChatsResponseDto,
	ChatUserDto,
	getChatsResponseDto,
} from '@/widgets/messenger/lib/ChatDto';

import { getUsers } from './users';
import { localStorageKeys, withLocalStorage } from './withLocalStorage';

const USERS = getUsers();

const CHATS_PRIVATE: ChatDto[] = [
	{
		id: v4(),
		type: 'private',
		title: 'Заголовок чата',
		isFavorite: true,
		isIncident: false,
	},
	{
		id: v4(),
		type: 'private',
		title: 'Вот такой Заголовок чата',
		isFavorite: false,
		isIncident: false,
	},
];

const CHATS_CHANNEL: ChatDto[] = [
	{
		id: v4(),
		type: 'channel',
		title: 'Вредоносный объект был найден и не вылечен в течение 5 минут',
		isFavorite: false,
		isIncident: true,
	},
	{
		id: v4(),
		type: 'channel',
		title: 'Вредоносный объект был найден и не вылечен в течение 5 минут',
		isFavorite: false,
		isIncident: true,
	},
	{
		id: v4(),
		type: 'channel',
		title: 'Вредоносный объект был найден и не вылечен в течение 5 минут',
		isFavorite: true,
		isIncident: true,
	},
	{
		id: v4(),
		type: 'channel',
		title: 'Вредоносный объект был найден и не вылечен в течение 5 минут',
		isFavorite: true,
		isIncident: true,
	},
];

export const CHAT_USERS: ChatUserDto[] = USERS.map((v) => {
	return {
		id: v.id,
		username: v.username,
		surname: v.surname,
		name: v.name,
		patronymic: v.patronymic,
		avatar: v.avatar,
		isBot: false,
		isIncident: false,
	};
});

const CHAT_INCIDENT_USERS = [
	{
		id: v4(),
		username: 'cool_bot777',
		surname: 'Бот',
		name: 'Бот',
		patronymic: 'Бот',
		avatar: 'https://placehold.jp/32x32.png',
		isBot: true,
		isIncident: true,
	},
	{
		id: v4(),
		username: 'incident',
		surname: 'Вредоносный объект был найден и не вылечен в течение 5 минут',
		name: '',
		patronymic: '',
		avatar: '',
		isBot: true,
		isIncident: true,
	},
];

function generate() {
	const chats = getChatsResponseDto({
		chats: CHATS_PRIVATE.concat(CHATS_CHANNEL),
		users: CHAT_USERS.concat(CHAT_INCIDENT_USERS),
		messages: new Array(5)
			.fill(0)
			.map(() => {
				return {
					id: v4(),
					from: sample(CHAT_USERS)!.id,
					chat: sample(CHATS_PRIVATE)!.id,
					date: faker.date
						.between({
							from: Date.now() - 1000 * 60 * 60 * 24 * 7,
							to: Date.now(),
						})
						.toISOString(),
					text: faker.lorem.paragraph(),
					isPinned: false,
				};
			})
			.concat(
				new Array(5).fill(0).map(() => {
					return {
						id: v4(),
						from: sample(CHAT_INCIDENT_USERS)!.id,
						chat: sample(CHATS_CHANNEL)!.id,
						date: faker.date
							.between({
								from: Date.now() - 1000 * 60 * 60 * 24 * 7,
								to: Date.now(),
							})
							.toISOString(),
						text: faker.lorem.paragraph(),
						isPinned: false,
					};
				}),
			),
	});

	chats.messages.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

	return chats;
}

const chats = withLocalStorage(localStorageKeys.chats, generate);

export function getChats() {
	return chats;
}
export function mockRequestChats(): ChatsResponseDto {
	return chats;
}

// export function mockRequestChat(id: string): ChatDto {
// 	const chat = chats.chats.find((v) => v.id === id);
// 	if (chat) {
// 		return chat;
// 	}
// 	throw new Error('Not Found');
// }

// export function mockUpdateChat(id: string, updateDto: Partial<ChatsResponseDto>): ChatDto {
// 	const chat = chats.chats.find((v) => v.id === id);
// 	if (chat) {
// 		Object.assign(chat, updateDto);
// 		saveState(localStorageKeys.chats, chat);
// 		return chat;
// 	}
// 	throw new Error('Not Found');
// }
