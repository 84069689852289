import Cookie from 'js-cookie';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { ValidationError } from 'myzod';

import { UserEntity } from '@/entities/user';
import { NotAuthorizedError } from '@/features/auth/lib/error';
import { localStorageKeys } from '@/shared/api/mock/withLocalStorage';
import type { RepositoriesManager } from '@/shared/lib/collections/RepositoriesManager';
import { RequestWithState } from '@/shared/lib/collections/RequestWithState';
import { loadState, saveState } from '@/shared/lib/localStorage';

import { removeState } from '@/shared/lib/localStorage/removeState';

import { AccessToken, getAccessToken } from '../../lib/getAccessToken';
import { LoginResponse, loginResponseSchema } from '../../lib/loginResponseSchema';
import { AuthApi } from '../api';

export class AuthModel {
	isAuth = false;
	token: AccessToken;
	api = new AuthApi();
	loginRequestState = new RequestWithState(this.api.login.bind(this.api));
	private _user: UserEntity | null = null;
	private storageKey = '@/features/auth/responce-data';
	constructor(public repositories: RepositoriesManager) {
		makeAutoObservable(this, { logout: action.bound });
		const initialData = this.loadResponse();
		this.token = getAccessToken();
		if (initialData) {
			// this.isAuth = this.token.value !== null;
			this.isAuth = true;
			this.setUserDto(initialData);
		}
	}

	get user(): UserEntity {
		if (this._user) {
			return this._user;
		}
		throw new NotAuthorizedError();
	}

	get userPermissions() {
		if (this._user) {
			return this._user.permissions;
		}
		return [];
	}

	async logout() {
		this.isAuth = false;
		removeState(this.storageKey);
		Cookie.remove('soar-access-token');
		/** TODO: DEMO очистка данных при выходе */
		Object.values(localStorageKeys).forEach((key) => {
			localStorage.removeItem(key);
		});
	}

	async login(username: string, password: string) {
		const userDto = await this.loginRequestState.request({
			username,
			password,
		});
		if (userDto) {
			runInAction(() => {
				this.isAuth = true;
				this.token = getAccessToken();
				this.saveResponse(userDto);
				this.setUserDto(userDto);
			});
		}
	}

	async loadProfile() {
		const userDto = await this.api.getProfile();
		this.saveResponse(userDto);
		this.setUserDto(userDto);
	}

	private setUserDto(userDto: LoginResponse) {
		if (!this._user || this._user.id !== userDto.id) {
			this._user = new UserEntity(userDto.id);
		}
		this._user.updateFromJson(userDto);
	}

	private saveResponse(data: LoginResponse) {
		saveState(this.storageKey, data);
	}

	private loadResponse(): LoginResponse | null {
		const data = loadState(this.storageKey);
		const result = loginResponseSchema.try(data);
		if (result instanceof ValidationError) {
			return null;
		}
		return result;
	}
}
