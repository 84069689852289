import { action, makeObservable, observable, ObservableMap } from 'mobx';

import { Entity } from '../Entity';
import { getWithSet } from '../getWithSet';

export class Repository<TEntity extends Entity<any>> {
	constructor(private createItem: (itemID: string) => TEntity) {
		makeObservable<typeof this, 'registry'>(this, {
			registry: observable,
			get: action,
			removeOne: action,
		});
	}
	protected registry: ObservableMap<string, TEntity> = observable.map<string, TEntity>([], {
		deep: false,
	});
	/**
	 * Получить модель сущности по ID
	 * @param itemID - ID сущности
	 * @returns всегда возвращает модель сущности, если модели нет, создает новую
	 */
	public get(itemID: string): TEntity {
		return getWithSet(this.registry, itemID, () => this.createItem(itemID));
	}
	/**
	 * Удалить модель сущности по ID из памяти
	 * @param itemID - ID сущности
	 */
	public removeOne(itemID: string): void {
		this.registry.delete(itemID);
	}
}
