import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.91575 16.8033C8.82415 16.8033 9.56055 15.7543 9.56055 14.4603C9.56055 13.1662 8.82415 12.1172 7.91575 12.1172C7.00735 12.1172 6.27095 13.1662 6.27095 14.4603C6.27095 15.7543 7.00735 16.8033 7.91575 16.8033Z"
			fill="url(#paint0_linear_1392_18556)"
		/>
		<path
			d="M16.2055 16.8033C17.1139 16.8033 17.8503 15.7543 17.8503 14.4603C17.8503 13.1662 17.1139 12.1172 16.2055 12.1172C15.2971 12.1172 14.5607 13.1662 14.5607 14.4603C14.5607 15.7543 15.2971 16.8033 16.2055 16.8033Z"
			fill="url(#paint1_linear_1392_18556)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.4691 2.55405C12.9944 2.39066 13.3765 1.89345 13.3765 1.30544C13.3765 0.584465 12.8021 0 12.0935 0C11.385 0 10.8106 0.584465 10.8106 1.30544C10.8106 1.86957 11.1623 2.35012 11.6547 2.53251L11.4987 3.8661C10.7954 3.99428 10.2205 4.35753 9.94112 4.83425C8.20165 5.16467 5.98379 5.93944 4.18571 7.57896C3.35598 8.33553 2.69883 9.19655 2.18525 10.0853C1.39807 10.0209 0.543565 10.7299 0.173979 11.8139C-0.190813 12.8838 0.03771 13.9646 0.675719 14.4208C0.481132 15.6954 0.493356 16.965 0.78202 17.9817C1.65903 21.0707 5.38615 24 12.0606 24C18.7801 24 22.2805 21.1837 23.2798 18.0697C23.6574 16.8929 23.6301 15.4742 23.3699 14.1202C23.9982 13.6446 24.1925 12.5514 23.7889 11.4905C23.374 10.3998 22.4691 9.71674 21.6735 9.8417C21.1406 9.00669 20.4798 8.21061 19.674 7.52231C18.0443 6.13018 16.4918 5.39895 15.1128 5.02389C14.785 4.93473 14.4673 4.867 14.1688 4.81514C13.8854 4.34783 13.3164 3.99256 12.6226 3.8661L12.4691 2.55405ZM5.94199 9.57323C7.96838 7.72553 10.8654 7.28592 12.0606 7.29708C12.1531 7.30367 12.2532 7.30915 12.3605 7.31502C13.5252 7.37876 15.5422 7.48914 17.9819 9.57323C20.5166 11.7384 21.3044 15.5983 20.7781 17.2385C20.2517 18.8787 18.0806 21.3222 12.0606 21.3222C6.04067 21.3222 3.73796 18.7448 3.31031 17.2385C2.88266 15.7322 3.409 11.8829 5.94199 9.57323Z"
			fill="url(#paint2_linear_1392_18556)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1392_18556"
				x1="-4.45714"
				y1="-0.342857"
				x2="27.0857"
				y2="30.7429"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1392_18556"
				x1="-4.45714"
				y1="-0.342857"
				x2="27.0857"
				y2="30.7429"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1392_18556"
				x1="-4.45714"
				y1="-0.342857"
				x2="27.0857"
				y2="30.7429"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
		</defs>
	</svg>
);

export const AIIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={Svg} {...props} />
);
