import { Flex } from 'antd';
import classNames from 'classnames';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useFormatDate } from '@/shared/lib/date/useFormatDate';
import { useMessengerModel } from '@/widgets/messenger/model/MessengerModel';

import { ChatModel } from '../../../model/ChatModel';

import styles from './chat-list-item.module.scss';

type ChatHistoryItemProps = {
	item: ChatModel;
};

export const ChatHistoryItem = observer<ChatHistoryItemProps>(function ChatHistoryItem({ item }) {
	const model = useMessengerModel();
	const formatDate = useFormatDate();

	const dateString = item.date ? formatDate(item.date, 'dd.MM.yyyy') : '';

	const isSelected = computed(() => item === model.aiChat.selectedChat).get();

	return (
		<div
			className={classNames(styles.wrapper, {
				[styles.selected]: isSelected,
			})}
			onClick={() => model.aiChat.setSelectedChat(item)}
		>
			<>
				<Flex align="center" justify="space-between">
					<div className={styles.name}>{item.title}</div>
				</Flex>
				<span className={styles.disabled}>{dateString}</span>
			</>
		</div>
	);
});
