import { IComputedValue, computed, makeObservable } from 'mobx';

import { ValidationEvent, ValidationEventTypes, ValidatorsFunction } from './validation';

export type ValidationControlOptions<TControlValue> = {
	/**
	 * Validations
	 * / Валидациии
	 */
	validators: ValidatorsFunction<ValidationControl<TControlValue>>[];
};
export abstract class ValidationControl<TControlValue> {
	// private validators: ValidatorsFunction<ValidationControl<TControlValue>>[];
	private validators: IComputedValue<ValidationEvent[]>[];
	constructor({ validators }: ValidationControlOptions<TControlValue>) {
		this.validators = validators.map((v) => computed(() => v(this)));

		makeObservable<typeof this>(this, {
			errorMessage: computed,
			validationEvents: computed,
			errors: computed.struct,
			warnings: computed.struct,
			informationMessages: computed.struct,
			successes: computed.struct,
		});
	}

	/**
	 * First error message
	 * / Сообщение первой ошибки
	 */
	get errorMessage() {
		return this.errors.length > 0 ? this.errors[0].message : '';
	}

	/**
	 * List of validation messages
	 * / Список сообщений валидации
	 */
	get validationEvents() {
		const errors: ValidationEvent[] = [];
		const warnings: ValidationEvent[] = [];
		const informationMessages: ValidationEvent[] = [];
		const successes: ValidationEvent[] = [];

		for (let i = 0; i < this.validators.length; i++) {
			const validationEvents = this.validators[i].get();

			validationEvents.forEach((event) => {
				switch (event.type) {
					case ValidationEventTypes.Error:
						errors.push(event);
						break;
					case ValidationEventTypes.Warning:
						warnings.push(event);
						break;
					case ValidationEventTypes.Info:
						informationMessages.push(event);
						break;
					case ValidationEventTypes.Success:
						successes.push(event);
						break;
				}
			});
		}

		return {
			errors,
			warnings,
			successes,
			informationMessages,
		};
	}
	/**
	 * Errors list
	 * / Список ошибок
	 */
	get errors() {
		return this.validationEvents.errors;
	}
	/**
	 * Warnings messages list
	 * / Список сообщений с типом "Внимание"
	 */
	get warnings() {
		return this.validationEvents.warnings;
	}
	/**
	 * Informations messages list
	 * / Сообщения с типом "Информационные сообщения"
	 */
	get informationMessages() {
		return this.validationEvents.informationMessages;
	}
	/**
	 * Successes messages list
	 * / Сообщения с типом "успешная валидация"
	 */
	get successes() {
		return this.validationEvents.successes;
	}
}
