import { observer } from 'mobx-react-lite';

import { ChatModel } from '../../../model/ChatModel';

import { ChatHistoryItem } from './ChatHistoryItem';

type Props = {
	chats: ChatModel[];
};

export const ChatHistory = observer<Props>(function ChatHistory({ chats }) {
	return (
		<>
			{chats.map((chat) => (
				<ChatHistoryItem item={chat} />
			))}
		</>
	);
});
