import { FormControl } from './FormControl';

export type FormControlEventElement<TValue = unknown> = Element & {
	value: TValue;
};

export type FormControlBindActionsOptions<
	TControlElement extends any = unknown,
	TEventElement extends FormControlEventElement = FormControlEventElement,
> = {
	ref?(element: TControlElement | null): void;
	onChange?(event: React.ChangeEvent<TEventElement>): void;
	onBlur?(event: React.FocusEvent<TEventElement>): void;
	onFocus?(event: React.FocusEvent<TEventElement>): void;
};

export type FormControlBindedActions<
	TControlElement extends any = unknown,
	TEventElement extends FormControlEventElement = FormControlEventElement,
> = {
	ref: (element: TControlElement | null) => void;
	onChange: (event: React.ChangeEvent<TEventElement>) => void;
	onBlur: (event: React.FocusEvent<TEventElement>) => void;
	onFocus: (event: React.FocusEvent<TEventElement>) => void;
};

export function formControlBindActions<
	TControl extends FormControl<any, any> = FormControl<FormControlEventElement, unknown>,
	TEventElement extends FormControlEventElement = Exclude<TControl['element'], null>,
>(
	formControl: TControl,
	toValue: (inputValue: TEventElement['value']) => TControl['value'],
	options: FormControlBindActionsOptions<TControl['element'], TEventElement> = {},
): FormControlBindedActions<TControl['element'], TEventElement> {
	return {
		ref: (element: TControl['element']): void => {
			formControl.element = element;
			options.ref && options.ref(element);
		},
		onChange: (event: React.ChangeEvent<TEventElement>) => {
			formControl.setValue(toValue(event.target.value));
			options.onChange && options.onChange(event);
		},
		onBlur: (event: React.FocusEvent<TEventElement>) => {
			formControl.setTouched(true);
			formControl.setFocused(false);
			options.onBlur && options.onBlur(event);
		},
		onFocus: (event: React.FocusEvent<TEventElement>) => {
			formControl.setFocused(true);
			options.onFocus && options.onFocus(event);
		},
	};
}
