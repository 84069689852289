import { Form } from 'antd';

import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';

import { RequestWithState } from '@/shared/lib/collections/RequestWithState';
import { FormGroup } from '@/shared/lib/form/FormGroup';
import { requiredValidator } from '@/shared/lib/form/validators';
import { singleton } from '@/shared/lib/singleton';
import { useStore } from '@/shared/lib/useStore';
import { Button } from '@/shared/ui/Button';
import { Input, InputTextControl } from '@/shared/ui/form/Input';

import { InputTextAreaControl } from '../form/InputTextArea';

import styles from './form.module.scss';

export type DemoFormProps = {
	className?: string;
};

class Model {
	constructor() {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	requestState = new RequestWithState(
		async () => {
			await new Promise((r) => setTimeout(r, 3000));
		},
		{
			callback(result, error) {},
		},
	);

	name = new InputTextControl('', {
		validators: [requiredValidator()],
	});

	phone = new InputTextControl('', {
		validators: [requiredValidator()],
	});

	comment = new InputTextAreaControl('', {});

	formGroup = new FormGroup({
		name: this.name,
		phone: this.phone,
		comment: this.comment,
	});

	handleSubmit() {
		if (this.formGroup.firstErrorControl) {
			this.formGroup.firstErrorControl.element?.focus();
		} else {
			this.requestState.request();
		}
	}
}

const SingletoneModel = singleton(Model);

export const DemoForm = observer<DemoFormProps>(function DemoForm({ className }) {
	const model = useStore(() => new SingletoneModel(), []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>Заявка на пилот</div>

			<div className={styles.divider}></div>
			<Form className={styles.form} layout="vertical">
				<Form.Item label="Имя">
					<Input placeholder="Введите имя" {...model.name.props} />
				</Form.Item>
				<Form.Item label="Номер телефона">
					<Input placeholder="+7 (999) 999 99 99" {...model.phone.props} />
				</Form.Item>
				<Form.Item label="Комментарий - необязательно">
					<Input.TextArea
						placeholder="Введите комментарий..."
						maxLength={1000}
						showCount
						rows={4}
						{...model.comment.props}
					/>
				</Form.Item>
			</Form>
			<Button
				loading={model.requestState.isLoading}
				variant="primary"
				ghost
				onClick={model.handleSubmit}
			>
				Отправить заявку
			</Button>
		</div>
	);
});
