import { Layout as AntLayout } from 'antd';
import classNames from 'classnames';
import { forwardRef } from 'react';

import styles from './base-layout.module.scss';

export type BaseLayoutProps = React.HTMLAttributes<HTMLDivElement>;
export const BaseLayout = forwardRef<HTMLDivElement, BaseLayoutProps>(function BaseLayoutHeader(
	{ className, ...props },
	ref,
) {
	return <AntLayout ref={ref} className={classNames(styles.layout, className)} {...props} />;
});
