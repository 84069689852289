import { FetchError, RequiredError, ResponseError } from '@/shared/api/generated';

import { ErrorData } from './types';

export async function getErrorData<D = never>(error: unknown): Promise<ErrorData<D>> {
	if (error instanceof ResponseError) {
		try {
			const data = (await error.response.json()) as any;
			return {
				httpStatus: error.response.status,
				message: error.response.statusText,
				data,
			};
		} catch (e) {
			return {
				httpStatus: error.response.status,
				message: error.response.statusText,
			};
		}
	} else if (error instanceof FetchError) {
		return {
			httpStatus: -1,
			message: 'Ошибка соединения',
		};
	} else if (error instanceof RequiredError) {
		return {
			httpStatus: 0,
			message: `Field ${error.field} is required`,
		};
	} else if (error instanceof Error) {
		return {
			httpStatus: 0,
			code: -1,
			message: error.message,
		};
	}

	return {
		httpStatus: 0,
		code: -1,
		message: 'Произошла непредвиденная ошибка',
	};
}
