import { route, string } from 'react-router-typesafe-routes/dom';

// export class RoutePath<Params extends string = ''> {
// 	constructor(public path: string) {}

// 	public resolve(params?: Partial<Record<Params, string>>) {
// 		return generatePath(this.path, params);
// 	}
// }

export const ROUTE_PATHS = {
	/** Дашбоард */
	dashboard: route(''),
	/** События */
	events: route('events'),
	/** Инциденты */
	incidents: route('incidents/*'),
	/** Инцидент */
	incident: route('incidents/:incidentID', {
		params: { incidentID: string().defined() },
		searchParams: { tab: string().default('info'), sort: string().default('info') },
	}),
	/** Сценарии */
	workflows: route('workflows/*', {
		searchParams: { tab: string().default('all') },
	}),
	/** Редактор сценариев */
	playbook: route('workflows/:workflowID', {
		params: { workflowID: string().defined() },
	}),
	/** Интеграции */
	integrations: route('integrations/*', {
		searchParams: { tab: string().default('all') },
	}),
	/** Интеграция */
	integration: route('integrations/:integrationID', {
		params: { integrationID: string().defined() },
		searchParams: { tab: string().default('info') },
	}),
	/** Аудит */
	audit: route('audit/*', {
		searchParams: { tab: string().default('reports') },
	}),
	/** Аудит */
	auditEditor: route('audit/report/editor/:reportID'),
	/** Администрирование */
	settings: route('settings/*', {
		searchParams: { tab: string().default('base') },
	}),
	/** Администрирование/пользователь */
	user: route('settings/users/:userID', {
		params: { userID: string().defined() },
		searchParams: { tab: string().default('info') },
	}),
	/** Исполнитель */
	executor: route('executor/:executorID'),
	/** Коррелятор */
	correlator: route('correlator/:correlatorID'),
	/** NOT FOUND PAGE */
	notFound: route('*'),
	/** LOGIN PAGE */
	login: route('*'),
};
