import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import React, { useLayoutEffect, useRef } from 'react';

import { Demo } from './Demo';

import styles from './demo-overlay.module.scss';

export type DemoOverlayProps = {
	className?: string;
	dymanic?: boolean;
};

export const DemoOverlay = observer<DemoOverlayProps>(function DemoOverlay({ className, dymanic }) {
	const ref = useRef<HTMLDivElement>(null);

	const [style, setStyle] = React.useState<React.CSSProperties>();

	useLayoutEffect(() => {
		if (dymanic) {
			function computedStyle() {
				if (ref.current) {
					const clientHeight = document.documentElement.clientHeight;
					const parentElement = ref.current.parentElement;
					if (parentElement) {
						const parentRect = parentElement.getBoundingClientRect();
						let height, top;
						if (parentRect.height > clientHeight) {
							if (parentRect.top > 0) {
								height = clientHeight - parentRect.top;
							} else {
								top = -parentRect.top;
								if (parentRect.bottom > clientHeight) {
									height = clientHeight;
								} else {
									height = parentRect.bottom;
								}
							}
							setStyle({
								top,
								height,
							});
						}
					}
				}
			}
			computedStyle();
			const parentElement = ref.current?.parentElement;
			parentElement?.addEventListener('scroll', computedStyle);
			parentElement?.parentElement?.addEventListener('scroll', computedStyle);
			window.addEventListener('resize', computedStyle);
			window.addEventListener('scroll', computedStyle);
			return () => {
				window.removeEventListener('resize', computedStyle);
				window.removeEventListener('scroll', computedStyle);
				parentElement?.removeEventListener('scroll', computedStyle);
				parentElement?.parentElement?.removeEventListener('scroll', computedStyle);
			};
		}
	}, [dymanic]);

	return (
		<div ref={ref} className={classNames(styles.wrapper, className)} style={style}>
			<Demo />
		</div>
	);
});

DemoOverlay.displayName = 'ForwardRef shared/ui/(DemoOverlay)';
