import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
	<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="52" height="52" fill="url(#paint0_linear_1015_21753)" />
		<path
			d="M21.9158 30.8033C22.8241 30.8033 23.5605 29.7543 23.5605 28.4603C23.5605 27.1662 22.8241 26.1172 21.9158 26.1172C21.0074 26.1172 20.271 27.1662 20.271 28.4603C20.271 29.7543 21.0074 30.8033 21.9158 30.8033Z"
			fill="#E6F7FF"
		/>
		<path
			d="M30.2055 30.8033C31.1139 30.8033 31.8503 29.7543 31.8503 28.4603C31.8503 27.1662 31.1139 26.1172 30.2055 26.1172C29.2971 26.1172 28.5607 27.1662 28.5607 28.4603C28.5607 29.7543 29.2971 30.8033 30.2055 30.8033Z"
			fill="#E6F7FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M26.4691 16.5541C26.9944 16.3907 27.3765 15.8934 27.3765 15.3054C27.3765 14.5845 26.8021 14 26.0935 14C25.385 14 24.8106 14.5845 24.8106 15.3054C24.8106 15.8696 25.1623 16.3501 25.6547 16.5325L25.4987 17.8661C24.7954 17.9943 24.2205 18.3575 23.9411 18.8342C22.2017 19.1647 19.9838 19.9394 18.1857 21.579C17.356 22.3355 16.6988 23.1965 16.1853 24.0853C15.3981 24.0209 14.5436 24.7299 14.174 25.8139C13.8092 26.8838 14.0377 27.9646 14.6757 28.4208C14.4811 29.6954 14.4934 30.965 14.782 31.9817C15.659 35.0707 19.3861 38 26.0606 38C32.7801 38 36.2805 35.1837 37.2798 32.0697C37.6574 30.8929 37.6301 29.4742 37.3699 28.1202C37.9982 27.6446 38.1925 26.5514 37.7889 25.4905C37.374 24.3998 36.4691 23.7167 35.6735 23.8417C35.1406 23.0067 34.4798 22.2106 33.674 21.5223C32.0443 20.1302 30.4918 19.399 29.1128 19.0239C28.785 18.9347 28.4673 18.867 28.1688 18.8151C27.8854 18.3478 27.3164 17.9926 26.6226 17.8661L26.4691 16.5541ZM19.942 23.5732C21.9684 21.7255 24.8654 21.2859 26.0606 21.2971C26.1531 21.3037 26.2532 21.3091 26.3605 21.315C27.5252 21.3788 29.5422 21.4891 31.9819 23.5732C34.5166 25.7384 35.3044 29.5983 34.7781 31.2385C34.2517 32.8787 32.0806 35.3222 26.0606 35.3222C20.0407 35.3222 17.738 32.7448 17.3103 31.2385C16.8827 29.7322 17.409 25.8829 19.942 23.5732Z"
			fill="#E6F7FF"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1015_21753"
				x1="-9.65714"
				y1="-0.742857"
				x2="58.6857"
				y2="66.6095"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
		</defs>
	</svg>
);

export const AIIconActive = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={Svg} {...props} />
);
