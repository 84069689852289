import { action, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

import { LayoutNavbarModel, LayoutNavItemType } from '../LayoutNavbarModel';
import { LayoutTabsNavbarModel } from '../LayoutTabsNavbarModel';

export type LayoutNavbarMode = 'tabs' | 'default';

export class LayoutModel {
	public navbar: LayoutNavbarModel;

	constructor(
		navItems: LayoutNavItemType[],
		public mode: LayoutNavbarMode = 'default',
	) {
		makeObservable(this, {
			mode: observable,
			setNavbarMode: action,
		});
		this.navbar = new LayoutNavbarModel(navItems, false);
	}

	public tabsNavbar = new LayoutTabsNavbarModel();

	public setNavbarMode(mode: LayoutNavbarMode) {
		this.mode = mode;
	}

	public key = v4();
}
