import { Alert } from 'antd';

import { observer } from 'mobx-react-lite';

import { AppConfig } from '@/shared/config/AppConfig';

import { withProviders } from './providers';
import { Router } from './Router/Router';

import '@/shared/ui/styles/global.css';

export const App = withProviders(
	observer(function App({ appConfig }: { appConfig: AppConfig }) {
		return (
			<Alert.ErrorBoundary>
				{
					appConfig.locale.isReady ? <Router /> : null
					// <Flex flex="1 100%" style={{ height: '100%' }} align="center" justify="center">
					// 	<Spin size="large" />
					// </Flex>
				}
			</Alert.ErrorBoundary>
		);
	}),
);
