import { formatDate } from 'date-fns/format';
import { useCallback } from 'react';

import { useAppConfig } from '@/shared/config/AppConfig';

export function useFormatDate() {
	const appConfig = useAppConfig();

	const _formatDate = useCallback<typeof formatDate>(
		(date, format, options = {}) => {
			if (!options.locale) {
				options.locale = appConfig.locale.dateFnsLocale;
			}
			return formatDate(date, format, options);
		},
		[appConfig.locale.dateFnsLocale],
	);

	return _formatDate;
}
