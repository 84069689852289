import { v4 } from 'uuid';

import type { Repository } from '../Repository';

export class RepositoriesManager {
	public id: string = v4();
	private registry = new Map<{ new (): Repository<any> }, Repository<any>>();
	get<
		IConstructor extends { new (): Repository<any> } = { new (): Repository<any> },
		IRepository extends Repository<any> = InstanceType<IConstructor>,
	>(constructor: IConstructor): IRepository {
		let instance = this.registry.get(constructor);
		if (!instance) {
			instance = new constructor();
			this.registry.set(constructor, instance);
		}

		return instance as IRepository;
	}
}
