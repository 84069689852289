import type { AppConfig } from '@/shared/config/AppConfig';

import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { Dashboard } = await import('./ui/Dashboard');
	return {
		default: Dashboard,
	};
};
export const dashboardRoute = (appConfig: AppConfig, layoutModel: LayoutModel): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const { DashboardModel } = await import('./model/DashboardModel');
			const SingletonModel = singleton(DashboardModel);
			const model = new SingletonModel(appConfig);
			model.stats.requestState.request();
			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.dashboard.path,
		},
	);
