import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { ReactNode } from 'react';

import styles from './sidebar-chat.module.scss';

type SidebarChatProps = {
	children: ReactNode;
	className?: string;
	isOpen: boolean;
};

export const SidebarChat = observer<SidebarChatProps>(function SidebarChat({
	children,
	className,
	isOpen,
}) {
	return (
		<aside
			className={classNames(className, styles.container, {
				[styles.container__collapsed]: !isOpen,
			})}
		>
			{children}
		</aside>
	);
});
