import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
	<svg width="16" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18 31.8462C26.2843 31.8462 33 25.1305 33 16.8462C33 8.56192 26.2843 1.84619 18 1.84619C9.71573 1.84619 3 8.56192 3 16.8462C3 20.491 4.29997 23.8322 6.46154 26.4314V33.3891C6.46154 33.7498 6.83172 33.9918 7.16208 33.8472L13.3846 31.1227C14.8386 31.5924 16.3897 31.8462 18 31.8462Z"
			stroke="currentColor"
			strokeWidth="3"
		/>
		<circle cx="11.0772" cy="16.8461" r="2.30769" fill="currentColor" />
		<circle cx="18.0001" cy="16.8461" r="2.30769" fill="currentColor" />
		<circle cx="24.9229" cy="16.8461" r="2.30769" fill="currentColor" />
	</svg>
);

export const ChatIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={Svg} {...props} />
);
