import { FormControl, FormControlOptions, ValueOrGetter } from '@/shared/lib/form/FormControl';
import {
	FormControlBindedActions,
	formControlBindActions,
} from '@/shared/lib/form/formControlBindActions';

import type { InputRef } from '../';

export type InputControlOptions<TEntity> = FormControlOptions<FormControl<InputRef, TEntity>> &
	InputControlActionsOptions & {
		valueTransform?: (v: string) => TEntity;
	};

export type InputControlActionsOptions = {
	ref?(element: InputRef | null): void;
	onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
	onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
	onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
};

export class InputNumberControl extends FormControl<InputRef, number> {
	actions: FormControlBindedActions<InputRef, HTMLInputElement>;
	constructor(
		/**
		 * При инициализации моделей рекомендуется передавать функцию геттер начального значения,
		 * а не само значение. Так мы получаем "реактивные" модели контролов, которые подписываются
		 * на изменения observable переменных и всегда имеют актуальное значение переменной.
		 * Само значение используется при создании "изолированной" модели контрола или когда модель
		 * инициализируется не observable значениями.
		 */
		valueOrGetter: ValueOrGetter<number>,
		{
			ref,
			onChange,
			onBlur,
			onFocus,
			valueTransform = (v: string) => Number(v),
			...options
		}: InputControlOptions<number> = {},
	) {
		super(valueOrGetter, options);

		this.actions = formControlBindActions<typeof this, HTMLInputElement>(this, valueTransform, {
			ref,
			onChange,
			onBlur,
			onFocus,
		});
	}

	get props() {
		return {
			value: this.value,
			...this.actions,
		};
	}
}

export class InputTextControl extends FormControl<InputRef, string> {
	actions: FormControlBindedActions<InputRef, HTMLInputElement>;
	constructor(
		/**
		 * При инициализации моделей рекомендуется передавать функцию геттер начального значения,
		 * а не само значение. Так мы получаем "реактивные" модели контролов, которые подписываются
		 * на изменения observable переменных и всегда имеют актуальное значение переменной.
		 * Само значение используется при создании "изолированной" модели контрола или когда модель
		 * инициализируется не observable значениями.
		 */
		valueOrGetter: ValueOrGetter<string>,
		{
			ref,
			onChange,
			onBlur,
			onFocus,
			valueTransform = (v: string) => v,
			...options
		}: InputControlOptions<string> = {},
	) {
		super(valueOrGetter, options);

		this.actions = formControlBindActions<typeof this, HTMLInputElement>(this, valueTransform, {
			ref,
			onChange,
			onBlur,
			onFocus,
		});
	}

	get props() {
		return {
			value: this.value,
			...this.actions,
		};
	}
}
