import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';

import { Toggler } from '@/shared/lib/collections/Toggler';
import { useStore } from '@/shared/lib/useStore';
import { DemoModal } from '@/shared/ui/DemoOverlay';
import { AIIcon } from '@/shared/ui/icons/AIIcon';

import { AlertGradientIcon } from '@/shared/ui/icons/AlertGradientIcon';
import { FileSearchGradientIcon } from '@/shared/ui/icons/FileSearchGradientIcon';

import { SafetyGradientIcon } from '@/shared/ui/icons/SafetyGradientIcon';

import { MessengerModel } from '../../../model/MessengerModel';

import { ChatInput } from '../../ChatInput';

import { RecommendationCard } from '../RecommendationCard';

import { RecommendationTag } from '../RecommendationTag';

import styles from './chat-bot.module.scss';

type ChatBotProps = {
	model: MessengerModel;
};
export const ChatBot = observer<ChatBotProps>(function ChatBot({ model }) {
	const toggler = useStore(() => new Toggler());
	return (
		<div className={styles.content}>
			<DemoModal toggler={toggler} />
			<div className={styles.empty_content}>
				<AIIcon />
				<h2 className={styles.title}>Чем я могу помочь сегодня?</h2>
				<span className={styles.text}>Просто опишите свою задачу</span>

				<Flex vertical>
					<RecommendationCard
						title="Анализ инцидента"
						desc="Вектор атаки и меры противодействия"
						Icon={FileSearchGradientIcon}
						onClick={toggler.enable}
					/>
					<RecommendationCard
						title="Оценка ущерба"
						desc="Потенциальные риски и меры восстановления"
						Icon={AlertGradientIcon}
						onClick={toggler.enable}
					/>
					<RecommendationCard
						title="Следующие шаги"
						desc="Рекомендации по дальнейшим действиям"
						Icon={SafetyGradientIcon}
						onClick={toggler.enable}
					/>
				</Flex>
			</div>

			<div className={styles.recommendations}>
				<RecommendationTag recommendation="Анализ инцидента" onClick={toggler.enable} />
				<RecommendationTag recommendation="Вектор атаки" onClick={toggler.enable} />
			</div>

			<ChatInput type="ai" model={model.AIInput} />
		</div>
	);
});
