import { SendOutlined } from '@ant-design/icons';

import { Mentions } from 'antd';
import classNames from 'classnames';

import { observer } from 'mobx-react-lite';

import { useRef } from 'react';

import { Toggler } from '@/shared/lib/collections/Toggler';
import { useStore } from '@/shared/lib/useStore';
import { Button } from '@/shared/ui/Button';

import { DemoModal } from '@/shared/ui/DemoOverlay';
import { MentionsControl } from '@/shared/ui/form/Mentions';
import { MentionIcon } from '@/shared/ui/icons/MentionIcon';

import { SendGradientIcon } from '@/shared/ui/icons/SendGradientIcon';

import { useMessengerModel } from '../../model/MessengerModel';

import styles from './chat-input.module.scss';

type ChatInputProps = {
	model: MentionsControl;
	type?: 'ai' | 'chat';
};

export const ChatInput = observer<ChatInputProps>(function ChatInput({ model, type }) {
	const messenger = useMessengerModel();

	const toggler = useStore(() => new Toggler());

	const isPrefixRef = useRef(false);

	const input = (
		<Mentions
			placeholder={
				type === 'ai' ? 'Задайте вопрос вашему Ai-ассистенту' : 'Введите сообщение'
			}
			autoSize={{ minRows: 2, maxRows: 10 }}
			className={classNames(styles.input, { [styles.ai_input]: type === 'ai' })}
			{...model.props}
			options={messenger.query.users.data
				.filter((v) => !v.isBot)
				.map((v) => {
					return {
						value: v.username,
						label: v.username,
					};
				})}
			onSearch={(str, prefix) => {
				isPrefixRef.current = true;
			}}
			onChange={(v) => {
				isPrefixRef.current = false;
				model.props.onChange?.(v);
			}}
			onKeyDown={(event) => {
				if (isPrefixRef.current) return;
				if (event.target === event.currentTarget) {
					if (event.key === 'Enter' && !event.shiftKey) {
						event.preventDefault();
						toggler.enable();
					}
				}
			}}
			onSubmit={toggler.enable}
		/>
	);

	return (
		<>
			<DemoModal toggler={toggler} />
			<div className={styles.input_wrapper}>
				{type === 'ai' ? (
					<div
						className={classNames(styles.gradient, {
							[styles.gradient_active]: model.isFocused,
						})}
					>
						{input}
					</div>
				) : (
					input
				)}
				{/* 
				TODO: DEMO
				{type === 'chat' && (
					<Button
						ghost
						variant="secondary"
						className={classNames(styles.button, styles.mention)}
					>
						<MentionIcon className={styles.action_icon} />
					</Button>
				)} */}
				{/* 
				TODO: DEMO
				<Button
					ghost
					variant="secondary"
					className={classNames(styles.button, styles.clip, {
						[styles.clip_chat]: type === 'chat',
					})}
				>
					<PaperClipOutlined className={styles.action_icon} />
				</Button> */}
				<Button
					ghost
					variant="secondary"
					className={classNames(styles.button, styles.send)}
					onClick={toggler.enable}
				>
					{type === 'ai' && model.isFocused ? (
						<SendGradientIcon />
					) : (
						<SendOutlined className={classNames(styles.action_icon)} />
					)}
				</Button>
			</div>
		</>
	);
});
