import { IObservableArray, makeAutoObservable, observable } from 'mobx';

import { Entity } from '@/shared/lib/collections/Entity';

export type RoleDto = {
	/** Название роли */
	name: string;
	/** Количество аттрибутов ??? */
	attributes: number;
	/** Модификаторы доступа */
	permissions?: PermissionDto[];
};

export type PermissionDto = {
	/** Название */
	name: string;
	/** Секция к которой относятся права */
	section: string;
	/** Подсекция к которой относятся права */
	subSection: string;
	/** Есть ли доступ */
	hasAccess: boolean;
};

export type UserDto = {
	/** ID пользователя */
	id: string;
	/** Username пользователя */
	username: string;
	/** Email пользователя */
	email: string;
	/**Фамилия */
	surname: string;
	/** Имя */
	name: string;
	/** Отчество */
	patronymic: string;
	/** Ссылка на аватар пользователя */
	avatar: string;
	/** Модификаторы доступа */
	permissions: PermissionDto[];
	/** Последний вход в систему в формате ISO */
	lastLoginDate: string;
	/** Дата регистрации в формате ISO */
	registrationDate: string;
	/** Дата последнего обновления в формате ISO  */
	lastUpdateDate: string;
	/** Тип уч. записи */
	accountType: string;
	/** Роли пользователя */
	roles: RoleDto[];
	/** Активна ли двухфакторная аутентификация */
	hasTwoFactorAuth: boolean;
	/** Есть ли доступ к системе */
	hasSystemAccess: boolean;
};

const iso = new Date().toISOString();

export class UserEntity implements Entity<UserDto> {
	constructor(public id: string) {
		makeAutoObservable(this);
	}
	avatar: string = '';
	username: string = '';
	email: string = '';
	patronymic: string = '';
	roleId: string = '';
	permissions: IObservableArray<PermissionDto> = observable.array(undefined);
	roles: IObservableArray<RoleDto> = observable.array(undefined);
	name: string = '';
	surname: string = '';
	lastLoginDate: string = iso;
	registrationDate: string = iso;
	lastUpdateDate: string = iso;
	accountType: string = '';
	hasTwoFactorAuth: boolean = false;
	hasSystemAccess: boolean = false;

	get permissionsSet() {
		return new Set(this.permissions);
	}

	get fio() {
		return `${this.surname ? `${this.surname} ` : ''}${this.name}`;
	}

	updateFromJson(updateDto: UserDto): void {
		this.partialUpdateFromJson(updateDto);
	}

	partialUpdateFromJson({ roles, permissions, ...dto }: Partial<UserDto>): void {
		roles && this.roles.replace(roles);
		permissions && this.permissions.replace(permissions);
		Object.assign(this, dto);
	}
}
