// import { faker } from '@faker-js/faker';

// import { v4 } from 'uuid';

import { getUsers } from '@/shared/api/mock/users';

import { LoginResponse } from '../lib/loginResponseSchema';

// const userId = v4();

const user = getUsers()[0];
export class AuthApi {
	async login(body: { username: string; password: string }): Promise<LoginResponse> {
		await new Promise((r) => setTimeout(r, 2000));
		if (body.username === 'i.ivanov' && body.password.trim() === 'ivanov1990') {
			return user;
		} else {
			throw new Error('Not found');
		}
		// return {
		// 	id: userId,
		// 	name: faker.person.firstName(),
		// 	surname: faker.person.lastName(),
		// };
	}

	async getProfile(): Promise<LoginResponse> {
		await new Promise((r) => setTimeout(r, 2000));
		return user;
		// return {
		// 	id: userId,
		// 	name: faker.person.firstName(),
		// 	surname: faker.person.lastName(),
		// };
	}
}
