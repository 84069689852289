import { IObservableArray, makeAutoObservable, observable } from 'mobx';

export type TabsNavbarActions = 'add' | 'remove';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export type TabsNavbarItemType = {
	key: string;
	label: React.ReactNode;
	to: string;
};
export type TabsNavbarAddListener = (event: React.MouseEvent | React.KeyboardEvent) => void;

export type TabsNavbarRemoveListener = (targetKey: string) => void;

export class LayoutTabsNavbarModel {
	public activeKey: string = '';

	public navItems: IObservableArray<TabsNavbarItemType> = observable.array(undefined, {
		deep: false,
	});

	public listeners: { add: TabsNavbarAddListener[]; remove: TabsNavbarRemoveListener[] } = {
		add: [],
		remove: [],
	};

	constructor() {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	public get activeNavItem() {
		return this.navItems.find((item) => item.key === this.activeKey);
	}

	public addTab(newTab: TabsNavbarItemType) {
		if (this.navItems.findIndex((v) => v.key === newTab.key) === -1) {
			this.navItems.push(newTab);
		}
	}

	public removeTab(tabKey: string) {
		let newActiveKey = this.activeKey;

		const indexToRemove = this.navItems.findIndex((item) => item.key === tabKey);

		if (indexToRemove !== -1) {
			if (newActiveKey === tabKey) {
				if (indexToRemove > 0) {
					newActiveKey = this.navItems[indexToRemove - 1].key;
				} else if (indexToRemove === 0 && this.navItems.length > 1) {
					newActiveKey = this.navItems[1].key;
				}
			}
			this.navItems.splice(indexToRemove, 1);
		}
		this.setSelectedKey(newActiveKey);
	}

	public handleEdit(targetKey: TargetKey, action: TabsNavbarActions) {
		if (action === 'add' && typeof targetKey !== 'string') {
			this.listeners.add.forEach((listener) => listener(targetKey));
		}
		if (action === 'remove' && typeof targetKey === 'string') {
			this.listeners.remove.forEach((listener) => listener(targetKey));
		}
	}

	public setTabsItems(navItems: TabsNavbarItemType[]) {
		this.navItems.replace(navItems);
	}

	public setSelectedKey(tabKey?: string) {
		if (tabKey) {
			this.activeKey = tabKey;
		} else {
			this.activeKey = '';
		}
	}
	public addEventListener(eventType: 'add', listener: TabsNavbarAddListener): () => void;
	public addEventListener(eventType: 'remove', listener: TabsNavbarRemoveListener): () => void;
	public addEventListener(
		eventType: TabsNavbarActions,
		listener: TabsNavbarAddListener & TabsNavbarRemoveListener,
	) {
		if (this.listeners[eventType]) {
			this.listeners[eventType].push(listener);
		}

		return () => {
			this.removeEventListener(eventType, listener);
		};
	}

	public removeEventListener(
		eventType: TabsNavbarActions,
		listener: TabsNavbarAddListener | TabsNavbarRemoveListener,
	) {
		const index = this.listeners[eventType].findIndex((v) => v === listener);
		if (index !== -1) {
			this.listeners[eventType].splice(index, 1);
		}
	}
}
