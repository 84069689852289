import { Repository } from '@/shared/lib/collections/Repository';

import { ChatUserEntity } from '.';

export class ChatUserRepository extends Repository<ChatUserEntity> {
	constructor() {
		super(function createItem(id: string) {
			return new ChatUserEntity(id);
		});
	}
}
