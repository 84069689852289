import { createContext } from 'react';

import { useAppConfig } from '@/shared/config/AppConfig';

import { AuthModel } from './AuthModel/AuthModel';

export const AuthModelContext = createContext<AuthModel>(null!);

export function useAuthModel() {
	const appConfig = useAppConfig();
	return appConfig.auth;
}
