import hexRgb, { RgbaObject } from 'hex-rgb';
import { makeAutoObservable } from 'mobx';

import { Entity } from '@/shared/lib/collections/Entity';

export type CompanyDto = {
	id: string;
	name: string;
	fullName: string;
	logo: string;
	bgColor: string;
};

export class CompanyEntity implements Entity<CompanyDto> {
	constructor(public id: string) {
		makeAutoObservable(this);
	}
	name: string = '';
	fullName: string = '';
	logo: string = '';
	bgColor: string = '#000000';

	get bgColorRgb(): RgbaObject {
		try {
			const { red, green, blue, alpha } = hexRgb(this.bgColor);
			return {
				red,
				green,
				blue,
				alpha,
			};
		} catch (error) {
			return {
				red: 0,
				green: 0,
				blue: 0,
				alpha: 0,
			};
		}
	}

	updateFromJson(updateDto: CompanyDto): void {
		this.partialUpdateFromJson(updateDto);
	}

	partialUpdateFromJson(dto: Partial<CompanyDto>): void {
		Object.assign(this, dto);
	}
}
