import { FormControl, FormControlOptions, ValueOrGetter } from '@/shared/lib/form/FormControl';
import {
	FormControlBindedActions,
	formControlBindActions,
} from '@/shared/lib/form/formControlBindActions';

import { TextAreaRef } from '..';

export type InputTextAreaControlOptions<TEntity> = FormControlOptions<
	FormControl<TextAreaRef, TEntity>
> &
	InputTextAreaControlActionsOptions & {
		valueTransform?: (v: string) => TEntity;
	};

export type InputTextAreaControlActionsOptions = {
	ref?(element: TextAreaRef | null): void;
	onChange?(event: React.ChangeEvent<HTMLTextAreaElement>): void;
	onBlur?(event: React.FocusEvent<HTMLTextAreaElement>): void;
	onFocus?(event: React.FocusEvent<HTMLTextAreaElement>): void;
};

export class InputTextAreaControl extends FormControl<TextAreaRef, string> {
	actions: FormControlBindedActions<TextAreaRef, HTMLTextAreaElement>;
	constructor(
		/**
		 * При инициализации моделей рекомендуется передавать функцию геттер начального значения,
		 * а не само значение. Так мы получаем "реактивные" модели контролов, которые подписываются
		 * на изменения observable переменных и всегда имеют актуальное значение переменной.
		 * Само значение используется при создании "изолированной" модели контрола или когда модель
		 * инициализируется не observable значениями.
		 */
		valueOrGetter: ValueOrGetter<string>,
		{
			ref,
			onChange,
			onBlur,
			onFocus,
			valueTransform = (v: string) => v,
			...options
		}: InputTextAreaControlOptions<string> = {},
	) {
		super(valueOrGetter, options);

		this.actions = formControlBindActions<typeof this, HTMLTextAreaElement>(
			this,
			valueTransform,
			{ ref, onChange, onBlur, onFocus },
		);
	}

	get props() {
		return {
			value: this.value,
			...this.actions,
		};
	}
}
