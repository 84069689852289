import { makeAutoObservable } from 'mobx';

import { Registry } from '@/shared/lib/collections/Registry';

import { ChatEntity } from '../ChatEntity';
import { ChatMessageBaseEntity } from '../ChatMessageEntity';
import { ChatMessageModel } from '../ChatMessageModel';
import { MessengerQueryModel } from '../MessengerQueryModel';

export class ChatModel {
	constructor(
		public chat: ChatEntity,
		public query: MessengerQueryModel,
	) {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	get fromUser() {
		return this.query.users.getItem(this.chat.id);
	}

	get from() {
		return this.fromUser?.fio || '';
	}

	get lastMessage() {
		return this.messages.at(-1);
	}

	private messagesRegistry = new Registry(
		(v: ChatMessageBaseEntity) => new ChatMessageModel(v, this.query),
	);

	get messages() {
		return this.messagesRegistry.from(
			this.query.messages.data.filter((message) => message.chat === this.chat.id),
		);
	}

	get date() {
		return this.lastMessage?.date;
	}

	get title() {
		return this.chat.title || this.from;
	}

	get text() {
		return this.lastMessage?.text || '';
	}

	get isPrivate() {
		return this.chat.type === 'private';
	}

	get isIncident() {
		return this.chat.isIncident;
	}

	get isFavorite() {
		return this.chat.isFavorite;
	}

	// get users() {
	// 	const result: ChatUserEntity[] = [];
	// 	this.messages.forEach((message) => {
	// 		const user = this.query.users.getItem(message.from);
	// 		if (user) {
	// 			result.push(user);
	// 		}
	// 	}, []);

	// 	return result;
	// }
}
