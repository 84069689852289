import { Button, Flex, Result } from 'antd';

import { reaction } from 'mobx';
import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAuthModel } from '@/features/auth';

export const NotAuthorisedResult = memo<{ resetErrorBoundary: () => void }>(
	function NotAuthorisedResult({ resetErrorBoundary }) {
		const navigate = useNavigate();

		const authModel = useAuthModel();
		useEffect(() => {
			return reaction(
				() => authModel.isAuth,
				(isAuth) => {
					if (isAuth) {
						resetErrorBoundary();
					}
				},
			);
		}, [authModel, resetErrorBoundary]);

		return (
			<Flex flex="1 100%" align="center" justify="center">
				<Result
					status="403"
					title="401"
					subTitle="Не авторизован"
					extra={
						<Button onClick={() => navigate('/')} type="primary">
							На главную
						</Button>
					}
				/>
			</Flex>
		);
	},
);
