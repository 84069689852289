import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1076_23189)">
			<path
				d="M19.3623 9.71132L0.690467 0.349717C0.614574 0.31177 0.527521 0.302842 0.444932 0.322931C0.35327 0.345591 0.274321 0.403649 0.225373 0.484393C0.176425 0.565136 0.161466 0.661985 0.183771 0.753734L2.10788 8.61534C2.1369 8.73364 2.22395 8.82963 2.34002 8.86757L5.6369 9.99927L2.34225 11.131C2.22618 11.1711 2.13913 11.2649 2.11234 11.3832L0.183771 19.256C0.163682 19.3386 0.17261 19.4256 0.210557 19.4993C0.29761 19.6756 0.511896 19.747 0.690467 19.66L19.3623 10.3519C19.4315 10.3185 19.4873 10.2604 19.5231 10.1935C19.6101 10.0149 19.5387 9.80061 19.3623 9.71132ZM2.38466 17.0194L3.50743 12.4301L10.0967 10.1689C10.1481 10.1511 10.1905 10.1109 10.2083 10.0573C10.2396 9.96356 10.1905 9.86311 10.0967 9.82963L3.50743 7.5707L2.38913 2.99927L16.407 10.0283L2.38466 17.0194Z"
				fill="url(#paint0_linear_1076_23189)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_1076_23189"
				x1="-3.42706"
				y1="0.035578"
				x2="22.0498"
				y2="25.1461"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
			<clipPath id="clip0_1076_23189">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const SendGradientIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={Svg} {...props} />
);
