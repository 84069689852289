import Cookie from 'js-cookie';
import myzod, { Infer } from 'myzod';

const accessTokenSchema = myzod.object({
	scope: myzod.object({
		alg: myzod.string(),
		typ: myzod.string(),
	}),
	payload: myzod.object({
		id: myzod.string(),
		name: myzod.string(),
		surname: myzod.string(),
		expiresIn: myzod.number(),
	}),
	signature: myzod.string(),
});

export type AccessToken = {
	token: string;
	value: Infer<typeof accessTokenSchema> | null;
};

export function getAccessToken(): AccessToken {
	const token = Cookie.get('soar-access-token');
	if (!token) {
		return {
			token: '',
			value: null,
		};
	}
	try {
		const [scopeStr, payloadStr, signature] = token.split('.');

		const result = {
			scope: JSON.parse(Buffer.from(scopeStr, 'base64').toString()),
			payload: JSON.parse(Buffer.from(payloadStr, 'base64').toString()),
			signature,
		};

		const value = accessTokenSchema.parse(result);

		return {
			token,
			value,
		};
	} catch (error) {
		console.warn(error);
		return {
			token: '',
			value: null,
		};
	}
}
