import { makeObservable, action, computed, observable } from 'mobx';

import { ValidationControl } from './ValidationControl';

import type { ValidatorsFunction } from './validation';

export type Comparer<TEntity> = (prev: TEntity, next: TEntity) => boolean;

export type ValueOrGetter<TValue> = TValue | (() => TValue);
export type FormControlOptions<
	TControl extends FormControl<any, any>,
	TControlValue = TControl['value'],
> = {
	/**
	 * Validations
	 * / Валидациии
	 */
	validators?: ValidatorsFunction<TControl>[];
	/**
	 * Функция для сравнения значений, должна возвращать true для равных значений
	 */
	comparer?: Comparer<TControlValue> /**
	 * Callback always when value changes
	 * / Срабатывает всегда при изменении значения
	 */;
	onChangeValue?: (value: TControlValue) => void;
	/**
	 * Callback get last valid value
	 * / Передает последние валидное значение
	 */
	onChangeValidValue?: (value: TControlValue) => void;
	/**
	 * Invoke `onChangeValidValue` when `FormControl` is created..
	 * / Вызвать `onChangeValidValue` при создании `FormControl`.
	 */
	callSetterOnInitialize?: boolean;
};

export class FormControl<
	Element extends any = unknown,
	TControlValue extends any = Element extends { value: any } ? Element['value'] : unknown,
> extends ValidationControl<TControlValue> {
	static readonly noValueAssigned: unique symbol = Symbol('No value was assigned');
	private readonly onChangeValidValue: ((value: TControlValue) => void) | undefined = undefined;
	private readonly onChangeValue: ((value: TControlValue) => void) | undefined = undefined;
	private internalValue: typeof FormControl.noValueAssigned | TControlValue =
		FormControl.noValueAssigned;
	private initialValueGetter!: () => TControlValue;

	/**
	 * Value changed
	 * / Значение изменялось
	 */
	private _isDirty: boolean = false;

	/**
	 * The field was in focus
	 * / Поле было в фокусе
	 */
	private _isTouched: boolean = false;

	/**
	 * The field is now in focus
	 * / Поле сейчас в фокусе
	 */
	private _isFocused: boolean = false;
	element: Element | null = null;
	readonly comparer: Comparer<TControlValue>;

	constructor(
		/**
		 * При инициализации моделей рекомендуется передавать функцию геттер начального значения,
		 * а не само значение. Так мы получаем "реактивные" модели контролов, которые подписываются
		 * на изменения observable переменных и всегда имеют актуальное значение переменной.
		 * Само значение используется при создании "изолированной" модели контрола или когда модель
		 * инициализируется не observable значениями.
		 */
		valueOrGetter: ValueOrGetter<TControlValue>,
		{
			validators = [],
			comparer,
			onChangeValidValue,
			onChangeValue,
			callSetterOnInitialize,
		}: FormControlOptions<FormControl<Element, TControlValue>> = {},
	) {
		super({ validators: validators.map((v) => () => v(this)) });
		this.onChangeValidValue = onChangeValidValue;
		this.onChangeValue = onChangeValue;

		this.setInitialValue(valueOrGetter);

		this.comparer = comparer || ((prev: TControlValue, next: TControlValue) => prev === next);
		makeObservable<
			typeof this,
			'internalValue' | '_isDirty' | '_isTouched' | '_isFocused' | 'initialValueGetter'
		>(this, {
			internalValue: observable,
			value: computed.struct,

			isInvalid: computed,
			isValid: computed,

			_isDirty: observable,
			isDirty: computed,

			_isTouched: observable,
			isTouched: computed,

			_isFocused: observable,
			isFocused: computed,

			isChanged: computed,

			initialValueGetter: observable,

			setDirty: action.bound,
			setTouched: action.bound,
			setFocused: action.bound,
			setValue: action.bound,

			reset: action.bound,
		});

		if (callSetterOnInitialize) {
			if (this.onChangeValidValue) {
				if (this.isValid) {
					this.onChangeValidValue(this.value);
				}
			}
		}
	}
	get initialValue() {
		return this.initialValueGetter();
	}
	get value(): TControlValue {
		if (this.internalValue === FormControl.noValueAssigned) {
			return this.initialValue;
		}
		return this.internalValue;
	}

	/**
	 * Changed value is not equal to initializing value
	 * / Измененное значение не равно инициализирующему значению
	 */
	public get isChanged() {
		return !this.comparer(this.initialValue, this.value);
	}

	/**
	 * Invalid
	 * / Невалидные данные
	 */
	get isInvalid(): boolean {
		return this.errors.length > 0;
	}

	/**
	 * Valid
	 * / Валидные данные
	 */
	get isValid(): boolean {
		return !this.isInvalid;
	}

	/**
	 * Value changed
	 * / Значение изменялось
	 */
	get isDirty(): boolean {
		return this._isDirty;
	}

	/**
	 * The field was in focus
	 * / Поле было в фокусе
	 */
	get isTouched(): boolean {
		return this._isTouched;
	}

	get isFocused(): boolean {
		return this._isFocused;
	}

	/**
	 * Установить начальное значение
	 */
	setInitialValue(valueOrGetter: ValueOrGetter<TControlValue>) {
		this.initialValueGetter =
			valueOrGetter instanceof Function ? () => valueOrGetter() : () => valueOrGetter;
	}

	/**
	 * Установить значение
	 */
	setValue(value: TControlValue) {
		this._isDirty = true;
		this.internalValue = value;
		this.onChangeValue && this.onChangeValue(value);
		if (this.onChangeValidValue) {
			if (this.isValid) {
				this.onChangeValidValue(this.value);
			}
		}
		return this;
	}

	/**
	 * Set marker "Value has changed"
	 * / Установить маркер "Значение изменилось"
	 */
	setDirty = (dirty: boolean) => {
		this._isDirty = dirty;
		return this;
	};

	/**
	 * Set marker "field was in focus"
	 * / Установить маркер "Поле было в фокусе"
	 */
	setTouched(touched: boolean) {
		this._isTouched = touched;
		return this;
	}

	/**
	 * Set marker "field in focus"
	 * / Установить маркер "Поле в фокусе"
	 */
	setFocused(focused: boolean) {
		this._isFocused = focused;
		return this;
	}

	/**
	 * Сбросить состояние формы
	 */
	reset() {
		this.internalValue = FormControl.noValueAssigned;
		this.setDirty(false);
		this.setFocused(false);
		this.setTouched(false);
		return this;
	}
}
