export class ScrollBarHelper {
	private _element: HTMLElement;
	private _originalPaddingRight: string | null;
	private _scrollbarWidth: number;
	constructor() {
		this._element = document.body;
		this._originalPaddingRight = null;
		this._scrollbarWidth = 0;
	}

	// Получаем ширину скроллбара
	getWidth() {
		const documentWidth = document.documentElement.clientWidth;
		return Math.abs(window.innerWidth - documentWidth);
	}

	// Скрываем скроллбар
	hide() {
		this._scrollbarWidth = this.getWidth();
		this._originalPaddingRight = this._element.style.paddingRight;

		this._element.style.overflow = 'hidden';
		this._element.style.paddingRight = `${parseFloat(this._originalPaddingRight) || 0 + this._scrollbarWidth}px`;
	}

	// Показываем скроллбар
	show() {
		this._element.style.overflow = '';
		this._element.style.paddingRight = this._originalPaddingRight || '';
	}
}
