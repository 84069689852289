import { Button as AntButton, ConfigProvider } from 'antd';
import { forwardRef } from 'react';

import { ButtonType, ButtonVariant, useButtonTheme } from './theme';

import type { ButtonProps as AntButtonProps } from 'antd';

export type ButtonProps = Omit<AntButtonProps, 'danger' | 'type' | 'variant'> & {
	type?: ButtonType;
	variant?: ButtonVariant;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
	{ className, variant = 'default', type = 'default', ghost, ...props },
	ref,
) {
	const { theme, prefix } = useButtonTheme(variant, type, ghost);

	return (
		<ConfigProvider theme={theme}>
			<AntButton
				prefixCls={prefix}
				ref={ref}
				className={className}
				type={type === 'fill' ? 'primary' : type}
				ghost={ghost}
				{...props}
			/>
		</ConfigProvider>
	);
});

Button.displayName = 'ForwardRef shared/ui/buttons/(Button)';
