import {
	DefaultError,
	OmitKeyof,
	QueryClient,
	QueryKey,
	QueryObserver,
	QueryObserverOptions,
	QueryObserverResult,
	notifyManager,
} from '@tanstack/query-core';
import { IResource, fromResource } from 'mobx-utils';

export type FromBaseQueryOptions<
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
> = QueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>;

export type FromQueryOptions<
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
> = OmitKeyof<
	FromBaseQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
	'suspense'
>;

export type QueryResultResource<TData, TError = Error> = IResource<
	QueryObserverResult<TData, TError>
>;

export type FromQueryResult<
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
> = {
	result: QueryResultResource<TData, TError>;
	observer: QueryObserver<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>;
};

export function fromQuery<
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	client: QueryClient,
	options: FromQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): FromQueryResult<TQueryFnData, TError, TData, TQueryKey> {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	let unsubscribe = () => {};

	// Prepare options to observer
	/** @link https://github.com/TanStack/query/blob/main/packages/react-query/src/suspense.ts#L21 */
	const defaultedOptions = client.defaultQueryOptions(options);
	if (defaultedOptions.suspense) {
		// Always set stale time when using suspense to prevent
		// fetching again when directly mounting after suspending
		if (typeof defaultedOptions.staleTime !== 'number') {
			defaultedOptions.staleTime = 1000;
		}
	}

	const observer = new QueryObserver(client, defaultedOptions);

	const result = fromResource<QueryObserverResult<TData, TError>>(
		(sink) => {
			/**
			 * Sync initial state. This is a simplified version of the logic in useBaseQuery from react-query repo.
			 * @link https://github.com/TanStack/query/blob/main/packages/react-query/src/useBaseQuery.ts#L134
			 */
			const optimisticResult = observer.getOptimisticResult(defaultedOptions);
			const result = !defaultedOptions.notifyOnChangeProps
				? observer.trackResult(optimisticResult)
				: optimisticResult;
			sink(result);

			unsubscribe = observer.subscribe(
				notifyManager.batchCalls((updatedResult) => {
					sink(updatedResult);
				}),
			);
		},
		() => unsubscribe(),
		observer.getCurrentResult(),
	);

	return {
		observer,
		result,
	};
}
