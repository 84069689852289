import { RouteObject } from 'react-router';

import type { AppConfig } from '@/shared/config/AppConfig';
import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { UserPage } = await import('./ui/UserPage');
	return {
		default: UserPage,
	};
};

export const userPageRoute = (appConfig: AppConfig, layoutModel: LayoutModel): RouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const userID = params.params.userID || '';
			const { UserPageModel } = await import('./model/UserPageModel');
			const SingletonModel = singleton(UserPageModel, userID);
			const model = new SingletonModel(userID, appConfig);
			if (!model.userRequest.isLoaded) {
				await model.userRequest.request();
			}

			const tab = new URL(params.request.url).searchParams.get('tab');

			if (tab && UserPageModel.allowTabKeys.includes(tab)) {
				model.navbar.setActiveKey(tab);
			}

			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.user.path,
		},
	);
