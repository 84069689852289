import { EllipsisOutlined, StarOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import classNames from 'classnames';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useFormatDate } from '@/shared/lib/date/useFormatDate';
import { Button } from '@/shared/ui/Button';

import { ChatModel } from '../../model/ChatModel';

import { MessengerModel } from '../../model/MessengerModel';
import { CategoryTag } from '../CategoryTag';

import styles from './chat-list-item.module.scss';

type ChatListItemProps = {
	item: ChatModel;
	model: MessengerModel;
	isImportantList?: boolean;
};

export const ChatListItem = observer<ChatListItemProps>(function ChatListItem({
	item,
	model,
	isImportantList,
}) {
	const formatDate = useFormatDate();
	const chat = item.chat;

	const actions = (
		<div className={styles.actions}>
			<Button type="text" variant="secondary" className={styles.button}>
				<StarOutlined className={styles.action_icon} />
			</Button>
			<Button type="text" variant="secondary" className={styles.button}>
				<EllipsisOutlined className={styles.action_icon} />
			</Button>
		</div>
	);

	const dateString = item.date ? formatDate(item.date, 'dd.MM.yyyy') : '';

	const isSelected = computed(() => item === model.chatList.selectedChat).get();

	return (
		<div
			className={classNames(styles.wrapper, {
				[styles.selected]: isSelected,
			})}
			onClick={() => model.chatList.setSelectedChat(item)}
		>
			{isImportantList ? (
				<>
					<Flex justify="space-between">
						<div className={styles.name}>{item.title}</div>
						<span className={styles.disabled}>{dateString}</span>
					</Flex>
					<Flex justify="space-between" align="center">
						<div className={styles.message}>{item.text}</div>
						<CategoryTag category={chat.isIncident ? 'incidents' : 'private'} />
					</Flex>
				</>
			) : chat.type === 'private' ? (
				<>
					<Flex justify="space-between">
						<div className={styles.name}>{item.title}</div>
						{actions}
						<span className={classNames(styles.disabled, styles.date)}>
							{dateString}
						</span>
					</Flex>
					{item.lastMessage ? (
						<div className={styles.message}>{item.lastMessage.text}</div>
					) : (
						<span className={styles.disabled}>Нет сообщений</span>
					)}
				</>
			) : (
				chat.isIncident && (
					<>
						<Flex align="center" justify="space-between">
							<div className={styles.name}>{item.title}</div>
							{actions}
						</Flex>
						<span className={styles.disabled}>{dateString}</span>
					</>
				)
			)}
		</div>
	);
});
