import { observer } from 'mobx-react-lite';
import { useLayoutEffect } from 'react';

import { AppConfig } from '@/shared/config/AppConfig';
import { getComponentDisplayName } from '@/shared/lib/getComponentDisplayName';
import { useStore } from '@/shared/lib/useStore';
import { MessengerModel, MessengerModelContext } from '@/widgets/messenger';

export const messengerProvider = <P extends { [K in keyof P]?: P[K] }>(
	WrappedComponent: React.FC<P>,
) => {
	const WithMessengerProvider = observer<P & { appConfig: AppConfig }>(
		function WithMessengerProvider(props: P & { appConfig: AppConfig }) {
			const model = useStore(() => new MessengerModel(props.appConfig), [props.appConfig]);

			useLayoutEffect(() => {
				model.query.init();
				model.aiQuery.init();
			}, [model.aiQuery, model.query]);

			return (
				<MessengerModelContext.Provider value={model}>
					<WrappedComponent {...props} />
				</MessengerModelContext.Provider>
			);
		},
	);

	WithMessengerProvider.displayName = `WithMessengerProvider(${getComponentDisplayName(WrappedComponent)})`;
	return WithMessengerProvider;
};
