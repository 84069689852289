import { ValidationControl } from './ValidationControl';
export const ValidationEventTypes = {
	Error: 0,
	Warning: 1,
	Info: 2,
	Success: 3,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ValidationEventTypes = (typeof ValidationEventTypes)[keyof typeof ValidationEventTypes];

export type ValidationEvent = {
	key?: string;
	message: string;
	type: ValidationEventTypes;
	additionalData?: any;
};
export type ValidatorsFunction<TControl extends ValidationControl<any>> = (
	control: TControl,
) => ValidationEvent[];
