import {
	CloseOutlined,
	FilterFilled,
	LeftOutlined,
	SearchOutlined,
	SecurityScanOutlined,
	ShrinkOutlined,
	StarOutlined,
	TeamOutlined,
} from '@ant-design/icons';
import { Col, Flex, Row, Tooltip } from 'antd';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useAuthModel } from '@/features/auth';
import { useFormatDate } from '@/shared/lib/date/useFormatDate';
import { Button } from '@/shared/ui/Button';

import { AIIcon } from '@/shared/ui/icons/AIIcon';

import { ChatCategory, MessengerModel } from '../../model/MessengerModel';
import { AIChat } from '../AIChat';
import { ChatInput } from '../ChatInput';
import { ChatSearchInput } from '../ChatSearchInput';
import { ChatsList } from '../ChatsList';
import { DayTag } from '../DayTag';

import { MessagesList } from '../MessagesList';

import styles from './chat.module.scss';

type ChatProps = {
	model: MessengerModel;
};

const categoryMap: Record<ChatCategory, string> = {
	private: 'Личные',
	favorite: 'Важные',
	incidents: 'Инциденты',
};

export const Chat = observer<ChatProps>(function Chat({ model }) {
	const formatDate = useFormatDate();

	const actionButtons = (
		<Flex>
			<Button type="text" className={styles.action_button} onClick={model.toggleChatCollapse}>
				<ShrinkOutlined className={styles.action_icon} />
			</Button>
			<Button type="text" className={styles.action_button} onClick={model.toggleChatIsOpen}>
				<CloseOutlined className={styles.action_icon} />
			</Button>
		</Flex>
	);

	const chatIsCollapsed = model.chatIsCollapsed.isEnabled;

	const authModel = useAuthModel();

	return (
		<div
			className={classNames(styles.wrapper, {
				[styles.full_chat]: !chatIsCollapsed,
			})}
		>
			{!chatIsCollapsed && <Flex justify="flex-end">{actionButtons}</Flex>}
			<Row
				className={classNames(styles.row, {
					[styles.collapsedRow]: chatIsCollapsed,
				})}
			>
				{!chatIsCollapsed && (
					<>
						<Col className={styles.first_column}>
							<div className={styles.container}>
								<div className={styles.title}>Сообщения</div>
								<div className={styles.main}>
									<Button
										type="text"
										className={classNames(styles.category_button, {
											[styles.selected]: model.selectedCategory === 'private',
										})}
										onClick={() => model.setSelectedCategory('private')}
									>
										<TeamOutlined className={styles.icon} />
										{categoryMap['private']}
									</Button>
									<Button
										type="text"
										className={classNames(styles.category_button, {
											[styles.selected]:
												model.selectedCategory === 'favorite',
										})}
										onClick={() => model.setSelectedCategory('favorite')}
									>
										<StarOutlined className={styles.icon} />
										{categoryMap['favorite']}
									</Button>
									<Button
										type="text"
										className={classNames(styles.category_button, {
											[styles.selected]:
												model.selectedCategory === 'incidents',
										})}
										onClick={() => model.setSelectedCategory('incidents')}
									>
										<SecurityScanOutlined className={styles.icon} />
										{categoryMap['incidents']}
									</Button>
								</div>
								<div className={styles.userName}>{authModel.user.fio}</div>
							</div>
						</Col>
						<Col className={styles.second_column}>
							<div className={styles.title}>
								{model.listSearchInputToggle.isEnabled ? (
									<ChatSearchInput model={model} type="global" />
								) : (
									<>
										<Flex align="center">
											<div className={styles.title_text}>
												{categoryMap[model.selectedCategory]}
											</div>
											{/*
											TODO: DEMO
											{model.selectedCategory === 'favorite' && (
												<Button
													type="text"
													variant="secondary"
													className={styles.filter_button}
												>
													<FilterFilled className={styles.filter_icon} />
												</Button>
											)} */}
										</Flex>

										{/* 
											TODO: DEMO
										<Tooltip
											title="Глобальный поиск"
											zIndex={3002}
											placement="bottom"
										>
											<Button
												type="text"
												variant="secondary"
												className={styles.search_button}
												onClick={model.listSearchInputToggle.toggle}
											>
												<SearchOutlined className={styles.action_icon} />
											</Button>
										</Tooltip> */}
									</>
								)}
							</div>
							<div className={styles.list}>
								{model.selectedCategory === 'private' && (
									<ChatsList chats={model.chatList.privateChats} model={model} />
								)}
								{model.selectedCategory === 'favorite' && (
									<ChatsList
										chats={model.chatList.favoriteChats}
										model={model}
										isImportantList
									/>
								)}
								{model.selectedCategory === 'incidents' && (
									<ChatsList chats={model.chatList.incidentChats} model={model} />
								)}
							</div>
						</Col>
					</>
				)}
				<Col className={styles.third_column}>
					<div className={styles.container}>
						{model.chatList.selectedChat ? (
							<>
								<div className={styles.title}>
									{model.messageSearchInputToggle.isEnabled ? (
										<ChatSearchInput model={model} type="messages" />
									) : (
										<>
											<span className={styles.title_text}>
												{model.chatList.selectedChat?.title}
											</span>
											{chatIsCollapsed ? (
												actionButtons
											) : (
												<Flex gap={16} align="center">
													<span className={styles.date}>
														{model.chatList.selectedChat.date
															? formatDate(
																	model.chatList.selectedChat
																		.date,
																	'dd.MM.yyyy HH:mm:ss',
																)
															: ''}
													</span>
													{/*
													TODO: DEMO 
													<Tooltip
														title="Поиск по сообщениям"
														zIndex={3001}
														placement="bottom"
													>
														<Button
															type="text"
															variant="secondary"
															className={styles.search_button}
															onClick={
																model.messageSearchInputToggle
																	.toggle
															}
														>
															<SearchOutlined
																className={styles.action_icon}
															/>
														</Button>
													</Tooltip> */}
												</Flex>
											)}
										</>
									)}
								</div>
								<div className={styles.chat_content}>
									<DayTag date={model.chatList.selectedChat.date} />
									<MessagesList
										model={model}
										messages={model.chatList.selectedChat.messages}
										chat={model.chatList.selectedChat}
									/>
									<ChatInput type="chat" model={model.chatInput} />
								</div>
							</>
						) : (
							<>
								{chatIsCollapsed && (
									<div className={classNames(styles.title, styles.empty_title)}>
										{actionButtons}
									</div>
								)}
								<div className={styles.empty_content}>
									<h2>Кому бы вы хотели написать?</h2>
									<span>Выберите собеседника и начните диалог</span>
								</div>
							</>
						)}
					</div>
				</Col>
				{!chatIsCollapsed && (
					<Col>
						<div
							className={classNames(styles.container, styles.sidebar, {
								[styles.sidebar_open]: model.AIChatIsOpen.isEnabled,
							})}
						>
							{model.AIChatIsOpen.isEnabled ? (
								<AIChat model={model} type="chat" />
							) : (
								<>
									<Button
										icon={<LeftOutlined />}
										className={styles.sidebar_button}
										onClick={model.AIChatIsOpen.enable}
									/>
									<AIIcon className={styles.ai_icon} />
								</>
							)}
						</div>
					</Col>
				)}
			</Row>
		</div>
	);
});
