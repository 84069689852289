import { reaction } from 'mobx';
import { ReactNode, memo, useEffect } from 'react';

import { matchPath, useLocation } from 'react-router';

import { LayoutModel, LayoutModelContext } from '../model/LayoutModel';

export type LayoutProviderProps = {
	children: ReactNode;
	model: LayoutModel;
};

export const LayoutProvider = memo<LayoutProviderProps>(function LayoutProvider({
	children,
	model,
}) {
	const location = useLocation();

	useEffect(() => {
		const disposeNavbarReaction = reaction(
			() =>
				model.navbar.routeItems.find(
					({ item }) => item.route?.path && matchPath(item.route.path, location.pathname),
				),
			(item) => {
				model.navbar.setActiveItem(item?.item);
			},
			{ fireImmediately: true },
		);

		const disposeTabsNavbarReaction = reaction(
			() => model.tabsNavbar.navItems.find(({ to }) => matchPath(to, location.pathname)),
			(item) => {
				model.tabsNavbar.setSelectedKey(item?.key);
			},
			{ fireImmediately: true },
		);

		return () => {
			disposeNavbarReaction();
			disposeTabsNavbarReaction();
		};
	}, [
		model.navbar,
		model.navbar.navItems,
		model.tabsNavbar,
		model.tabsNavbar.navItems,
		location,
	]);

	return <LayoutModelContext.Provider value={model}>{children}</LayoutModelContext.Provider>;
});
