import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
	<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3049_22133)">
			<path
				d="M13.9284 6.03153V4.9601C13.9284 4.86189 13.848 4.78153 13.7498 4.78153H5.17836C5.08015 4.78153 4.99979 4.86189 4.99979 4.9601V6.03153C4.99979 6.12974 5.08015 6.2101 5.17836 6.2101H13.7498C13.848 6.2101 13.9284 6.12974 13.9284 6.03153ZM5.17836 7.99581C5.08015 7.99581 4.99979 8.07617 4.99979 8.17438V9.24581C4.99979 9.34403 5.08015 9.42438 5.17836 9.42438H9.28551C9.38372 9.42438 9.46408 9.34403 9.46408 9.24581V8.17438C9.46408 8.07617 9.38372 7.99581 9.28551 7.99581H5.17836ZM8.39265 18.0851H3.21408V2.37081H15.7141V10.0494C15.7141 10.1476 15.7944 10.228 15.8926 10.228H17.1426C17.2409 10.228 17.3212 10.1476 17.3212 10.0494V1.47796C17.3212 1.08287 17.002 0.763672 16.6069 0.763672H2.32122C1.92613 0.763672 1.60693 1.08287 1.60693 1.47796V18.978C1.60693 19.373 1.92613 19.6922 2.32122 19.6922H8.39265C8.49086 19.6922 8.57122 19.6119 8.57122 19.5137V18.2637C8.57122 18.1655 8.49086 18.0851 8.39265 18.0851ZM18.3413 19.2346L16.2587 17.1521C16.7565 16.4936 17.0534 15.6722 17.0534 14.7815C17.0534 12.6119 15.2944 10.853 13.1248 10.853C10.9551 10.853 9.19622 12.6119 9.19622 14.7815C9.19622 16.9512 10.9551 18.7101 13.1248 18.7101C13.9239 18.7101 14.665 18.4713 15.2855 18.0628L17.3993 20.1766C17.4351 20.2123 17.4797 20.228 17.5243 20.228C17.569 20.228 17.6159 20.2101 17.6493 20.1766L18.3413 19.4846C18.3578 19.4683 18.3708 19.4488 18.3798 19.4273C18.3887 19.4059 18.3933 19.3829 18.3933 19.3596C18.3933 19.3364 18.3887 19.3134 18.3798 19.292C18.3708 19.2705 18.3578 19.251 18.3413 19.2346ZM13.1248 17.2815C11.7431 17.2815 10.6248 16.1632 10.6248 14.7815C10.6248 13.3998 11.7431 12.2815 13.1248 12.2815C14.5065 12.2815 15.6248 13.3998 15.6248 14.7815C15.6248 16.1632 14.5065 17.2815 13.1248 17.2815Z"
				fill="url(#paint0_linear_3049_22133)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_3049_22133"
				x1="-1.51053"
				y1="0.485611"
				x2="23.7392"
				y2="21.9459"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
			<clipPath id="clip0_3049_22133">
				<rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);

export const FileSearchGradientIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={Svg} {...props} />
);
