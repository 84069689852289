import { DefaultError, QueryClient, QueryKey, QueryObserver } from '@tanstack/query-core';
import { computed, makeObservable, when } from 'mobx';

import { fromQuery, FromQueryOptions, QueryResultResource } from './fromQuery';

export class QueryWithState<
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
	TOptions extends FromQueryOptions<TQueryFnData, TError, TData, TQueryKey> = FromQueryOptions<
		TQueryFnData,
		TError,
		TData,
		TQueryKey
	>,
> {
	private query: QueryResultResource<TData, TError>;
	private observer: QueryObserver<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>;
	constructor(
		client: QueryClient,
		options: FromQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	) {
		const fromQueryResult = fromQuery(client, options);
		this.query = fromQueryResult.result;
		this.observer = fromQueryResult.observer;
		makeObservable<typeof this>(this, {
			current: computed,
			data: computed,
			dataUpdatedAt: computed,
			error: computed,
			isError: computed,
			isPending: computed,
			isLoadingError: computed,
			isRefetchError: computed,
			isSuccess: computed,
			status: computed,
			errorUpdateCount: computed,
			errorUpdatedAt: computed,
			failureCount: computed,
			failureReason: computed,
			fetchStatus: computed,
			isFetched: computed,
			isFetchedAfterMount: computed,
			isFetching: computed,
			isLoading: computed,
			isPaused: computed,
			isPlaceholderData: computed,
			isRefetching: computed,
			isStale: computed,
			refetch: computed,
		});
	}

	setOptions(options: FromQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
		this.observer.setOptions(options, { listeners: true });
	}

	async init() {
		if (!this.isFetched) {
			this.refetch();
			await when(() => this.isFetched);
		}
	}

	get current() {
		return this.query.current();
	}
	get data() {
		return this.current.data as TOptions['initialData'] extends undefined
			? TData | undefined
			: TData;
		// return this.current.data;
	}
	get dataUpdatedAt() {
		return this.current.dataUpdatedAt;
	}
	get error() {
		return this.current.error;
	}
	get isError() {
		return this.current.isError;
	}
	get isPending() {
		return this.current.isPending;
	}
	get isLoadingError() {
		return this.current.isLoadingError;
	}
	get isRefetchError() {
		return this.current.isRefetchError;
	}
	get isSuccess() {
		return this.current.isSuccess;
	}
	get status() {
		return this.current.status;
	}
	get errorUpdateCount() {
		return this.current.errorUpdateCount;
	}
	get errorUpdatedAt() {
		return this.current.errorUpdatedAt;
	}
	get failureCount() {
		return this.current.failureCount;
	}
	get failureReason() {
		return this.current.failureReason;
	}
	get fetchStatus() {
		return this.current.fetchStatus;
	}
	get isFetched() {
		return this.current.isFetched;
	}
	get isFetchedAfterMount() {
		return this.current.isFetchedAfterMount;
	}
	get isFetching() {
		return this.current.isFetching;
	}
	get isLoading() {
		return this.current.isLoading;
	}
	get isPaused() {
		return this.current.isPaused;
	}
	get isPlaceholderData() {
		return this.current.isPlaceholderData;
	}
	get isRefetching() {
		return this.current.isRefetching;
	}
	get isStale() {
		return this.current.isStale;
	}
	get refetch() {
		return this.current.refetch;
	}
}
