import { makeAutoObservable } from 'mobx';

import { mockRequestAiChats } from '@/shared/api/mock/aiChats';
import { mockRequestChats } from '@/shared/api/mock/chats';
import { AppConfig } from '@/shared/config/AppConfig';
import { List } from '@/shared/lib/collections/List';
import { RepositoriesManager } from '@/shared/lib/collections/RepositoriesManager';
import { QueryWithState } from '@/shared/lib/transports/QueryWithState';

import { ChatsResponseDto } from '../../lib/ChatDto';
import { ChatDto, ChatEntity, ChatRepository } from '../ChatEntity';
import {
	ChatMessageBaseDto,
	ChatMessageBaseEntity,
	ChatMessageBaseRepository,
} from '../ChatMessageEntity';
import { ChatUserDto, ChatUserEntity, ChatUserRepository } from '../ChatUserEntity';

export type ChatsStore = {
	chats: List<ChatEntity, ChatDto>;
	messages: List<ChatMessageBaseEntity, ChatMessageBaseDto>;
	users: List<ChatUserEntity, ChatUserDto>;
};

export type ChatsQueryState = QueryWithState<ChatsResponseDto | null, Error, ChatsStore, string[]>;

/**
 * Messenger больше подходит для названия виджета
 * Chat - это отдельный элемент списка в Messenger
 */
export class MessengerQueryModel {
	public repositories: RepositoriesManager;
	constructor(
		public appConfig: AppConfig,
		private type: 'ai' | 'chats',
	) {
		makeAutoObservable(this, {}, { autoBind: true });
		this.repositories = appConfig.repositories;
	}

	private chatsStore: ChatsStore = {
		chats: new List<ChatEntity, ChatDto>({
			createItem: (dto) => {
				const item = this.repositories.get(ChatRepository).get(dto.id);
				item.updateFromJson(dto);
				return item;
			},
		}),
		messages: new List<ChatMessageBaseEntity, ChatMessageBaseDto>({
			createItem: (dto) => {
				const item = this.repositories.get(ChatMessageBaseRepository).get(dto.id);
				item.updateFromJson(dto);
				return item;
			},
		}),
		users: new List<ChatUserEntity, ChatUserDto>({
			createItem: (dto) => {
				const item = this.repositories.get(ChatUserRepository).get(dto.id);
				item.updateFromJson(dto);
				return item;
			},
		}),
	};

	private chatsQuery: ChatsQueryState = new QueryWithState(this.appConfig.queryClient, {
		queryKey: [this.type as string],
		queryFn: async () => {
			await new Promise((r) => setTimeout(r, 1000));
			return this.type === 'ai' ? mockRequestAiChats() : mockRequestChats();
		},
		select: (response) => {
			if (response !== null) {
				this.chatsStore.chats.replace(response.chats);
				this.chatsStore.users.replace(response.users);
				this.chatsStore.messages.replace(response.messages);
			}
			return this.chatsStore;
		},
		initialData: null,
	});

	init() {
		this.chatsQuery.init();
	}

	get chatsQueryState() {
		return this.chatsQuery;
	}

	get usersQueryState() {
		return this.chatsQuery;
	}

	get messagesQueryState() {
		return this.chatsQuery;
	}

	get chats() {
		return this.chatsQuery.data.chats;
	}

	get users() {
		return this.chatsQuery.data.users;
	}

	get messages() {
		return this.chatsQuery.data.messages;
	}
}
