import { faker } from '@faker-js/faker';
import { random } from 'lodash';
import { v4 } from 'uuid';

import { UserDto } from '../model/UserEntity';

export function getUserDto({
	id = v4(),
	name = faker.person.firstName(),
	surname = faker.person.lastName(),
	email = faker.internet.email(),
	avatar = faker.image.avatar(),
	patronymic = faker.person.middleName(),
	username = faker.internet.userName(),
	permissions = [],
	lastLoginDate = new Date().toISOString(),
	lastUpdateDate = new Date().toISOString(),
	registrationDate = new Date().toISOString(),
	accountType = faker.hacker.abbreviation(),
	roles = [],
	hasTwoFactorAuth = !random(0, 1),
	hasSystemAccess = !random(0, 1),
}: Partial<UserDto>): UserDto {
	return {
		id,
		name,
		surname,
		email,
		avatar,
		patronymic,
		username,
		permissions,
		lastLoginDate,
		accountType,
		roles,
		lastUpdateDate,
		registrationDate,
		hasTwoFactorAuth,
		hasSystemAccess,
	};
}
