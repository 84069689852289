import { flow, makeAutoObservable, runInAction } from 'mobx';

import { CancellablePromise } from 'mobx/dist/internal';

import { IS_DEV_ENV } from '@/shared/config';
import { ErrorData, getErrorData } from '@/shared/lib/errors';

export type RequestWithStateOptions<Result> = {
	isResolveLatest?: boolean;
	callback?: (result: Result | null, error: ErrorData | null) => void;
};
export class RequestWithState<
	Func extends (params: Args[0], init?: RequestInit) => Promise<Result>,
	Args extends any[] = Parameters<Func>,
	Result = Awaited<ReturnType<Func>>,
> {
	private _isLoaded: boolean = false;
	private _isLoading: boolean = false;
	private _requestError: any | null = null;
	private _errorData: ErrorData | null = null;

	/**
	 * @param requestFunction - асинхронная функция, вокруг которой будет создана обертка с состоянием
	 * @param isResolveLatest - стоит ли отменять предыдущий отправленный запрос перед отправкой нового,
	 * по умолчанию - false, что означает: если предыдущий запрос не успел завершиться при отправке
	 * нового запроса - новый запрос не будет отправлен, будет возвращен промис предыдущего запроса
	 */
	constructor(
		private readonly requestFunction: Func,
		private options: RequestWithStateOptions<Result> = {},
	) {
		this.options.isResolveLatest = this.options.isResolveLatest || false;
		makeAutoObservable<
			RequestWithState<Func, Args, Result>,
			| 'prevState'
			| 'requestFunction'
			| 'isResolveLatest'
			| '_cancellablePromise'
			| 'abortController'
			| 'options'
		>(this, {
			prevState: false,
			isResolveLatest: false,
			requestFunction: false,
			_cancellablePromise: false,
			abortController: false,
			options: false,
		});
	}

	private abortController: AbortController | null = null;

	private prevState: Record<string, any> = {};

	private readonly requestFlow = flow(function* (
		this: RequestWithState<Func, Args, Result>,
		params: Args[0],
		init?: RequestInit,
	) {
		try {
			this.prevState._isLoading = this._isLoading;
			this._isLoading = true;
			!init && (this.abortController = new AbortController());

			const result: Result = yield this.requestFunction(
				params,
				init || {
					signal: this.abortController?.signal,
				},
			);
			this.abortController = null;

			this.prevState._errorData = this._errorData;
			this.prevState._isLoading = this._isLoading;
			this.prevState._isLoaded = this._isLoaded;

			this._isLoading = false;
			this._isLoaded = true;
			this._errorData = null;
			return result;
		} catch (error) {
			this._isLoading = false;
			this._requestError = error;
			throw error;
		}
	});

	private _cancellablePromise: CancellablePromise<Result> | null = null;

	/** Запрос не выбрасывает исключений, если запрос завершится
	 * с ошибкой, то ошибка будет записанна в состояние запроса
	 * */
	public request = async (...args: Args) => {
		/** Если выбрана схема работы, как "разрешать последний", то отменяем уже отправленный запрос */
		if (this.options.isResolveLatest) {
			this.cancel();
		}

		/** Если нет отправленного запроса, отправляем запрос заново */
		if (!this._cancellablePromise) {
			this._cancellablePromise = this.requestFlow(args[0], args[1]);
		}

		try {
			const result = await this._cancellablePromise;
			runInAction(() => {
				this._cancellablePromise = null;
				this.prevState = {};
			});
			runInAction(() => {
				this.options.callback && this.options.callback(result, null);
			});
			return result;
		} catch (error) {
			/** При отмене запроса промис разрешится с ошибкой, чтобы
			 * отличить ошибку запроса от ошибки, полученной при отмене
			 * запроса, вводится переменная _requestError */
			if (this._requestError) {
				const errorData = await getErrorData(error);
				runInAction(() => {
					this.prevState = {};
					this._cancellablePromise = null;
					this._errorData = errorData;
				});
			}
			if (IS_DEV_ENV) {
				console.error(error);
			}
			return null;
		}
	};

	public cancel(): void {
		if (this.abortController) {
			this.abortController.abort();
			this.abortController = null;
		}
		if (this._cancellablePromise) {
			this._cancellablePromise.cancel();
			/** Возвращаем состояние запроса в предыдущее состояние */
			this._cancellablePromise = null;
			this._requestError = null;
			if ('_isLoading' in this.prevState) {
				this._isLoading = this.prevState._isLoading;
			}
			if ('_isLoaded' in this.prevState) {
				this._isLoaded = this.prevState._isLoaded;
			}
			if ('errorData' in this.prevState) {
				this._errorData = this.prevState._errorData;
			}
			this.prevState = {};
		}
	}

	public reset(): void {
		this.cancel();
		this._isLoading = false;
		this._isLoaded = false;
		this._errorData = null;
		this._requestError = null;
		this.prevState = {};
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isLoaded(): boolean {
		return this._isLoaded;
	}

	get errorData(): ErrorData | null {
		return this._errorData;
	}
}
