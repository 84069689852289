import { makeAutoObservable } from 'mobx';

import { LoginFormModel } from '@/features/auth';
import { AppConfig } from '@/shared/config/AppConfig';

export class LoginPageModel {
	constructor(public appConfig: AppConfig) {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	loginForm = new LoginFormModel({
		onSubmit: (form) => {
			this.appConfig.auth.login(form.value.username, form.value.password);
		},
	});

	get isLoading() {
		return this.appConfig.auth.loginRequestState.isLoading;
	}
}
