import { observer } from 'mobx-react-lite';

import { MessengerModel } from '../../model/MessengerModel';
import { AIChat } from '../AIChat';

import { Chat } from '../Chat';
import { SidebarChat } from '../SidebarChat';

type MessengerProps = {
	model: MessengerModel;
};

export const Messenger = observer<MessengerProps>(function Messenger({ model }) {
	return (
		<>
			{model.chatIsCollapsed.isEnabled ? (
				<SidebarChat isOpen={model.chatIsOpen.isEnabled || model.AIChatIsOpen.isEnabled}>
					{model.chatIsOpen.isEnabled && <Chat model={model} />}
					{model.AIChatIsOpen.isEnabled && <AIChat model={model} type="ai" />}
				</SidebarChat>
			) : (
				model.chatIsOpen.isEnabled && <Chat model={model} />
			)}
		</>
	);
});
