import { observer } from 'mobx-react-lite';

import type { UserEntity } from '../../model/UserEntity';

import styles from './user.module.scss';

export type UserProps = {
	model: UserEntity;
};

export const User = observer<UserProps>(function User({ model }) {
	return (
		<div className={styles.container}>
			<div />
		</div>
	);
});
