import type { AppConfig } from '@/shared/config/AppConfig';

import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { WorkflowsPage } = await import('./ui/WorkflowsPage');
	return {
		default: WorkflowsPage,
	};
};

export const workflowsPageRoute = (
	appConfig: AppConfig,
	layoutModel: LayoutModel,
): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const { WorkflowsPageModel } = await import('./model/WorkflowsPageModel');
			const SingletonModel = singleton(WorkflowsPageModel);
			const model = new SingletonModel(appConfig);

			const tab = new URL(params.request.url).searchParams.get('tab');

			if (tab && WorkflowsPageModel.allowTabKeys.includes(tab)) {
				model.navbar.setActiveKey(tab);
			}

			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.workflows.path,
		},
	);
