import { action, computed, makeObservable } from 'mobx';

import { FormControl } from './FormControl';

export abstract class AbstractFormGroup<
	TControl extends FormControl<any, any> | AbstractFormGroup<any, any, any>,
	TAllControls extends FormControl<any, any>,
	TValue,
> {
	constructor() {
		makeObservable(this, {
			isInvalid: computed,
			isValid: computed,

			isDirty: computed,
			isTouched: computed,
			isFocused: computed,
			isChanged: computed,

			setDirty: action,
			setTouched: action,

			reset: action.bound,

			firstErrorControl: computed,
		});
	}

	get isChanged() {
		for (const control of this.getControls()) {
			if (control.isChanged) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Invalid
	 * / Невалидные данные
	 */
	get isInvalid(): boolean {
		for (const control of this.getControls()) {
			if (control.isInvalid) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Valid
	 * / Валидные данные
	 */
	get isValid(): boolean {
		return !this.isInvalid;
	}
	/**
	 * Value changed
	 * / Значение изменялось
	 */
	get isDirty(): boolean {
		for (const control of this.getControls()) {
			if (control.isDirty) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Один из контролов группы находился в фокуме
	 */
	get isTouched(): boolean {
		for (const control of this.getControls()) {
			if (control.isTouched) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Один из контролов группы находится в фокуме
	 */
	get isFocused(): boolean {
		for (const control of this.getControls()) {
			if (control.isFocused) {
				return true;
			}
		}
		return false;
	}

	get firstErrorControl() {
		return this.allControls().find((c) => c.isInvalid) || null;
	}
	/**
	 * Set marker "Value has changed"
	 * / Установить маркер "Значение изменилось"
	 */
	setDirty = (dirty: boolean) => {
		for (const control of this.getControls()) {
			control.setDirty(dirty);
		}
		return this;
	};

	/**
	 * Set marker "field was in focus"
	 * / Установить маркер "Поле было в фокусе"
	 */
	setTouched = (touched: boolean) => {
		for (const control of this.getControls()) {
			control.setTouched(touched);
		}
		return this;
	};

	/**
	 * Returns a complete list of FormControls without attachments (terminal elements)
	 * Возвращает полный список FormControl-ов без вложений (терминальных элементов)
	 */
	allControls(): TAllControls[] {
		let controls: TAllControls[] = [];
		for (const control of this.getControls()) {
			if ('allControls' in control) {
				/**@ts-ignore */
				controls = controls.concat(control.allControls());
			} else {
				/**@ts-ignore */
				controls.push(control);
			}
		}
		return controls;
	}

	/**
	 * Сбросить состояние всех контроллов группы
	 *
	 */
	reset() {
		for (const control of this.getControls()) {
			control.reset();
		}
		return this;
	}

	/**
	 * Values of all FormControls
	 * / Значениями всех FormControl-ов
	 */
	abstract get value(): TValue;

	/**
	 * Values of the modified FormControls
	 * / Значения измененных FormControl-ов
	 */
	abstract get changedValue(): Partial<TValue>;
	/**
	 * First FormControl with error
	 * Первый FormControl с ошибкой
	 */
	abstract setValue(value: any): void;

	protected abstract getControls(): IterableIterator<TControl>;
}
