import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
	<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3049_22128)">
			<path
				d="M2.87951 16.8379C2.87951 17.233 3.19871 17.5522 3.5938 17.5522H16.4063C16.8014 17.5522 17.1206 17.233 17.1206 16.8379V11.637C17.1206 7.70396 13.9331 4.51646 10 4.51646C6.06701 4.51646 2.87951 7.70396 2.87951 11.637V16.8379ZM4.48665 11.637C4.48665 8.59235 6.95541 6.1236 10 6.1236C13.0447 6.1236 15.5134 8.59235 15.5134 11.637V15.945H7.58933V12.1281C7.58933 12.0053 7.48889 11.9049 7.36612 11.9049H6.38398C6.26121 11.9049 6.16076 12.0053 6.16076 12.1281V15.945H4.48665V11.637ZM3.41299 6.00084L4.29692 5.11691C4.36612 5.04771 4.36612 4.93387 4.29692 4.86468L2.7813 3.34905C2.74774 3.31582 2.70241 3.29718 2.65518 3.29718C2.60795 3.29718 2.56263 3.31582 2.52907 3.34905L1.64514 4.23298C1.6119 4.26654 1.59326 4.31186 1.59326 4.3591C1.59326 4.40633 1.6119 4.45165 1.64514 4.48521L3.16076 6.00084C3.22996 6.07003 3.34157 6.07003 3.41299 6.00084ZM18.3594 4.23298L17.4755 3.34905C17.4419 3.31582 17.3966 3.29718 17.3494 3.29718C17.3021 3.29718 17.2568 3.31582 17.2233 3.34905L15.7076 4.86468C15.6744 4.89824 15.6558 4.94356 15.6558 4.99079C15.6558 5.03802 15.6744 5.08335 15.7076 5.11691L16.5916 6.00084C16.6608 6.07003 16.7746 6.07003 16.8438 6.00084L18.3594 4.48521C18.4286 4.41378 18.4286 4.30218 18.3594 4.23298ZM17.1429 18.9807H2.85719C2.4621 18.9807 2.1429 19.2999 2.1429 19.695V20.2307C2.1429 20.329 2.22326 20.4093 2.32148 20.4093H17.6786C17.7768 20.4093 17.8572 20.329 17.8572 20.2307V19.695C17.8572 19.2999 17.538 18.9807 17.1429 18.9807ZM9.37505 3.08789H10.625C10.7233 3.08789 10.8036 3.00753 10.8036 2.90932V0.766462C10.8036 0.668248 10.7233 0.587891 10.625 0.587891H9.37505C9.27683 0.587891 9.19648 0.668248 9.19648 0.766462V2.90932C9.19648 3.00753 9.27683 3.08789 9.37505 3.08789Z"
				fill="url(#paint0_linear_3049_22128)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_3049_22128"
				x1="-1.53009"
				y1="0.304727"
				x2="24.1122"
				y2="21.7464"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
			<clipPath id="clip0_3049_22128">
				<rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);

export const AlertGradientIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={Svg} {...props} />
);
