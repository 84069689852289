import { Tabs as AntTabs } from 'antd';

import classNames from 'classnames';

import type { TabsProps as AntTabsProps } from 'antd';

import styles from './tabs.module.scss';

export type TabsProps = AntTabsProps & {
	scrolling?: boolean;
	resetMargin?: boolean;
};

export const Tabs = function Tabs({
	className,
	scrolling,
	rootClassName,
	resetMargin,
	...props
}: TabsProps) {
	return (
		<AntTabs
			className={classNames(className, {
				[styles.scrolling]: scrolling,
				[styles.resetMargin]: resetMargin,
			})}
			rootClassName={classNames(styles.tabs, rootClassName)}
			{...props}
		/>
	);
};

Tabs.displayName = 'shared/ui/(Tabs)';
