import { CoffeeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import throttle from 'lodash/throttle';
import { computed, IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';

import { AppConfig, useAppConfig } from '@/shared/config/AppConfig';

import { useStore } from '@/shared/lib/useStore';

import { AuthModel } from '../../model/AuthModel';
import { useAuthModel } from '../../model/AuthModelContext';

import styles from './session-modal.module.scss';

export type EndSessionModalProps = {};

class Model {
	private disposer?: IReactionDisposer;
	constructor(
		private appConfig: AppConfig,
		private authModel: AuthModel,
		public readonly maxInActiveTime = 1000 * 60 * 5,
		public readonly lastSeconds = 30,
	) {
		makeAutoObservable(
			this,
			{
				seconds: computed,
			},
			{ autoBind: true },
		);
	}
	lastActiveDate = new Date();

	get diff() {
		return this.appConfig.reactiveTimer.time.getTime() - this.lastActiveDate.getTime();
	}

	get seconds() {
		let seconds = (this.maxInActiveTime - this.diff) / 1000;
		if (seconds < 0) seconds = 0;
		return seconds;
	}

	get isActive() {
		return this.seconds > 0;
	}

	get iShow() {
		return this.seconds <= this.lastSeconds;
	}

	track = throttle(() => {
		runInAction(() => {
			this.lastActiveDate = new Date();
		});
	}, 1000);

	startTracking() {
		this.lastActiveDate = new Date();
		document.addEventListener('mousemove', this.track);
		document.addEventListener('keydown', this.track);
		this.disposer = reaction(
			() => this.isActive,
			(isActive) => {
				if (!isActive) {
					this.authModel.logout();
				}
			},
		);
	}

	stopTracking() {
		document.removeEventListener('mousemove', this.track);
		document.removeEventListener('keydown', this.track);
		this.disposer?.();
	}

	dispose() {
		this.stopTracking();
	}
}

export const EndSessionModal = observer<EndSessionModalProps>(function EndSessionModal({
	...props
}) {
	const appConfig = useAppConfig();
	const authModel = useAuthModel();

	const model = useStore(() => new Model(appConfig, authModel), [appConfig, authModel]);

	useEffect(() => {
		model.startTracking();
		return () => {
			model.stopTracking();
		};
	}, [model]);

	const lastSeconds = useRef(model.seconds);
	if (model.iShow) {
		lastSeconds.current = model.seconds;
	}

	return (
		<Modal
			open={model.iShow}
			closable
			maskClosable={false}
			centered
			width={440}
			modalRender={() => (
				<Observer>
					{() => (
						<div className={styles.card} onClick={(e) => e.stopPropagation()}>
							<div className={styles.header}>
								<CoffeeOutlined className={styles.icon} />
								<h3 className={styles.title}>Завершение сессии</h3>
								<div className={styles.desc}>
									По истечении таймера текущая сессия будет завершена
								</div>
							</div>
							<div className={styles.divider}></div>
							<div className={styles.footer}>
								<div className={styles.timer}>{lastSeconds.current.toFixed()}</div>
							</div>
						</div>
					)}
				</Observer>
			)}
		/>
	);
});
