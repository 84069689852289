import { loadState, saveState } from '@/shared/lib/localStorage';

export const localStorageKeys = {
	/** Core */
	tags: 'demo/tags',
	mitre: 'demo/mitre',
	users: 'demo/users',

	/** Integrations */
	integrations: 'demo/integrations/v2',

	/** Workflows */
	workflows: 'demo/workflows/v2',
	launches: 'demo/launches',

	/** Events */
	events: 'demo/events/v2',
	eventsExtras: 'demo/eventsExtras',

	/** Incidents */
	incidents: 'demo/incidents',

	/** Messenger */
	aiChats: 'demo/chats/ai',
	chats: 'demo/chats/all',

	/** Audit */
	auditReports: 'demo/audit/reports',
	auditActiveWorkflows: 'demo/audit/activeWorkflows',
	auditWorkflowChanges: 'demo/audit/workflowChanges',
	auditCorrelationRules: 'demo/audit/correlationRules',
	auditWorkflowExecutions: 'demo/audit/workflowExecutions',
	auditActiveIntegrationInstances: 'demo/audit/activeIntegrationInstances',
	auditIntegrationCalls: 'demo/audit/integrationCalls',
	auditIntegrationEvents: 'demo/audit/integrationEvents',

	/** Executors */
	executors: 'demo/executors',

	/** Correlators */
	correlators: 'demo/correlators',

	/** Deprecated */
	deprecatedEvents: 'demo/events',
	deprecatedWorkflows: 'demo/workflows',
	deprecatedIntegrations: 'demo/integrations',
} as const;

export function withLocalStorage<TResult>(key: string, getData: () => TResult): TResult {
	const initialData = loadState(key);
	if (initialData) return initialData as TResult;

	const data = getData();

	saveState(key, data);
	return data;
}
