export const loadState = <T = unknown>(key: string): T | undefined => {
	try {
		const serializedState = localStorage.getItem(key);
		if (serializedState === null) {
			return undefined;
		}
		const result = JSON.parse(serializedState) as T;
		return result;
	} catch (err) {
		return undefined;
	}
};
