import { makeAutoObservable } from 'mobx';

import { Entity } from '@/shared/lib/collections/Entity';

export type ChatUserDto = {
	/** ID пользователя */
	id: string;
	/** Username пользователя */
	username: string;
	/** Фамилия */
	surname: string;
	/** Имя */
	name: string;
	/** Отчество */
	patronymic: string;
	/** Ссылка на аватар пользователя */
	avatar: string;
	/** Является ли этот пользователь ботом */
	isBot: boolean;
	/** Является ли этот пользователь инцидентом */
	isIncident: boolean;
};

export class ChatUserEntity implements Entity<ChatUserDto> {
	constructor(public id: string) {
		makeAutoObservable(this);
	}
	username: string = '';
	surname: string = '';
	name: string = '';
	patronymic: string = '';
	avatar: string = '';
	isBot: boolean = false;
	isIncident: boolean = false;

	get fio() {
		return [this.surname, this.name, this.patronymic].filter((v) => !!v).join(' ');
	}

	updateFromJson(updateDto: ChatUserDto): void {
		this.partialUpdateFromJson(updateDto);
	}

	partialUpdateFromJson(updateDto: Partial<ChatUserDto>): void {
		Object.assign(this, updateDto);
	}
}
