import { AppConfig } from '@/shared/config/AppConfig';

import { useStore } from '@/shared/lib/useStore';

import { LoginPageModel } from '../model/LoginPageModel';

import { LoginPage } from './LoginPage/LoginPage';

export function LoginPageWithModel({ appConfig }: { appConfig: AppConfig }) {
	const model = useStore(() => new LoginPageModel(appConfig), [appConfig]);
	return <LoginPage model={model} />;
}
