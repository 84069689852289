import { Flex, Spin } from 'antd';

import type { AppConfig } from '@/shared/config/AppConfig';
import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { EventsPage } = await import('./ui/EventsPage');
	return {
		default: EventsPage,
	};
};

export const eventsPageRoute = (appConfig: AppConfig, layoutModel: LayoutModel): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const { EventsPageModel } = await import('./model/EventsPageModel');
			const SingletonModel = singleton(EventsPageModel);
			const model = new SingletonModel(appConfig);
			return {
				model,
			};
		},
		{
			path: '/events',
			fallback: (
				<Flex flex="1 100%" align="center" justify="center">
					<Spin size="large" />
				</Flex>
			),
		},
	);
