export class AuthError extends Error {}

export class NotAuthorizedError extends AuthError {
	constructor() {
		super('Client is not authorized');
	}
}

export class AccessDeniedError extends Error {
	constructor() {
		super('Access denied');
	}
}
