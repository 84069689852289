import { observer } from 'mobx-react-lite';

import { ChatModel } from '../../model/ChatModel';
import { MessengerModel } from '../../model/MessengerModel';
import { ChatListItem } from '../ChatListItem';

type ChatsListProps = {
	chats: ChatModel[];
	model: MessengerModel;
	isImportantList?: boolean;
};

export const ChatsList = observer<ChatsListProps>(function ChatsList({
	chats,
	model,
	isImportantList,
}) {
	return (
		<>
			{chats.map((item) => {
				return (
					<ChatListItem
						key={item.chat.id}
						item={item}
						model={model}
						isImportantList={isImportantList}
					/>
				);
			})}
		</>
	);
});
