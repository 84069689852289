import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';

export type LitItemDto = {
	id: string;
};

export type ListItemEntity<
	EntityDto extends {} = any,
	UpdateDto extends any = Partial<EntityDto>,
> = {
	[K in keyof EntityDto]: EntityDto[K];
} & {
	id: string;
	updateFromJson(dto: UpdateDto): void;
};

export type ListOptions<
	TItem extends ListItemEntity<any> = ListItemEntity<any>,
	TItemDto extends LitItemDto = LitItemDto,
> = {
	createItem: (dto: TItemDto) => TItem;
	onRemoveItem?: (item: TItem) => void;
};

export class List<
	TItem extends ListItemEntity<any> = ListItemEntity<any>,
	TItemDto extends LitItemDto = LitItemDto,
> {
	private registry: Map<string, TItem> = new Map();
	/**
	 * Массив элементов в том порядке, в котором был получен
	 */
	public data: IObservableArray<TItem> = observable.array(undefined, { deep: false });
	private createItem: (dto: TItemDto) => TItem;
	private onRemoveItem: ((item: TItem) => void) | undefined = undefined;
	constructor({ createItem, onRemoveItem }: ListOptions<TItem, TItemDto>) {
		this.createItem = createItem;
		this.onRemoveItem = onRemoveItem;
		makeObservable<typeof this, 'clear'>(this, {
			isEmpty: computed,
			clear: action.bound,
			replace: action,
			push: action,
			unshift: action,
			insert: action,
			removeItem: action,
		});
	}

	public get isEmpty() {
		return this.data.length === 0;
	}

	/**
	 * Получить элемент списка по ID
	 * @param id - идентификатор элемента
	 * @returns элемент списка
	 */
	public getItem(id: string): TItem | undefined {
		return this.registry.get(id);
	}

	/**
	 * Удалить элемент из списка по ID
	 * @param id - идентификатор элемента
	 * @returns элемент списка
	 */
	public removeItem(id: string): void {
		this.registry.delete(id);
		const index = this.data.findIndex((v) => v.id === id);
		if (index !== -1) {
			const item = this.data.splice(index, 1)[0];
			this.onRemoveItem && this.onRemoveItem(item);
		}
	}

	/**
	 * Очистить все добавленные элементы из списка
	 */
	public clear() {
		const onRemoveItem = this.onRemoveItem;
		if (onRemoveItem) {
			this.data.forEach((v) => onRemoveItem(v));
		}
		this.data.clear();
		this.registry.clear();
	}

	/**
	 * Заменить все элементы из списка новыми
	 */
	public replace(data: TItemDto[]) {
		const isExistDataByID: Record<string, boolean> = {};
		this.data.clear();
		data.forEach((v) => {
			const id = v.id;
			isExistDataByID[id] = true;
			const item = this.addItem(v);
			this.data.push(item);
		});
		this.registry.forEach((item, id, registry) => {
			if (!isExistDataByID[id]) {
				this.onRemoveItem && this.onRemoveItem(item);
				registry.delete(id);
			}
		});
	}
	public push(dto: TItemDto) {
		const item = this.addItem(dto);
		this.data.push(item);
	}

	public unshift(dto: TItemDto) {
		const item = this.addItem(dto);
		this.data.unshift(item);
	}

	public insert(dto: TItemDto, index: number) {
		const item = this.addItem(dto);
		this.data.splice(index, 0, item);
	}

	private addItem = (dto: TItemDto) => {
		let item = this.registry.get(dto.id);
		if (!item) {
			item = this.createItem(dto);
			this.registry.set(item.id, item);
		}
		item.updateFromJson(dto);
		return item;
	};
}
