import { action, computed, makeObservable } from 'mobx';

import { AbstractFormGroup } from './AbstractFormGroup';
import { ControlsCollection, ControlsValueType, FlatControlsType } from './controls-collection';
import { FormControl } from './FormControl';

export type FormGroupOptions = {};

export class FormGroup<
	TControlsCollection extends ControlsCollection = ControlsCollection,
	TControlsValues extends
		ControlsValueType<TControlsCollection> = ControlsValueType<TControlsCollection>,
	TFlatControls extends FormControl<any, any> = FlatControlsType<
		TControlsCollection[keyof TControlsCollection]
	>,
> extends AbstractFormGroup<
	TControlsCollection[keyof TControlsCollection],
	TFlatControls,
	TControlsValues
> {
	constructor(
		/**
		 * Сontrols
		 * / Контролы
		 */
		public controls: TControlsCollection,
	) {
		super();
		this.controls = controls;

		makeObservable<typeof this>(this, {
			value: computed.struct,
			changedValue: computed.struct,
			setValue: action,
		});
	}

	/**
	 * An object with the values of all FormControls, in the form in which the FormGroup was initialized
	 * / Объект со значениями всех FormControl-ов, в том виде, в которым был проинициализирован FormGroup
	 */
	get value(): TControlsValues {
		const result: Record<string, any> = {};
		for (const key in this.controls) {
			const control = this.controls[key];
			if (control) {
				result[key] = control.value;
			}
		}
		return result as TControlsValues;
	}

	/**
	 * An object with the values of the modified FormControls, in the form in which the FormGroup was initialized
	 * / Объект со значений измененных FormControl-ов, в том виде, в которым был проинициализирован FormGroup
	 */
	get changedValue(): Partial<TControlsValues> {
		const result: Record<string, any> = {};
		for (const key in this.controls) {
			const control = this.controls[key];
			if (control && control.isChanged) {
				result[key] = control.value;
			}
		}
		return result as Partial<TControlsValues>;
	}

	/**
	 * Returns a complete list of FormControls without attachments (terminal elements)
	 * Возвращает полный список FormControl-ов без вложений (терминальных элементов)
	 */
	allControls(): TFlatControls[] {
		let controls: TFlatControls[] = [];
		for (const control of this.getControls()) {
			if ('allControls' in control) {
				controls = controls.concat(control.allControls());
			} else {
				controls.push(control as unknown as TFlatControls);
			}
		}
		return controls;
	}

	setValue(formGroupValue: Partial<TControlsValues>) {
		for (const key in formGroupValue) {
			const control = this.controls[key];
			if (control) {
				const value: typeof control.value | undefined = formGroupValue[key];
				if (value !== undefined) {
					if ('comparer' in control) {
						if (!control.comparer(control.value, value)) {
							control.setValue(value);
						}
					} else {
						control.setValue(value);
					}
				}
			}
		}
		return this;
	}

	protected *getControls(): IterableIterator<TControlsCollection[keyof TControlsCollection]> {
		for (const keyName in this.controls) {
			yield this.controls[keyName];
		}
	}
}
