import { action, makeAutoObservable, observable } from 'mobx';

import { NavigateOptions } from 'react-router';

import { LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';

import type { MenuItemType } from 'antd/es/menu/interface';
import type { MenuInfo } from 'rc-menu/lib/interface';

export type LayoutNavbarType = 'collapsed' | 'default';
export type LayoutNavItemType = Exclude<MenuItemType, null> & {
	key: string;
	to?: string;
	navigateOptions?: NavigateOptions;
	route?: LazyRouteObject;
	children?: LayoutNavItemType[];
	navbarType?: LayoutNavbarType;
};

export class LayoutNavbarModel {
	public activeKey: string = '';
	public activeKeyPath: string[] = [];

	public navItems = observable.array<LayoutNavItemType>();

	constructor(
		navItems: LayoutNavItemType[],
		public isCollapsed: boolean,
	) {
		makeAutoObservable(this, { setActiveItem: action }, { autoBind: true });
		this.setNavigationItems(navItems);
	}

	public get routeItemsMap() {
		const map = new Map<
			LayoutNavItemType['key'],
			{ item: LayoutNavItemType; parent?: LayoutNavItemType }
		>();

		const setItems = (items: LayoutNavItemType[], parent?: LayoutNavItemType) => {
			items.forEach((item) => {
				map.set(item.key, {
					item,
					parent,
				});
				if (item.children) {
					setItems(item.children, item);
				}
			});
		};
		setItems(this.navItems);
		return map;
	}

	public get routeItems() {
		return Array.from(this.routeItemsMap.values());
	}

	public get activeNavItem() {
		return this.routeItemsMap.get(this.activeKey)?.item;
	}

	public get navbarType(): LayoutNavbarType {
		return this.activeNavItem?.navbarType || 'default';
	}

	public get selectedKeys() {
		return [this.activeKey];
	}

	public setIsCollapsed(isCollapsed: boolean) {
		this.isCollapsed = isCollapsed;
	}

	public setNavigationItems(items: LayoutNavItemType[]) {
		this.navItems.replace(
			items.map((item) => {
				return {
					...item,
					onMouseEnter: () => {
						item.route?.preloadPage();
					},
				};
			}),
		);
	}

	public handleMenuSelect({ key, keyPath }: MenuInfo) {
		this.activeKey = key;
		this.activeKeyPath = keyPath;
	}

	public setActiveItem(item?: LayoutNavItemType) {
		if (item) {
			this.activeKey = item.key;
			const path: string[] = [item.key];

			let parent = this.routeItemsMap.get(item.key)?.parent;

			while (parent) {
				path.push(parent.key);
				parent = this.routeItemsMap.get(parent.key)?.parent;
			}

			this.activeKeyPath = path.reverse();
		} else {
			this.activeKey = '';
			this.activeKeyPath = [];
		}
	}
}
