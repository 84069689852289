import {
	BellOutlined,
	QuestionCircleOutlined,
	SearchOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Badge, Dropdown } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useAuthModel } from '@/features/auth';

import { AIIcon } from '@/shared/ui/icons/AIIcon';
import { AIIconActive } from '@/shared/ui/icons/AIIconActive';
import { ChatIcon } from '@/shared/ui/icons/ChatIcon';

import { useMessengerModel } from '@/widgets/messenger';

import styles from './toolbar.module.scss';

export type ToolbarProps = {
	dark?: boolean;
};

export const Toolbar = observer<ToolbarProps>(function Toolbar({ dark }) {
	const authModel = useAuthModel();
	const chatModel = useMessengerModel();

	return (
		<div className={classNames(styles.wrapper, { [styles.dark]: dark })}>
			{
				/* <button className={styles.button}>
				<SearchOutlined />
			</button>
			<button className={styles.button}>
				<QuestionCircleOutlined />
			</button>*/
				<button
					className={classNames(styles.button, {
						[styles.selected]: chatModel.chatIsOpen.isEnabled,
					})}
					onClick={() => {
						chatModel.chatIsOpen.toggle();
						chatModel.AIChatIsOpen.disable();
						chatModel.chatIsCollapsed.enable();
					}}
				>
					<ChatIcon />
				</button>
				/*<button className={styles.button}>
				<Badge size="small" color="red" count={5}>
					<BellOutlined />
				</Badge>
			</button> */
			}
			<Dropdown
				className={styles.user}
				trigger={['click']}
				menu={{
					items: [
						{
							key: 'logout',
							label: 'Выйти',
							onClick: authModel.logout,
						},
					],
				}}
				placement="bottomRight"
			>
				<button className={styles.button}>
					<span className={styles.name}>Иван Иванов</span>
					<UserOutlined className={styles.user_icon} />
				</button>
			</Dropdown>
			<button
				className={classNames(styles.button, styles.ai_button)}
				onClick={() => {
					chatModel.AIChatIsOpen.toggle();
					chatModel.chatIsOpen.disable();
				}}
			>
				{chatModel.AIChatIsOpen.isEnabled ? <AIIconActive /> : <AIIcon />}
			</button>
		</div>
	);
});
