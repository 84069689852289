import { Repository } from '@/shared/lib/collections/Repository';

import { CompanyEntity } from '../CompanyEntity';

export class CompanyRepository extends Repository<CompanyEntity> {
	constructor() {
		super((id: string) => {
			return new CompanyEntity(id);
		});
	}
}
