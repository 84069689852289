import { Tag, TagProps } from 'antd';
import classNames from 'classnames';
import { isEqual, subDays } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { useFormatDate } from '@/shared/lib/date/useFormatDate';

import styles from './day-tag.module.scss';

export type Props = TagProps & {
	date: Date | undefined;
};

export const DayTag = observer<Props>(function DayTag({ date, className, ...props }) {
	const today = new Date();
	const yesterday = subDays(today, 1);

	const isToday = date ? isEqual(date, today) : false;
	const isYesterday = date ? isEqual(date, yesterday) : false;

	const formatDate = useFormatDate();

	return (
		<Tag className={classNames(styles.tag, className)} {...props}>
			{isToday
				? 'Сегодня'
				: isYesterday
					? 'Вчера'
					: date
						? formatDate(date, 'dd.MM.yyyy')
						: ''}
		</Tag>
	);
});
