import { Card as AntCard, ConfigProvider } from 'antd';
import classNames from 'classnames';
import { forwardRef } from 'react';

import {
	CardBgVariant,
	CardRoundedVariant,
	CardTitlePosition,
	CardTitleVariant,
	PaddingResetProps,
	useCardTheme,
} from './theme';

import type { CardProps as AntCardProps } from 'antd';

import styles from './card.module.scss';

export type CardProps = AntCardProps & {
	rounded?: CardRoundedVariant;
	bg?: CardBgVariant;
	hasHeaderDivider?: boolean;
	/** Цвет заголовка */
	titleVariant?: CardTitleVariant;
	/** Позиция заголовка */
	titlePosition?: CardTitlePosition;
	/** Сброс паддингов */
	paddingReset?: PaddingResetProps;
	/** Скролится ли контент внутри карточки */
	scrollingBody?: boolean;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
	{
		className,
		rounded = 'none',
		bg = 'default',
		bordered = false,
		hasHeaderDivider = true,
		paddingReset,
		scrollingBody,
		titlePosition = 'left',
		titleVariant = 'default',
		classNames: { title, header, body, ...otherClassnames } = {},
		size = 'default',
		...props
	},
	ref,
) {
	const { theme, prefix } = useCardTheme(
		bg,
		rounded,
		titleVariant,
		titlePosition,
		hasHeaderDivider,
		size,
		paddingReset,
	);

	return (
		<ConfigProvider theme={theme}>
			<AntCard
				prefixCls={prefix}
				ref={ref}
				className={classNames(className, styles.wrapper, {
					[styles['scrolling-body']]: scrollingBody,
				})}
				classNames={{
					title: classNames(styles.title, title),
					header: classNames(styles.header, header),
					body: classNames(styles.body, body),
					...otherClassnames,
				}}
				bordered={bordered}
				size={size}
				{...props}
			/>
		</ConfigProvider>
	);
});

Card.displayName = 'ForwardRef shared/ui/card/(Card)';
