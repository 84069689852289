import { createContext, useContext } from 'react';

import { Toggler } from '@/shared/lib/collections/Toggler';

export const DemoContext = createContext<{
	modal: Toggler;
}>(null!);

export function useDemo() {
	return useContext(DemoContext);
}
