import type { AppConfig } from '@/shared/config/AppConfig';
import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { IncidentsPage } = await import('./ui/IncidentsPage');
	return {
		default: IncidentsPage,
	};
};

export const incidentsPageRoute = (
	appConfig: AppConfig,
	layoutModel: LayoutModel,
): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');
			const { IncidentsPageModel } = await import('./model/IncidentsPageModel');
			const SingletonModel = singleton(IncidentsPageModel);
			const model = new SingletonModel(appConfig);
			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.incidents.path,
		},
	);
