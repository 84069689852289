import { RouteObject } from 'react-router';

import type { AppConfig } from '@/shared/config/AppConfig';
import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute } from '@/shared/lib/router/createDynamicRoute';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { IntegrationPage } = await import('./ui/IntegrationPage');
	return {
		default: IntegrationPage,
	};
};

export const integrationPageRoute = (appConfig: AppConfig, layoutModel: LayoutModel): RouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const integrationID = params.params.integrationID || '';
			const { IntegrationPageModel } = await import('./model/IntegrationPageModel');
			const model = new IntegrationPageModel(integrationID, appConfig);
			await model.integrationRequest.request();

			const tab = new URL(params.request.url).searchParams.get('tab');

			if (tab && IntegrationPageModel.allowTabKeys.includes(tab)) {
				model.navbar.setActiveKey(tab);
			}

			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.integration.path,
		},
	);
