import { createContext, useContext } from 'react';

import { LayoutModel } from './LayoutModel';

export const LayoutModelContext = createContext<LayoutModel>(null!);

export function useLayoutModel() {
	const model = useContext(LayoutModelContext);

	if (!(model instanceof LayoutModel)) {
		throw new Error(
			'Отсутствует LayoutModelContext, необходимо передать значение в LayoutModelContext.Provider',
		);
	}
	return model;
}
