import { ThemeConfig as AntThemeConfig, GlobalToken, theme as antTheme } from 'antd';
import { useMemo } from 'react';

import { useAntCssKey } from '@/shared/config/AntCssKeyContext';
import { AppThemeMode } from '@/shared/config/AppConfig';

export type ThemeExtendToken = {
	/** Необходимо добвить глобальный токен layoutBodyBg,
	 * чтобы был доступ к переменной за пределами Layout,
	 * Необходимо для модалок, так как они рендерятся через портал в body
	 * */
	layoutBodyBg: string;
};

export type ThemeToken = AntThemeConfig['token'] & ThemeExtendToken;

export type ThemeConfig<
	Tokens extends {} = ThemeToken,
	TComponents extends AntThemeConfig['components'] = AntThemeConfig['components'],
> = Omit<AntThemeConfig, 'cssVar' | 'token'> & {
	token?: Tokens;
	components?: TComponents;
	cssVar: {
		key: string;
		prefix?: string;
	};
};

export type ThemeContextValue = Omit<ReturnType<typeof antTheme.useToken>, 'token'> & {
	token: GlobalToken & ThemeExtendToken;
};

export function useExtendedTheme<
	Tokens extends {} = ThemeToken,
	TComponents extends AntThemeConfig['components'] = AntThemeConfig['components'],
>(
	extendTheme: (
		themeValue: ThemeContextValue,
		themeMode: AppThemeMode,
	) => Partial<ThemeConfig<Tokens, TComponents>>,
	deps: any[],
): ThemeConfig<Tokens, TComponents> {
	const { hashId, theme, token } = antTheme.useToken() as ThemeContextValue;

	const themeMode = useAntCssKey();

	return useMemo(() => {
		const extendedTheme = extendTheme({ hashId, theme, token }, themeMode);
		return {
			...extendedTheme,
			cssVar: {
				key: themeMode,
				...extendedTheme.cssVar,
			},
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extendTheme, hashId, theme, token, themeMode, ...deps]);
}
