import { action, makeObservable } from 'mobx';

import { FormGroup } from '@/shared/lib/form/FormGroup';
import { requiredValidator } from '@/shared/lib/form/validators';
import { InputTextControl } from '@/shared/ui/form/Input';

export type LoginFormModelOptions = {
	onSubmit: (formGroup: LoginFormModel['formGroup']) => void;
};

export class LoginFormModel {
	constructor(private options: LoginFormModelOptions) {
		makeObservable(this, {
			submit: action.bound,
		});
	}

	username = new InputTextControl('', {
		validators: [requiredValidator('Имя пользователя обязательно')],
	});

	password = new InputTextControl('', {
		validators: [requiredValidator('Пароль обязателен')],
	});

	formGroup = new FormGroup({
		username: this.username,
		password: this.password,
	});

	submit() {
		if (this.formGroup.isInvalid) {
			this.formGroup.allControls().forEach((control) => {
				if (control.isInvalid && control.element) {
					control.element.focus();
				}
			});
		} else {
			this.options.onSubmit(this.formGroup);
		}
	}
}
