import React, { useEffect, useRef } from 'react';

export function useStore<S extends object & { dispose?: () => void }>(
	getInstance: () => S,
	deps: any[] = [],
): S {
	const [instance, setInstanse] = React.useState(getInstance);

	const isMount = useRef(false);

	useEffect(() => {
		if (isMount.current) {
			setInstanse(getInstance());
		} else {
			isMount.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps]);

	useEffect(() => {
		return () => {
			if (typeof instance.dispose === 'function') {
				instance.dispose();
			}
		};
	}, [instance]);

	return instance;
}
