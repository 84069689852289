import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';

import { Toggler } from '@/shared/lib/collections/Toggler';

import { Demo } from './Demo';

export type DemoModalProps = {
	toggler: Toggler;
};

export const DemoModal = observer<DemoModalProps>(function DemoModal({ toggler }) {
	return (
		<Modal
			open={toggler.isEnabled}
			onCancel={toggler.disable}
			onClose={toggler.disable}
			closable
			maskClosable={false}
			centered
			width={460}
			modalRender={() => <Demo closable onClose={toggler.disable} />}
		/>
	);
});
