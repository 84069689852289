/**
 * Ищет элемент спо ключу в коллекции и возвращает его, если элемента с заданным ключом не
 * @param map - Коллекция map над которой производятся вычисления
 * @param key - Ключ для поиска/вставки значения
 * @param create - функция создания элемента
 * @returns элемент коллекции
 */
export const getWithSet = <TItem, K>(map: Map<K, TItem>, key: K, create: (key: K) => TItem) => {
	let item: TItem;
	if (map.has(key)) {
		item = map.get(key) as TItem;
	} else {
		item = create(key);
		map.set(key, item);
	}
	return item;
};
