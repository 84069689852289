import { ReactNode, memo } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { useUpdateEffect } from 'react-use';

import { AccessDeniedError, NotAuthorizedError } from '@/features/auth';

import { AccessDeniedResult } from '../AccessDeniedResult';
import { NotAuthorisedResult } from '../NotAuthorisedResult';

export type LayoutProps = {
	children?: ReactNode;
};

export const FallbackComponent = memo<FallbackProps>(function FallbackComponent({
	error,
	resetErrorBoundary,
}) {
	const location = useLocation();

	useUpdateEffect(() => {
		resetErrorBoundary();
	}, [location]);

	if (error instanceof NotAuthorizedError) {
		return <NotAuthorisedResult resetErrorBoundary={resetErrorBoundary} />;
	}

	if (error instanceof AccessDeniedError) {
		return <AccessDeniedResult resetErrorBoundary={resetErrorBoundary} />;
	}
	throw error;
});
