import { makeAutoObservable } from 'mobx';

import { Entity } from '@/shared/lib/collections/Entity';

export type ChatDto = {
	id: string;
	/**
	 * Тип чата
	 * private - личный чат с другим пользователем
	 * channel - общий чат, куда может писать любой пользователь, например чат инцидента
	 * group - чат доступный определенной группе пользователей, пока не используется, но может быть реализован позденее
	 */
	type: 'private' | 'channel' | 'group';
	/** Название чата */
	title: string;
	/** Отмечен ли чат, как "важные" */
	isFavorite: boolean;
	/** Был ли этот чат создан инцидентом */
	isIncident: boolean;
};

export class ChatEntity implements Entity<ChatDto> {
	constructor(public id: string) {
		makeAutoObservable(this);
	}
	type: 'private' | 'channel' | 'group' = 'private';
	title: string = '';
	isIncident: boolean = false;
	isFavorite: boolean = false;

	updateFromJson(updateDto: ChatDto): void {
		this.partialUpdateFromJson(updateDto);
	}

	partialUpdateFromJson(updateDto: Partial<ChatDto>): void {
		Object.assign(this, updateDto);
	}
}
