import { observer } from 'mobx-react-lite';
import { ElementType } from 'react';

import styles from './recommendation-card.module.scss';

type RecommendationCardProps = {
	Icon: ElementType;
	title: string;
	desc: string;
	onClick?: () => void;
};

export const RecommendationCard = observer<RecommendationCardProps>(function RecommendationCard({
	Icon,
	title,
	desc,
	onClick,
}) {
	return (
		<div className={styles.gradient} onClick={onClick}>
			<div className={styles.container}>
				<Icon className={styles.icon} />
				<div>
					<span className={styles.title}>{title}</span>
					<div className={styles.desc}>{desc}</div>
				</div>
			</div>
		</div>
	);
});
