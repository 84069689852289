import {
	CheckCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	EllipsisOutlined,
	PushpinOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import { MenuProps } from 'antd/lib';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useMemo } from 'react';

import { useAuthModel } from '@/features/auth';
import { useFormatDate } from '@/shared/lib/date/useFormatDate';
import { Button } from '@/shared/ui/Button';

import { ChatMessageModel } from '../../model/ChatMessageModel';
import { ChatModel } from '../../model/ChatModel';

import styles from './message-item.module.scss';

type MessageItemProps = {
	message: ChatMessageModel;
	chat: ChatModel;
};

export const MessageItem = observer<MessageItemProps>(function MessageItem({ message, chat }) {
	const formatDate = useFormatDate();

	const name = message.title;

	const auth = useAuthModel();

	const isSelf = computed(() => auth.user.id === message.user?.id).get();

	const actions = useMemo<MenuProps['items']>(
		() =>
			[
				!isSelf && {
					label: 'Ответить',
					key: 'answer',
					icon: <PushpinOutlined className={styles.icon} />,
				},
				{
					label: 'Закрепить',
					key: 'pin',
					icon: <PushpinOutlined className={styles.icon} />,
				},
				chat.isIncident && {
					label: 'Создать задачу',
					key: 'create',
					icon: <CheckCircleOutlined className={styles.icon} />,
				},
				{
					label: 'Редактировать',
					key: 'edit',
					icon: <EditOutlined className={styles.icon} />,
				},
				isSelf && {
					label: 'Удалить',
					key: 'delete',
					icon: <DeleteOutlined className={styles.icon} />,
				},
			].filter(Boolean),
		[isSelf, chat.isIncident],
	);

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.name}>
					{isSelf ? (
						<>
							{name}&nbsp;<span className={styles.info}>вы</span>
						</>
					) : (
						name
					)}
				</div>
				<span className={styles.time}>{formatDate(message.date, 'HH:mm')}</span>
				{/* 
				TODO: DEMO
				<Dropdown
					trigger={['click']}
					menu={{
						items: actions,
						// onClick: (value) => {
						// 	if (conditionTypes.includes(value.key)) {
						// 		onClick(value.key as 'SIMPLE' | 'OR' | 'AND');
						// 	}
						// },
					}}
					overlayClassName={styles.dropdown}
				>
					<Button type="text" variant="secondary" className={styles.button}>
						<EllipsisOutlined className={styles.action_icon} />
					</Button>
				</Dropdown> */}
			</div>
			<div>{message.text}</div>
		</div>
	);
});
