import { formatDate, FormatOptions } from 'date-fns/format';
import { makeAutoObservable } from 'mobx';

import { AppConfig } from '@/shared/config/AppConfig';
export class DateFormatter {
	constructor(public appConfig: AppConfig) {
		makeAutoObservable(this, {});
	}

	format<DateType extends Date>(
		date: DateType | number | string,
		formatStr: string,
		options: FormatOptions = {},
	) {
		if (!options.locale) {
			options.locale = this.appConfig.locale.dateFnsLocale;
		}
		return formatDate(date, formatStr, options);
	}
}
