import { action, makeAutoObservable } from 'mobx';

import { createContext, useContext } from 'react';

import { AppConfig } from '@/shared/config/AppConfig';
import { Toggler } from '@/shared/lib/collections/Toggler';
import { ScrollBarHelper } from '@/shared/lib/dom/ScrollBarHelper';
import { MentionsControl } from '@/shared/ui/form/Mentions';

import { AiChatModel } from './AiChatModel';
import { ChatListModel } from './ChatListModel';
import { MessengerQueryModel } from './MessengerQueryModel';

export type ChatCategory = 'private' | 'favorite' | 'incidents';

export class MessengerModel {
	scrollBarHelper: ScrollBarHelper;
	constructor(public appConfig: AppConfig) {
		this.scrollBarHelper = new ScrollBarHelper();
		makeAutoObservable<typeof this, 'requestState'>(this, {
			toggleChatCollapse: action.bound,
			toggleChatIsOpen: action.bound,
			requestState: false,
		});
	}

	query = new MessengerQueryModel(this.appConfig, 'chats');

	aiQuery = new MessengerQueryModel(this.appConfig, 'ai');

	chatList = new ChatListModel(this.query);

	aiChat = new AiChatModel(this.aiQuery);

	selectedCategory: ChatCategory = 'private';

	chatIsOpen = new Toggler(false);

	AIChatIsOpen = new Toggler(false);

	chatIsCollapsed = new Toggler(true);

	listSearchInputToggle = new Toggler(false);

	messageSearchInputToggle = new Toggler(false);

	AIInput = new MentionsControl(() => '');

	chatInput = new MentionsControl(() => '');

	public toggleChatCollapse() {
		this.chatIsCollapsed.toggle();

		if (this.chatIsCollapsed.isEnabled) {
			this.scrollBarHelper.show();
		} else {
			this.scrollBarHelper.hide();
		}
	}

	public toggleChatIsOpen() {
		this.chatIsOpen.toggle();

		if (this.chatIsOpen.isEnabled) {
			this.scrollBarHelper.hide();
		} else {
			this.scrollBarHelper.show();
			this.chatIsCollapsed.enable();
		}
	}

	public setSelectedCategory(category: ChatCategory) {
		this.selectedCategory = category;
	}
}

export const MessengerModelContext = createContext<MessengerModel>(null!);

export function useMessengerModel() {
	return useContext(MessengerModelContext);
}
