import { action, makeObservable, observable } from 'mobx';

export class Toggler {
	public isEnabled: boolean;

	constructor(
		initialValue: boolean = false,
		public onToggle: (isEnabled: boolean) => void = () => {},
	) {
		this.isEnabled = initialValue;
		makeObservable(this, {
			isEnabled: observable,
			enable: action.bound,
			disable: action.bound,
			toggle: action.bound,
			setEnabled: action.bound,
		});
	}

	public enable(): void {
		this.isEnabled = true;
		this.onToggle(this.isEnabled);
	}

	public disable(): void {
		this.isEnabled = false;
		this.onToggle(this.isEnabled);
	}

	public setEnabled(isEnabled: boolean) {
		this.isEnabled = isEnabled;
		this.onToggle(this.isEnabled);
	}

	public toggle(): void {
		this.isEnabled = !this.isEnabled;
		this.onToggle(this.isEnabled);
	}
}
