import myzod, { Infer } from 'myzod';

export const loginResponseSchema = myzod.object({
	id: myzod.string(),
	/** Имя */
	name: myzod.string().default(''),
	/**Фамилия */
	surname: myzod.string().default(''),
	/** Username пользователя */
	username: myzod.string(),
	/** Email пользователя */
	email: myzod.string(),
	/** Отчество */
	patronymic: myzod.string(),
	/** Ссылка на аватар пользователя */
	avatar: myzod.string(),
	/** Модификаторы доступа */
	permissions: myzod.array(
		myzod.object({
			name: myzod.string(),
			section: myzod.string(),
			subSection: myzod.string(),
			hasAccess: myzod.boolean(),
		}),
	),
	/** Последний вход в систему в формате ISO */
	lastLoginDate: myzod.string(),
	/** Дата регистрации в формате ISO */
	registrationDate: myzod.string(),
	/** Дата последнего обновления в формате ISO  */
	lastUpdateDate: myzod.string(),
	/** Тип уч. записи */
	accountType: myzod.string(),
	/** Роли пользователя */
	roles: myzod.array(
		myzod.object({
			name: myzod.string(),
			attributes: myzod.number(),
		}),
	),
	/** Активна ли двухфакторная аутентификация */
	hasTwoFactorAuth: myzod.boolean(),
	/** Есть ли доступ к системе */
	hasSystemAccess: myzod.boolean(),
});
export type LoginResponse = Infer<typeof loginResponseSchema>;
