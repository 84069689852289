import { Flex, Spin } from 'antd';
import React, { Suspense } from 'react';
import { LoaderFunctionArgs, RouteObject, defer } from 'react-router';
import { Await, useLoaderData } from 'react-router';

export type DynamicRouteObject = RouteObject & {
	fallback?: React.ReactNode;
};

const defaultFallback = (
	<Flex flex="1 100%" align="center" justify="center">
		<Spin size="large" />
	</Flex>
);

type PageProps = {
	model: { [x: string]: any; dispose?: () => void };
};

type RouteLoaderData<Data> = {
	promise: Promise<Data>;
};

type ResolvedData<IProps> = {
	Component: React.ComponentType<IProps>;
	props: IProps;
};

function loadableComponent<IResolvedData extends ResolvedData<PageProps> = ResolvedData<PageProps>>(
	fallback?: React.ReactNode,
) {
	return function LoadableComponent() {
		const data = useLoaderData() as RouteLoaderData<IResolvedData>;

		return (
			<Suspense fallback={fallback}>
				<Await resolve={data.promise}>
					{({ Component, props }: IResolvedData) => {
						return <Component {...props} />;
					}}
				</Await>
			</Suspense>
		);
	};
}

export type LazyRouteObject = RouteObject & {
	preloadPage: () => Promise<void>;
};

export function createDynamicRoute<IProps extends PageProps = PageProps>(
	importComponent: () => Promise<{ default: React.ComponentType<IProps> }>,
	loaderFunction: (params: LoaderFunctionArgs<any>) => Promise<IProps>,
	{ fallback, ...routeObject }: DynamicRouteObject,
): LazyRouteObject {
	if (!fallback) fallback = defaultFallback;

	const Component = loadableComponent(fallback);

	const preloadPage = async () => {
		await importComponent();
	};

	const loader = (params: LoaderFunctionArgs<any>) => {
		const getPromise = async () => {
			// const start = performance.now();
			const [component, props] = await Promise.all([
				importComponent(),
				loaderFunction(params),
			]);

			// const diff = performance.now() - start;
			// if (diff > 150 && diff < 300) {
			// 	await new Promise<void>((r) => setTimeout(r, diff));
			// }
			// if (diff < 50) {
			// 	await new Promise<void>((r) => setTimeout(r, 0));
			// }
			return {
				Component: component.default,
				props,
			};
		};
		const promise = getPromise();
		return defer({
			promise,
		});
	};

	return {
		...routeObject,
		preloadPage,
		// async lazy() {
		// 	const { default: Component } = await importComponent();
		// 	const props = await loaderFunction({});
		// 	return {
		// 		element: <Component {...props} />,
		// 	};
		// },
		// Component,
		element: <Component />,
		loader,
	};
}
