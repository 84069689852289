/**
 * Модуль инициализации env-переменных
 * @remark Если не найдено значение хоть одной переменной,
 * Приложение сразу выбросит ошибку, при инициализации модуля
 * @module
 */

/**
 * Получение env-переменной
 * @throwable
 */
const getEnvVar = (getEnv: () => string, key: string) => {
	const env = getEnv();
	if (env === undefined) {
		throw new Error(`Env variable ${key} is required`);
	}
	return env;
};

export const NODE_ENV = getEnvVar(() => process.env.NODE_ENV, 'NODE_ENV');

/** Режим разработки */
export const IS_DEV_ENV = NODE_ENV === 'development';
/** Режим продакшена */
export const IS_PROD_ENV = NODE_ENV === 'production';

/** значение homepage из package.json */
export const PUBLIC_URL = getEnvVar(() => process.env.PUBLIC_URL, 'PUBLIC_URL');
