import { FormControl } from './FormControl';
import { ValidationEvent, ValidationEventTypes, ValidatorsFunction } from './validation';

export const requiredValidatorKey = 'required';
export function requiredValidator<TControl extends FormControl<unknown, any>>(
	message: string = 'Поле обязательно',
	eventType: ValidationEventTypes = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return function (control: TControl): ValidationEvent[] {
		if (control.value == null || (control.value as any as string) === '') {
			return [
				{
					message,
					key: requiredValidatorKey,
					type: eventType,
				},
			];
		}
		return [];
	};
}

export const notEmptyOrSpacesValidatorKey = 'notEmptyOrSpaces';
export function notEmptyOrSpacesValidator<
	TControl extends FormControl<any, string> = FormControl<any, string>,
>(
	message: string = 'Отсутствует значение',
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return (control: TControl): ValidationEvent[] => {
		if (control.value != null && control.value.trim() !== '') {
			return [];
		}
		return [
			{
				message,
				key: notEmptyOrSpacesValidatorKey,
				type: eventType,
			},
		];
	};
}

export const notContainSpacesValidatorKey = 'notContainSpaces';
/**
 * Not contain spaces
 * / Не содержит проблелов
 */
export function notContainSpacesValidator<
	TControl extends FormControl<any, string> = FormControl<any, string>,
>(message: string = 'Не должен содержать пробелы', eventType = ValidationEventTypes.Error) {
	return (control: TControl): ValidationEvent[] => {
		if (control.value === null || !/\s/.test(control.value)) {
			return [];
		}
		return [
			{
				message,
				key: notContainSpacesValidatorKey,
				type: eventType,
			},
		];
	};
}

export const patternValidatorKey = 'pattern';
/**
 * Error if there is no pattern matching
 * / Ошибка, если нет соответствия паттерну
 */
export function patternValidator<
	TControl extends FormControl<any, string> = FormControl<any, string>,
>(
	regExp: RegExp,
	message: string = 'Присутствуют недопустимые символы',
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return (control: TControl): ValidationEvent[] => {
		if (control.value != null && regExp.test(control.value)) {
			return [];
		}
		return [
			{
				message,
				key: patternValidatorKey,
				type: eventType,
			},
		];
	};
}

/**
 * Error if there is a pattern match
 * / Ошибка, если есть соответствие паттерну
 */
export function invertPatternValidator<TControl extends FormControl<any, string>>(
	regExp: RegExp,
	message: string = 'Присутствуют недопустимые символы',
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return (control: TControl): ValidationEvent[] => {
		if (control.value !== null && regExp.test(control.value)) {
			return [
				{
					message,
					key: patternValidatorKey,
					type: eventType,
				},
			];
		}
		return [];
	};
}

export const minLengthValidatorKey = 'minlength';
export function minLengthValidator<
	TEntity extends { length: number },
	TControl extends FormControl<any, TEntity>,
>(
	minlength: number,
	message: string = `Минимальная длина ${minlength}`,
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return function (control: TControl): ValidationEvent[] {
		if (control.value == null || minlength <= control.value.length) {
			return [];
		}
		return [
			{
				message,
				key: minLengthValidatorKey,
				type: eventType,
			},
		];
	};
}

export const maxLengthValidatorKey = 'maxlength';
export function maxLengthValidator<
	TEntity extends { length: number },
	TControl extends FormControl<any, TEntity>,
>(
	maxlength: number,
	message: string = `Максимальная длина ${maxlength}`,
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return (control: TControl): ValidationEvent[] => {
		if (control.value == null || control.value.length <= maxlength) {
			return [];
		}
		return [
			{
				message,
				key: maxLengthValidatorKey,
				type: eventType,
			},
		];
	};
}

export const absoluteLengthValidatorKey = 'absoluteLength';
export function absoluteLengthValidator<TControl extends FormControl<any, { length: number }>>(
	length: number,
	message: string = `Длина отлична от ${length}`,
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return (control: TControl): ValidationEvent[] => {
		if (control.value == null || control.value.length === length) {
			return [];
		}
		return [
			{
				message,
				key: absoluteLengthValidatorKey,
				type: eventType,
			},
		];
	};
}

export const minValueValidatorKey = 'minValue';
export function minValueValidator<
	TEntity extends string | null | number | Date,
	TControl extends FormControl<any, TEntity> = FormControl<any, TEntity>,
>(
	min: TEntity | (() => TEntity),
	message: string = 'Значение слишком маленькое',
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	const getMin: () => TEntity = typeof min === 'function' ? min : () => min;
	return (control: TControl): ValidationEvent[] => {
		if (control.value == null) {
			return [];
		}
		const minValue: any = getMin();
		let value: any = control.value;
		if (typeof value === 'string') {
			if (typeof minValue === 'number') {
				value = +value;
			} else if (minValue instanceof Date) {
				value = new Date(value);
			}
		}
		if (value < minValue) {
			return [
				{
					message,
					key: minValueValidatorKey,
					type: eventType,
				},
			];
		}
		return [];
	};
}

export const maxValueValidatorKey = 'minValue';
export function maxValueValidator<
	TEntity extends string | null | number | Date,
	TControl extends FormControl<any, TEntity> = FormControl<any, TEntity>,
>(
	max: TEntity | (() => TEntity),
	message: string = 'Значение слишком большое',
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	const getMax: () => TEntity = typeof max === 'function' ? max : () => max;
	return (control: TControl): ValidationEvent[] => {
		if (control.value == null) {
			return [];
		}
		const maxValue: any = getMax();
		let value: any = control.value;
		if (typeof value === 'string') {
			if (typeof maxValue === 'number') {
				value = +value;
			} else if (maxValue instanceof Date) {
				value = new Date(value);
			}
		}
		if (maxValue < value) {
			return [
				{
					message,
					key: maxValueValidatorKey,
					type: eventType,
				},
			];
		}
		return [];
	};
}

export const compairValidatorKey = 'compair';
/**
 * Wrapper for complex validation (error if validation returns false)
 * / Обёртка для сложной проверки (ошибка, если проверка вернула false)
 */
export function compareValidator<TEntity>(
	expression: (value: TEntity) => boolean,
	message: string = 'Поле не валидно',
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<FormControl<any, TEntity>> {
	return (control: FormControl<any, TEntity>): ValidationEvent[] => {
		if (expression(control.value)) {
			return [];
		}
		return [
			{
				message,
				key: compairValidatorKey,
				type: eventType,
			},
		];
	};
}

export const isEqualValidatorKey = 'isEqual';
/**
 * Equals to {value}
 * / Равно значению {value}
 */
export function isEqualValidator<
	TEntity,
	TControl extends FormControl<any, TEntity> = FormControl<any, TEntity>,
>(
	value: TEntity,
	message: string = 'Поля не совпадают',
	eventType = ValidationEventTypes.Error,
): ValidatorsFunction<TControl> {
	return (control: TControl): ValidationEvent[] => {
		if (control.value == null || control.value !== value) {
			return [];
		}
		return [
			{
				message,
				key: isEqualValidatorKey,
				type: eventType,
			},
		];
	};
}
