import { Menu } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useCallback } from 'react';

import { useNavigate } from 'react-router';

import { useLayoutModel } from '../../model/LayoutModel';

import type { MenuInfo } from 'rc-menu/lib/interface';

import styles from './navbar.module.scss';

export type LayoutNavbarMenuMode = 'horizontal' | 'vertical';

export type LayoutNavbarProps = {
	mode?: LayoutNavbarMenuMode;
};

export const LayoutNavbar = observer<LayoutNavbarProps>(function LayoutNavbar({
	mode = 'horizontal',
}) {
	const layoutModel = useLayoutModel();

	const navigate = useNavigate();

	const handleSelect = useCallback(
		(info: MenuInfo) => {
			layoutModel.navbar.handleMenuSelect(info);
			const to = layoutModel.navbar.activeNavItem?.to;
			if (to) {
				navigate(to);
			}
		},
		[layoutModel.navbar, navigate],
	);

	return (
		<Menu
			className={classNames({
				[styles.horizontal]: mode === 'horizontal',
				[styles.vertical]: mode === 'vertical',
			})}
			selectedKeys={layoutModel.navbar.selectedKeys}
			mode={mode}
			items={layoutModel.navbar.navItems}
			onClick={handleSelect}
		/>
	);
});
