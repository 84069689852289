import { makeAutoObservable } from 'mobx';

import { Registry } from '@/shared/lib/collections/Registry';

import { ChatEntity } from './ChatEntity';
import { ChatModel } from './ChatModel';
import { MessengerQueryModel } from './MessengerQueryModel';

export type ChatCategory = 'private' | 'favorite' | 'incidents';

export class AiChatModel {
	constructor(public query: MessengerQueryModel) {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	private itemsRegistry = new Registry<ChatEntity, ChatModel>(
		(v) => new ChatModel(v, this.query),
	);

	get items() {
		return this.itemsRegistry.from(this.query.chats.data);
	}

	selectedChat: ChatModel | null = null;

	public setSelectedChat(chat: ChatModel) {
		this.selectedChat = chat;
	}
}
