import { observer } from 'mobx-react-lite';

import styles from './recommendation-tag.module.scss';

type RecommendationTagProps = {
	recommendation: string;
	onClick?: () => void;
};

export const RecommendationTag = observer<RecommendationTagProps>(function RecommendationTag({
	recommendation,
	onClick,
}) {
	return (
		<div className={styles.gradient} onClick={onClick}>
			<div className={styles.container}>
				<span className={styles.recommendation}>{recommendation}</span>
			</div>
		</div>
	);
});
