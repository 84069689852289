import { Layout as AntLayout } from 'antd';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-router-dom';

import { useLayoutModel } from '../../model/LayoutModel';
import { FallbackComponent } from '../FallbackComponent';
import { LayoutNavbar } from '../LayoutNavbar';
import { TabsNavbar } from '../TabsNavbar';
import { Toolbar } from '../Toolbar';

import styles from './layout.module.scss';

export type LayoutProps = {
	children?: ReactNode;
};
export const Layout = observer<LayoutProps>(function Layout({ children }) {
	const layoutModel = useLayoutModel();

	return (
		<>
			<AntLayout.Header className={styles.header}>
				<Link to={'/'} className={styles.logo}>
					SECAI AIR
				</Link>
				{layoutModel.mode === 'default' && (
					<>
						<LayoutNavbar />
						<Toolbar />
					</>
				)}
				{layoutModel.mode === 'tabs' && (
					<>
						<TabsNavbar />
						<Toolbar dark />
					</>
				)}
			</AntLayout.Header>
			<AntLayout.Content key="123" className={styles.content}>
				<ErrorBoundary FallbackComponent={FallbackComponent}>{children}</ErrorBoundary>
			</AntLayout.Content>
		</>
	);
});
