import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Form, Input, Typography } from 'antd';

import { observer } from 'mobx-react-lite';

import { LoginFormModel } from '@/features/auth/model/LoginFormModel';
import { Button } from '@/shared/ui/Button';

import styles from './login-form.module.scss';

export type LoginFormProps = {
	model: LoginFormModel;
	isLoading?: boolean;
};

export const LoginForm = observer<LoginFormProps>(function LoginForm({ model, isLoading }) {
	return (
		<Form className={styles.form} onFinish={model.submit}>
			<Form.Item
				name="username"
				validateStatus={
					model.username.isDirty && model.username.errorMessage ? 'error' : ''
				}
				help={model.username.isDirty && model.username.errorMessage}
			>
				<Input
					prefix={
						<Typography.Text type="secondary">
							<UserOutlined />
						</Typography.Text>
					}
					placeholder="Учетная запись"
					{...model.username.props}
				/>
			</Form.Item>
			<Form.Item
				name="password"
				validateStatus={
					model.password.isDirty && model.password.errorMessage ? 'error' : ''
				}
				help={model.password.isDirty && model.password.errorMessage}
			>
				<Input
					prefix={
						<Typography.Text type="secondary">
							<LockOutlined />
						</Typography.Text>
					}
					type="password"
					placeholder="Пароль"
					{...model.password.props}
				/>
			</Form.Item>

			{/* <Form.Item>
			<Form.Item name="remember" valuePropName="checked" noStyle>
				<Checkbox>Remember me</Checkbox>
			</Form.Item>

			<a className="login-form-forgot" href="">
				Forgot password
			</a>
		</Form.Item> */}

			<Button block type="fill" htmlType="submit" variant="primary" loading={isLoading}>
				Войти
			</Button>
		</Form>
	);
});
