import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

import { forwardRef } from 'react';

import { Button } from '@/shared/ui/Button';
import { Input, InputProps } from '@/shared/ui/form/Input';

import { MessengerModel } from '../../model/MessengerModel';

import styles from './chat-search-input.module.scss';

type FilterSearchProps = InputProps & {
	model: MessengerModel;
	type: 'global' | 'messages';
};

export const ChatSearchInput = forwardRef<HTMLInputElement, FilterSearchProps>(
	function ChatSearchInput({ model, type, ...props }, ref) {
		return (
			<div ref={ref} className={styles.container}>
				<Input
					size="large"
					variant="borderless"
					autoFocus
					placeholder={type === 'global' ? 'Глобальный поиск' : 'Поиск по сообщениям'}
					prefix={<SearchOutlined />}
					suffix={
						<Button
							type="text"
							variant="secondary"
							className={styles.button}
							onClick={
								type === 'global'
									? model.listSearchInputToggle.disable
									: model.messageSearchInputToggle.disable
							}
						>
							<CloseOutlined />
						</Button>
					}
					classNames={{ prefix: styles.prefix }}
					className={styles.input}
					{...props}
				/>
			</div>
		);
	},
);
