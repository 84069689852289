import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { AppConfig } from '@/shared/config/AppConfig';
import { Toggler } from '@/shared/lib/collections/Toggler';
import { getComponentDisplayName } from '@/shared/lib/getComponentDisplayName';
import { DemoContext, DemoModal } from '@/shared/ui/DemoOverlay';

export const demoProvider = <P extends { [K in keyof P]?: P[K] }>(
	WrappedComponent: React.FC<P>,
) => {
	const WithDemoProvider = observer<P & { appConfig: AppConfig }>(function WithDemoProvider(
		props: P & { appConfig: AppConfig },
	) {
		const [model] = useState(() => {
			return {
				modal: new Toggler(),
			};
		});
		return (
			<DemoContext.Provider value={model}>
				<DemoModal toggler={model.modal} />
				<WrappedComponent {...props} />
			</DemoContext.Provider>
		);
	});
	WithDemoProvider.displayName = `WithDemoProvider(${getComponentDisplayName(WrappedComponent)})`;

	return WithDemoProvider;
};
