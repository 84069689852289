import { makeAutoObservable } from 'mobx';

import { ChatMessageBaseEntity } from '../ChatMessageEntity';
import { MessengerQueryModel } from '../MessengerQueryModel';

export class ChatMessageModel {
	constructor(
		public message: ChatMessageBaseEntity,
		public query: MessengerQueryModel,
	) {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	get user() {
		return this.query.users.getItem(this.message.from);
	}

	get date() {
		return new Date(this.message.date);
	}

	get text() {
		return this.message.text;
	}

	get title() {
		return this.user?.fio || '';
	}
}
