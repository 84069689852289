import { IObservableArray, makeObservable, observable } from 'mobx';

import { Entity } from '@/shared/lib/collections/Entity';

import { Repository } from '@/shared/lib/collections/Repository';

import { ChatDocumentDto, ChatMessageInnerObjectDto } from '../../lib/ChatDto';

export type ChatMessageBaseDto = {
	id: string;
	/** id отправителя */
	from: string;
	/** Id чата */
	chat: string;
	/** Дата отправки сообщения */
	date: string;
	/** Текст сообщения */
	text: string;
	/** Закреплено ли это сообщение  */
	isPinned: boolean;
	/** Массив объектов с правилами форматирования частей текста */
	objects?: ChatMessageInnerObjectDto[];
	/** Прикрепленные документы к сообщению */
	documents?: ChatDocumentDto[];
};

const iso = new Date().toISOString();

export class ChatMessageBaseEntity implements Entity<ChatMessageBaseDto> {
	constructor(public id: string) {
		makeObservable({
			from: observable,
			chat: observable,
			date: observable,
			text: observable,
			isPinned: observable,
		});
	}
	from: string = '';
	chat: string = '';
	date: string = iso;
	text: string = '';
	isPinned: boolean = false;

	objects: IObservableArray<ChatMessageInnerObjectDto> = observable.array(undefined, {
		deep: false,
	});
	documents: IObservableArray<ChatDocumentDto> = observable.array(undefined, {
		deep: false,
	});
	updateFromJson(updateDto: ChatMessageBaseDto): void {
		this.partialUpdateFromJson(updateDto);
	}

	partialUpdateFromJson({ objects, documents, ...updateDto }: Partial<ChatMessageBaseDto>): void {
		if (objects) {
			this.objects.replace(objects);
		}
		if (documents) {
			this.documents.replace(documents);
		}
		Object.assign(this, updateDto);
	}
}

export class ChatMessageBaseRepository extends Repository<ChatMessageBaseEntity> {
	constructor() {
		super(function createItem(id: string) {
			return new ChatMessageBaseEntity(id);
		});
	}
}
