const SINGLETON_SIMBOL: symbol = Symbol('singleton');

function instance(symbol: symbol, Class: any, ...args: any[]): any {
	if (!(Class[symbol] instanceof Class)) {
		Class[symbol] = new Class(...args);
	}

	return Class[symbol];
}

/** Функция для создания синглтона из класса */
export function singleton<BaseClass>(Class: BaseClass, key?: string): BaseClass {
	const symbol = key === undefined ? SINGLETON_SIMBOL : Symbol.for(key);
	return instance.bind(null, symbol, Class) as BaseClass;
}

/** Функция для создания синглтона из класса, для серверного рендеринга синглтон не будет создаваться */
export function isomorphicSingleton<BaseClass>(Class: BaseClass, key?: string): BaseClass {
	if (typeof window === 'undefined') {
		return Class;
	}
	return singleton<BaseClass>(Class, key);
}
