import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import random from 'lodash/random';
import { v4 } from 'uuid';

export type ChatDto = {
	id: string;
	/**
	 * Тип чата
	 * private - личный чат с другим пользователем
	 * channel - общий чат, куда может писать любой пользователь, например чат инцидента
	 * group - чат доступный определенной группе пользователей, пока не используется, но может быть реализован позденее
	 */
	type: 'private' | 'channel' | 'group';
	/** Название чата */
	title: string;
	/** Отмечен ли чат, как "важные" */
	isFavorite: boolean;
	/** Был ли этот чат создан инцидентом */
	isIncident: boolean;
};

/** Базовая структура сообщения */
export type ChatMessageBaseDto = {
	id: string;
	/** Id отправителя */
	from: string;
	/** Id чата */
	chat: string;
	/** Дата отправки сообщения */
	date: string;
	/** Текст сообщения */
	text: string;
	/** Закреплено ли это сообщение  */
	isPinned: boolean;
	/** Массив объектов с правилами форматирования частей текста */
	objects?: ChatMessageInnerObjectDto[];
	/** Прикрепленные документы к сообщению */
	documents?: ChatDocumentDto[];
};

/**
 * Структура пересланного сообщения
 * TODO: сейчас механизм пересылки сообщений не используется, но его нужно учесть
 */
export type ChatMessageForwardedDto = ChatMessageBaseDto & {
	/** Id отправителя оригинального сообщения */
	forwardFrom: string;
	/** Дата отправки оригинального сообщения */
	forwardDate: string;
};

/**
 * Структура сообщения для ответов
 * TODO: сейчас механизм ответов на сообщения не используется, но его нужно учесть
 */
export type ChatMessageReplyDto = ChatMessageBaseDto & {
	/** Оригинал сообщения на которое был дан ответ */
	replyToMessage: ChatMessageBaseDto | ChatMessageForwardedDto;
};

export type ChatMessageDto = ChatMessageBaseDto | ChatMessageReplyDto | ChatMessageForwardedDto;

/** Объект для описания правил форматирования блоков текста
 * аналог форматирования в телеграм
 * пример как с этим работать https://imakebots.ru/article/obekt-messageentity-priyatnyy-bonus-ot-telegram
 */
export type ChatMessageInnerObjectDto = {
	/** Тип объекта
	 * mention - упоминание вида @username
	 * hashtag - хэштег #ключевое слово
	 * url - кликабельная ссылка,  отличие от text_link в том, что url отображается в своем оригинальном виде (https://example.com)
	 * email - электронный адрес для отправки
	 * phone - номер телефона
	 * bold - жирный текст
	 * italic - курсивный текст
	 * code - моноширинная строка
	 * pre - моноширинный блок
	 * text_link - кликабельная ссылка, отличие от url в том, что text_link применяется для текста, в котором должна быть защита ссылка
	 */
	type:
		| 'mention'
		| 'hashtag'
		| 'url'
		| 'email'
		| 'phone'
		| 'bold'
		| 'italic'
		| 'code'
		| 'pre'
		| 'text_link';
	/** Смещение, с которого в тексте начинается данный объект */
	offset: string;
	/** Длина объекта в символах */
	length: string;
	/** Ссылка для типа text_link */
	url?: string;
};

export type ChatDocumentDto = {
	/** id файла */
	fileId: string;
	/** Название файла */
	fileName: string;
	/** MIME файла, заданный отправителем */
	mimeType: string;
	/** Размер файла */
	fileSize: number;
	/** Миниатюра документа, определенная отправителем */
	thumb?: ChatPhotoSizeDto;
};

export type ChatPhotoSizeDto = {
	/** id файла */
	fileId: string;
	/** Размер файла */
	fileSize: number;
	/** Ширина изображения */
	width: number;
	/** Высота изображения */
	height: number;
};

export type ChatUserDto = {
	/** ID пользователя */
	id: string;
	/** Username пользователя */
	username: string;
	/** Фамилия */
	surname: string;
	/** Имя */
	name: string;
	/** Отчество */
	patronymic: string;
	/** Ссылка на аватар пользователя */
	avatar: string;
	/** Является ли этот пользователь ботом */
	isBot: boolean;
	/** Является ли этот пользователь инцидентом */
	isIncident: boolean;
};

export type ChatsResponseDto = {
	chats: ChatDto[];
	messages: ChatMessageDto[];
	users: ChatUserDto[];
};

export function getChatDto({
	id = v4(),
	type = sample(['private', 'channel', 'group']),
	title = faker.lorem.words(),
	isFavorite = !random(0, 1),
	isIncident = !random(0, 1),
}: Partial<ChatDto>): ChatDto {
	return {
		id,
		type,
		title,
		isFavorite,
		isIncident,
	};
}

export function getChatMessageDto({
	id = v4(),
	from = v4(),
	chat = v4(),
	date = new Date().toISOString(),
	text = faker.lorem.paragraph(),
	isPinned = false,
}: Partial<ChatMessageDto>): ChatMessageDto {
	return {
		id,
		from,
		chat,
		date,
		text,
		isPinned,
	};
}

export function getChatUserDto({
	id = v4(),
	username = faker.internet.userName(),
	surname = faker.person.lastName(),
	name = faker.person.firstName(),
	patronymic = faker.person.lastName(),
	avatar = !random(0, 1) ? 'https://placehold.jp/32x32.png' : '',
	isBot = !random(0, 1),
	isIncident = !random(0, 1),
}: Partial<ChatUserDto>): ChatUserDto {
	return {
		id,
		username,
		surname,
		name,
		patronymic,
		avatar,
		isBot,
		isIncident,
	};
}

export function getChatsResponseDto({
	chats = Array(random(2, 5))
		.fill(0)
		.map(() => getChatDto({})),
	messages = Array(random(2, 5))
		.fill(0)
		.map(() => getChatMessageDto({})),
	users = Array(random(2, 5))
		.fill(0)
		.map(() => getChatUserDto({})),
	...rest
}: Partial<ChatsResponseDto>): ChatsResponseDto {
	return {
		chats,
		messages,
		users,
		...rest,
	};
}
