import { Repository } from '@/shared/lib/collections/Repository';

import { ChatEntity } from '.';

export class ChatRepository extends Repository<ChatEntity> {
	constructor() {
		super(function createItem(id: string) {
			return new ChatEntity(id);
		});
	}
}
