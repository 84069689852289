import { theme } from 'antd';

import hexRgb from 'hex-rgb';
import kebabCase from 'lodash/kebabCase';
import uniq from 'lodash/uniq';
import { memo, useMemo } from 'react';
import { createPortal } from 'react-dom';

// function getVariable([tokenName, value]: [string, string | number | boolean]) {
// 	const isPrivateField = tokenName.startsWith('_');
// 	if (isPrivateField) return '';

// 	if (typeof value === 'boolean') return;

// 	if (typeof value === 'number') {
// 		value = isPureNumberProperty(tokenName) ? value : `${value}px`;
// 	}
// 	if (typeof value === 'string') {
// 		if (value.startsWith('#')) {
// 			try {
// 				const rgb = hexRgb(value);

// 				const name = kebabCase(tokenName);

// 				return (
// 					`--ant-${name}: ${value};\n` +
// 					`--ant-${name}-rgb: ${rgb.red},${rgb.green},${rgb.blue}`
// 				);
// 			} catch (error) {}
// 		}

// 		return `--ant-${kebabCase(tokenName)}: ${value}`;
// 	}
// 	return '';
// }

// const isPureNumberProperty = (tokenName: string) =>
// 	tokenName.includes('zIndex') ||
// 	tokenName.includes('Weight') ||
// 	tokenName.includes('motion') ||
// 	tokenName.includes('opacity') ||
// 	tokenName.includes('lineHeight');

function getRgbVariable([tokenName, value]: [string, string | number | boolean]) {
	const isPrivateField = tokenName.startsWith('_');
	if (isPrivateField) return '';

	if (typeof value === 'boolean') return;

	if (typeof value === 'string') {
		if (value.startsWith('#')) {
			try {
				const rgb = hexRgb(value);

				const name = kebabCase(tokenName);

				return `--ant-${name}-rgb: ${rgb.red},${rgb.green},${rgb.blue}`;
			} catch (error) {}
			return `--ant-${kebabCase(tokenName)}: ${value}`;
		}
	}
	return '';
}

export const CssTokenBridge = memo(({ cssKey }: { cssKey: string }) => {
	const { token } = theme.useToken();

	const variables = useMemo(() => {
		const rules = uniq(
			Object.entries(token)
				.map(getRgbVariable)
				.filter((v) => !!v),
		).join(';\n');
		return rules;
	}, [token]);

	return createPortal(
		<style dangerouslySetInnerHTML={{ __html: `.${cssKey}{${variables}}` }} />,
		document.head,
	);
});
