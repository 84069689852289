import { observer } from 'mobx-react-lite';

import type { CompanyEntity } from '../../model/CompanyEntity';

import styles from './company.module.scss';

export type CompanyProps = {
	model: CompanyEntity;
};

export const Company = observer<CompanyProps>(function Company({ model }) {
	return (
		<div className={styles.container}>
			<div />
		</div>
	);
});
