import { computed, createAtom, IAtom, makeObservable } from 'mobx';

export class ReactiveTimer {
	private atom: IAtom;
	private intervalHandler: NodeJS.Timer | null = null;
	private currentDateTime = new Date();

	constructor(private interval: number = 1000) {
		this.atom = createAtom(
			'Clock',
			() => this.startTicking(),
			() => this.stopTicking(),
		);
		makeObservable(this, {
			gmtOffset: computed.struct,
			ISOString: computed.struct,
		});
	}

	private tick() {
		this.currentDateTime = new Date();
		this.atom.reportChanged();
	}

	private startTicking() {
		this.tick();
		this.intervalHandler = setInterval(() => this.tick(), this.interval);
	}

	private stopTicking() {
		if (this.intervalHandler !== null) {
			clearInterval(this.intervalHandler);
		}
		this.intervalHandler = null;
	}

	public get time() {
		if (this.atom.reportObserved()) {
			return this.currentDateTime;
		} else {
			return new Date();
		}
	}

	public get timestamp() {
		return this.time.getTime();
	}

	public get gmtOffset() {
		const offset = new Date().getTimezoneOffset() / 60;

		let prefix = 'GMT ';
		if (offset > 0) {
			prefix += '-';
		} else {
			prefix += '+';
		}
		return prefix + Math.abs(offset);
	}

	public get ISOString() {
		return this.time.toISOString();
	}
}
