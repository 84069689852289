import { useMemo } from 'react';

import { AppThemeMode } from '@/shared/config/AppConfig';
import { useExtendedTheme } from '@/shared/lib/theme/useExtendedTheme';

import type { CardSize } from 'antd/es/card/Card';

import type { ComponentTokenMap, AliasToken } from 'antd/es/theme/interface';
export type CardBgVariant = 'elevated' | 'spotlight' | 'default';
export type CardRoundedVariant = 'none' | 'lg' | 'sm';
export type CardTitleVariant = 'tertiary' | 'default';
export type CardTitlePosition = 'left' | 'center';

export type PaddingResetProps =
	| {
			top?: boolean;
			bottom?: boolean;
			left?: boolean;
			right?: boolean;
	  }
	| true;

type CardTokens = Partial<
	ComponentTokenMap['Card'] &
		AliasToken &
		Record<
			| 'cardTitleAlign'
			| 'cardHeaderBorderBottom'
			| 'cardTitleColor'
			| 'cardPaddingTop'
			| 'cardPaddingRight'
			| 'cardPaddingBottom'
			| 'cardPaddingLeft',
			string
		>
>;

type CardThemes = Record<AppThemeMode, CardTokens>;

export function useCardTheme(
	bg: CardBgVariant,
	rounded: CardRoundedVariant,
	titleVariant: CardTitleVariant,
	titlePosition: CardTitlePosition,
	hasHeaderDivider: boolean,
	size: CardSize,
	paddingResetProps?: PaddingResetProps,
) {
	const paddingReset = useMemo(() => {
		return typeof paddingResetProps === 'boolean'
			? { top: true, bottom: true, left: true, right: true }
			: paddingResetProps || {};
	}, [paddingResetProps]);

	const theme = useExtendedTheme<{}, { Card: CardTokens }>(
		({ token }, cssKey) => {
			const CardBgMap: Record<CardBgVariant, string> = {
				default: token.colorBgContainer,
				spotlight: token.colorBgSpotlight,
				elevated: token.colorBgElevated,
			};

			const CardBorderRadiusMap: Record<CardRoundedVariant, number> = {
				none: 0,
				lg: token.borderRadiusLG,
				sm: token.borderRadiusSM,
			};

			const CardTitleColorMap: Record<CardTitleVariant, string> = {
				tertiary: token.colorTextTertiary,
				default: token.colorTextHeading,
			};

			const CardTitleAlignMap: Record<CardTitlePosition, string> = {
				center: 'center',
				left: 'left',
			};

			let cardPaddingTop, cardPaddingRight, cardPaddingBottom, cardPaddingLeft;

			if (size === 'small') {
				cardPaddingTop = token.paddingSM + 'px';
				cardPaddingRight = token.paddingSM + 'px';
				cardPaddingBottom = token.paddingSM + 'px';
				cardPaddingLeft = token.paddingSM + 'px';
			} else {
				cardPaddingTop = token.paddingMD + 'px';
				cardPaddingRight = token.paddingMD + 'px';
				cardPaddingBottom = token.paddingMD + 'px';
				cardPaddingLeft = token.paddingMD + 'px';
			}

			if (paddingReset.top) cardPaddingTop = '0px';
			if (paddingReset.right) cardPaddingRight = '0px';
			if (paddingReset.bottom) cardPaddingBottom = '0px';
			if (paddingReset.left) cardPaddingLeft = '0px';

			const themes: CardThemes = {
				dark: {
					borderRadius: CardBorderRadiusMap[rounded],
					borderRadiusLG: CardBorderRadiusMap[rounded],
					colorBgContainer: CardBgMap[bg],
					cardTitleColor: CardTitleColorMap[titleVariant],
					cardTitleAlign: CardTitleAlignMap[titlePosition],
					cardHeaderBorderBottom: hasHeaderDivider
						? `${token.lineWidth}px ${token.lineType} ${token.colorBorderSecondary}`
						: 'none',
					cardPaddingTop,
					cardPaddingLeft,
					cardPaddingRight,
					cardPaddingBottom,
				},
			};

			return {
				components: {
					Card: {
						...themes[cssKey],
					},
				},
			};
		},
		[bg, hasHeaderDivider, paddingReset, rounded, size, titlePosition, titleVariant],
	);

	return {
		theme,
		prefix:
			theme.cssVar.key +
			'-' +
			'card-' +
			[bg, rounded, titleVariant, titlePosition, size]
				.map((v) => v.charAt(0))
				.filter(Boolean)
				.join('') +
			'-' +
			[
				paddingReset.top && 't',
				paddingReset.right && 'r',
				paddingReset.bottom && 'b',
				paddingReset.left && 'l',
			]
				.filter(Boolean)
				.join(''),
	};
}
