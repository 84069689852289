import { observer } from 'mobx-react-lite';

import { ChatMessageModel } from '../../model/ChatMessageModel';
import { ChatModel } from '../../model/ChatModel';

import { MessengerModel } from '../../model/MessengerModel';

import { MessageItem } from './MessageItem';

type MessagesListProps = {
	messages: ChatMessageModel[];
	model: MessengerModel;
	chat: ChatModel;
};

export const MessagesList = observer<MessagesListProps>(function MessagesList({ messages, chat }) {
	return (
		<>
			{messages.map((message) => (
				<MessageItem key={message.message.id} message={message} chat={chat} />
			))}
		</>
	);
});
