import { observer } from 'mobx-react-lite';

import { Component, ComponentType, FunctionComponent, ReactElement, ReactNode } from 'react';

import { UserEntity } from '@/entities/user';
import { useAuthModel } from '@/features/auth';
import { checkUserPermission } from '@/features/auth/lib/checkUserPermission';

export type AuthGuardFallbackProps = {
	user: UserEntity;
	permissions: string[];
};

export type AuthGuardSharedProps = {
	permissions: string[];
	children?: ReactNode | undefined;
};

export type AuthGuardPropsWithComponent = AuthGuardSharedProps & {
	fallback?: never;
	FallbackComponent: ComponentType<AuthGuardFallbackProps>;
};
export type AuthGuardPropsWithFallback = AuthGuardSharedProps & {
	fallback: ReactElement<unknown, string | FunctionComponent | typeof Component> | null;
	FallbackComponent?: never;
};

export type AuthGuardWithoutFallback = AuthGuardSharedProps & {
	fallback?: never;
	FallbackComponent?: never;
};
export type AuthGuardProps =
	| AuthGuardWithoutFallback
	| AuthGuardPropsWithFallback
	| AuthGuardPropsWithComponent;

export const AuthGuard = observer<AuthGuardProps>(function AuthGuard({
	children,
	permissions,
	FallbackComponent,
	fallback = null,
}) {
	const authModel = useAuthModel();

	const { isPassed } = checkUserPermission(authModel.user, permissions);

	if (isPassed) {
		return <>{children}</>;
	}

	if (FallbackComponent) {
		fallback = <FallbackComponent permissions={permissions} user={authModel.user} />;
	}

	return fallback;
});

<AuthGuard permissions={[]} />;
