export class Registry<TKey = unknown, TItem = unknown> {
	constructor(private createItem: (dto: TKey) => TItem) {}
	private registry = new Map<TKey, TItem>();
	from(data: TKey[]) {
		const result: TItem[] = [];
		const isExistData = new Set();
		data.forEach((v) => {
			isExistData.add(v);
			let item = this.registry.get(v);
			if (!item) {
				item = this.createItem(v);
				this.registry.set(v, item);
			}
			result.push(item);
		});
		this.registry.forEach((item, dto, registry) => {
			if (!isExistData.has(dto)) {
				registry.delete(dto);
			}
		});
		return result;
	}

	get = this.registry.get;
}
