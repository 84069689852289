// import { when } from 'mobx';

import ReactDOM from 'react-dom/client';

import { App } from '@/app';

import { AuthModel } from '@/features/auth';

import { AppConfig } from '@/shared/config/AppConfig';
import { RepositoriesManager } from '@/shared/lib/collections/RepositoriesManager';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

/**
 * Ant не может на ходу менять локаль(и возможно что-то еще)
 * При изменении локали в ConfigProvider из Ant происходит перемонтирование
 * его дочерних компонентов, то есть всего дерева приложения из-за чего
 * проиходит мерцание экрана, поэтому инициализация конфига вынеса в корень приложения
 * и монтирование всего дерева происходит после того, как все загрузятся локали
 * TODO: Можно сделать лоадер вне контекста Ant, который будет отображаться, пока грузится приложение
 */
const repositoriesManager = new RepositoriesManager();
const authModel = new AuthModel(repositoriesManager);
const appConfig = new AppConfig(repositoriesManager, authModel);

root.render(<App appConfig={appConfig} />);
// when(
// 	() => !appConfig.locale.isLoading,
// 	() => {
// 		root.render(<App appConfig={appConfig} />);
// 	},
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
