import { LockOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import { observer } from 'mobx-react-lite';

import { Toggler } from '@/shared/lib/collections/Toggler';
import { useStore } from '@/shared/lib/useStore';
import { Button, CloseButton } from '@/shared/ui/Button';

import { DemoForm } from './DemoForm';

import styles from './demo.module.scss';

export type DemoProps = {
	closable?: boolean;
	onClose?: () => void;
};

export const Demo = observer<DemoProps>(function Demo({ closable, onClose }) {
	const toggler = useStore(() => new Toggler(), []);

	return (
		<div className={styles.card} onClick={(e) => e.stopPropagation()}>
			{closable && <CloseButton className={styles.close} onClick={onClose} />}
			<div className={styles.header}>
				<LockOutlined className={styles.icon} />
				<h3 className={styles.title}>Ограниченный доступ</h3>
				<div className={styles.desc}>
					Функциональность данного раздела ограничена в рамках демонстрационной версии
					продукта.
				</div>
			</div>
			<div className={styles.divider}></div>
			<div className={styles.body}>
				<div className={styles.desc}>
					Для получения полного доступа и дальнейшего тестирования предлагаем Вам оформить
					заявку на пилотный проект.
				</div>
			</div>
			<div className={styles.footer}>
				<Button variant="primary" ghost onClick={toggler.enable}>
					Оформить заявку на пилот
				</Button>
				<Modal
					centered
					open={toggler.isEnabled}
					onClose={toggler.disable}
					onCancel={toggler.disable}
					footer={null}
					closable
					classNames={{
						content: styles.modal__content,
						body: styles.modal__body,
					}}
					zIndex={10000000}
					maskClosable={false}
					width={460}
				>
					<DemoForm />
				</Modal>
			</div>
		</div>
	);
});

Demo.displayName = 'ForwardRef shared/ui/(Demo)';
