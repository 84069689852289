import { faker } from '@faker-js/faker';
import { v4 } from 'uuid';

import { getUserDto, UserDto } from '@/entities/user';

import { localStorageKeys, withLocalStorage } from './withLocalStorage';

type UserPreset = Partial<UserDto> & { id: string };

function generate() {
	const userPresets: UserPreset[] = [
		{
			id: v4(),
			username: 'i.ivanov',
			surname: 'Иванов',
			name: 'Иван',
			patronymic: 'Иванович',
			avatar: 'https://placehold.jp/32x32.png',
			lastLoginDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			lastUpdateDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			registrationDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			accountType: 'SECAI AIR',
			roles: [
				{ name: 'Администратор', attributes: 34 },
				{ name: 'SOC L3', attributes: 17 },
				{ name: 'SOC L2', attributes: 25 },
				{ name: 'SOC L1', attributes: 13 },
			],
			permissions: [
				{
					name: 'Изменение параметров экземпляра интеграции',
					section: 'Интеграции ',
					subSection: 'Экземпляры интеграций',
					hasAccess: true,
				},
				{
					name: 'Изменение параметров подключения к Репозиторию',
					section: 'Система ',
					subSection: 'Репозиторий',
					hasAccess: false,
				},
			],
			hasTwoFactorAuth: true,
			hasSystemAccess: true,
		},
		{
			id: v4(),
			username: 'p.petrovvvv',
			surname: 'Петров',
			name: 'Петр',
			patronymic: 'Петрович',
			avatar: 'https://placehold.jp/32x32.png',
			lastLoginDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			lastUpdateDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			registrationDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			accountType: 'LDAP',
			roles: [
				{ name: 'SOC L3', attributes: 12 },
				{ name: 'SOC L2', attributes: 5 },
				{ name: 'SOC L1', attributes: 36 },
			],
			permissions: [
				{
					name: 'Изменение параметров подключения к Репозиторию',
					section: 'Система ',
					subSection: 'Репозиторий',
					hasAccess: false,
				},
				{
					name: 'Изменение параметров экземпляра интеграции',
					section: 'Интеграции ',
					subSection: 'Экземпляры интеграций',
					hasAccess: false,
				},
			],
			hasTwoFactorAuth: false,
			hasSystemAccess: false,
		},
		{
			id: v4(),
			username: 'k.kostya',
			surname: 'Константинов',
			name: 'Константин',
			patronymic: 'Константинович',
			avatar: '',
			lastLoginDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			lastUpdateDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			registrationDate: faker.date
				.between({
					from: Date.now() - 1000 * 60 * 60 * 24 * 7,
					to: Date.now(),
				})
				.toISOString(),
			accountType: 'AD FS',
			roles: [
				{ name: 'SOC L2', attributes: 21 },
				{ name: 'SOC L1', attributes: 13 },
			],
			permissions: [
				{
					name: 'Изменение параметров экземпляра интеграции',
					section: 'Интеграции ',
					subSection: 'Экземпляры интеграций',
					hasAccess: true,
				},
				{
					name: 'Изменение параметров подключения к Репозиторию',
					section: 'Система ',
					subSection: 'Репозиторий',
					hasAccess: true,
				},
			],
			hasTwoFactorAuth: true,
			hasSystemAccess: true,
		},
	];

	const users = userPresets.map((v) => getUserDto(v));
	return users;
}

const users = withLocalStorage(localStorageKeys.users, generate);

export function mockRequestUsers(): UserDto[] {
	return users;
}

export function mockRequestUser(id: string): UserDto {
	const user = users.find((v) => v.id === id);
	if (user) {
		return user;
	}
	throw new Error('Not Found');
}

export function getUsers(): UserDto[] {
	return users;
}
