import { RouteObject } from 'react-router';

import type { AppConfig } from '@/shared/config/AppConfig';
import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute } from '@/shared/lib/router/createDynamicRoute';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { IncidentPage } = await import('./ui/IncidentPage');
	return {
		default: IncidentPage,
	};
};

export const incidentPageRoute = (appConfig: AppConfig, layoutModel: LayoutModel): RouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const incidentID = params.params.incidentID || '';
			const { IncidentPageModel } = await import('./model/IncidentPageModel');
			const model = new IncidentPageModel(incidentID, appConfig);
			await model.incidentRequest.request();

			const tab = new URL(params.request.url).searchParams.get('tab');

			if (tab && IncidentPageModel.allowTabKeys.includes(tab)) {
				model.navbar.setActiveKey(tab);
			}

			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.incident.path,
		},
	);
