import { faker } from '@faker-js/faker';
import { v4 } from 'uuid';

import {
	ChatDto,
	ChatsResponseDto,
	ChatUserDto,
	getChatsResponseDto,
} from '@/widgets/messenger/lib/ChatDto';

import { CHAT_USERS } from './chats';
import { localStorageKeys, withLocalStorage } from './withLocalStorage';

const CHATS: ChatDto[] = [
	{
		id: v4(),
		type: 'private',
		title: 'Вредоносный объект был найден и не вылечен в течение 5 минут',
		isFavorite: true,
		isIncident: false,
	},
	{
		id: v4(),
		type: 'private',
		title: 'Сканирование порта 443 — необычная активность',
		isFavorite: false,
		isIncident: false,
	},
	{
		id: v4(),
		type: 'private',
		title: 'Попытка входа с неизвестного устройства',
		isFavorite: false,
		isIncident: false,
	},
	{
		id: v4(),
		type: 'private',
		title: 'Подозрительный файл "invoice_2024.pdf"',
		isFavorite: false,
		isIncident: false,
	},
	{
		id: v4(),
		type: 'private',
		title: 'Подмена DNS-запросов на локальной сети',
		isFavorite: false,
		isIncident: false,
	},
];

const USER_AI: ChatUserDto = {
	id: v4(),
	avatar: '',
	isBot: true,
	isIncident: false,
	name: '',
	patronymic: '',
	surname: '',
	username: 'secai',
};

const USERS = [CHAT_USERS[0], USER_AI];

const CURRENT_USER: ChatUserDto = USERS[0];

function generate() {
	const chats = getChatsResponseDto({
		chats: CHATS,
		users: USERS,
		messages: [
			{
				id: v4(),
				from: CURRENT_USER.id,
				chat: CHATS[0].id,
				date: '2024-10-02T13:57:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: USER_AI.id,
				chat: CHATS[0].id,
				date: '2024-10-02T13:58:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: CURRENT_USER.id,
				chat: CHATS[0].id,
				date: '2024-10-02T13:57:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: USER_AI.id,
				chat: CHATS[0].id,
				date: '2024-10-02T13:58:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: CURRENT_USER.id,
				chat: CHATS[1].id,
				date: '2024-10-02T13:57:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: USER_AI.id,
				chat: CHATS[1].id,
				date: '2024-10-02T13:58:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: CURRENT_USER.id,
				chat: CHATS[2].id,
				date: '2024-10-02T13:57:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: USER_AI.id,
				chat: CHATS[2].id,
				date: '2024-10-02T13:58:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: CURRENT_USER.id,
				chat: CHATS[3].id,
				date: '2024-10-02T13:57:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: USER_AI.id,
				chat: CHATS[3].id,
				date: '2024-10-02T13:58:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: CURRENT_USER.id,
				chat: CHATS[4].id,
				date: '2024-10-02T13:57:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
			{
				id: v4(),
				from: USER_AI.id,
				chat: CHATS[4].id,
				date: '2024-10-02T13:58:15.777Z',
				text: faker.lorem.paragraph(),
				isPinned: false,
			},
		],
	});

	chats.messages.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

	return chats;
}

const chats = withLocalStorage(localStorageKeys.aiChats, generate);

export function getAiChats() {
	return chats;
}
export function mockRequestAiChats(): ChatsResponseDto {
	return chats;
}
