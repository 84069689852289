import { makeAutoObservable } from 'mobx';

import { Registry } from '@/shared/lib/collections/Registry';

import { ChatEntity } from '../ChatEntity';
import { ChatModel } from '../ChatModel';
import { MessengerQueryModel } from '../MessengerQueryModel';

export class ChatListModel {
	constructor(public query: MessengerQueryModel) {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	private itemsRegistry = new Registry<ChatEntity, ChatModel>(
		(v) => new ChatModel(v, this.query),
	);

	get items() {
		return this.itemsRegistry.from(this.query.chats.data);
	}

	get privateChats() {
		return this.items.filter((chat) => chat.isPrivate);
	}

	get favoriteChats() {
		return this.items.filter((chat) => chat.isFavorite);
	}

	get incidentChats() {
		return this.items.filter((chat) => chat.isIncident);
	}
	selectedChat: ChatModel | null = null;

	public setSelectedChat(chat: ChatModel) {
		this.selectedChat = chat;
	}
}
