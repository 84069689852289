import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
	<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 1.75L2.5 4.25V11.75C2.5 15.8926 5.85742 19.25 10 19.25C14.1426 19.25 17.5 15.8926 17.5 11.75V4.25L10 1.75ZM16.0938 11.75C16.0938 15.1152 13.3652 17.8437 10 17.8437C6.63477 17.8437 3.90625 15.1152 3.90625 11.75V5.30469L10 3.15625L16.0938 5.30469V11.75Z"
			fill="url(#paint0_linear_3049_22143)"
		/>
		<path
			d="M7.38969 9.77692C7.32452 9.71146 7.24706 9.65951 7.16176 9.62406C7.07646 9.58861 6.98499 9.57036 6.89262 9.57036C6.80025 9.57036 6.70878 9.58861 6.62348 9.62406C6.53818 9.65951 6.46072 9.71146 6.39555 9.77692C6.33008 9.84209 6.27813 9.91955 6.24269 10.0049C6.20724 10.0902 6.18899 10.1816 6.18899 10.274C6.18899 10.3664 6.20724 10.4578 6.24269 10.5431C6.27813 10.6284 6.33008 10.7059 6.39555 10.7711L8.96391 13.3394C9.02555 13.4012 9.09877 13.4502 9.17937 13.4836C9.25997 13.517 9.34637 13.5342 9.43363 13.5342C9.52089 13.5342 9.6073 13.517 9.6879 13.4836C9.7685 13.4502 9.84172 13.4012 9.90336 13.3394L14.2686 8.97419C14.3304 8.91254 14.3794 8.83933 14.4128 8.75872C14.4462 8.67812 14.4634 8.59172 14.4634 8.50446C14.4634 8.4172 14.4462 8.3308 14.4128 8.2502C14.3794 8.1696 14.3304 8.09638 14.2686 8.03473L14.2139 7.98005C14.1523 7.91829 14.079 7.86929 13.9984 7.83586C13.9178 7.80243 13.8314 7.78522 13.7442 7.78522C13.6569 7.78522 13.5705 7.80243 13.4899 7.83586C13.4093 7.86929 13.3361 7.91829 13.2745 7.98005L9.43266 11.8199L7.38969 9.77692Z"
			fill="url(#paint1_linear_3049_22143)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_3049_22143"
				x1="-0.285714"
				y1="1.5"
				x2="22.3931"
				y2="20.6572"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_3049_22143"
				x1="-0.285714"
				y1="1.5"
				x2="22.3931"
				y2="20.6572"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F66FA" />
				<stop offset="0.454" stopColor="#3777BA" />
				<stop offset="1" stopColor="#6ACB96" />
			</linearGradient>
		</defs>
	</svg>
);

export const SafetyGradientIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={Svg} {...props} />
);
