import { FormControl, FormControlOptions, ValueOrGetter } from '@/shared/lib/form/FormControl';
import { FormControlEventElement } from '@/shared/lib/form/formControlBindActions';

import { MentionsRef } from '..';

export type MentionsControlOptions<TEntity> = FormControlOptions<
	FormControl<MentionsRef, TEntity>
> &
	FormMentionsControlBindActionsOptions & {
		valueTransform?: (v: string) => TEntity;
	};

export type FormMentionsControlBindActionsOptions = {
	ref?(element: MentionsRef | null): void;
	onChange?(text: string): void;
	onBlur?(): void;
	onFocus?(): void;
};
export type FormMentionsControlBindedActions = {
	ref?(element: MentionsRef | null): void;
	onChange?(text: string): void;
	onBlur?(): void;
	onFocus?(): void;
};

export function formControlBindActions<
	TControl extends FormControl<any, any> = FormControl<FormControlEventElement, unknown>,
	TEventElement extends FormControlEventElement = Exclude<TControl['element'], null>,
>(
	formControl: TControl,
	toValue: (inputValue: TEventElement['value']) => TControl['value'],
	options: FormMentionsControlBindActionsOptions = {},
): FormMentionsControlBindedActions {
	return {
		onChange: (text: string) => {
			formControl.setValue(toValue(text));
			options.onChange && options.onChange(text);
		},
		ref: (element: TControl['element']): void => {
			formControl.element = element;
			options.ref && options.ref(element);
		},
		onBlur: () => {
			formControl.setTouched(true);
			formControl.setFocused(false);
			options.onBlur && options.onBlur();
		},
		onFocus: () => {
			formControl.setFocused(true);
			options.onFocus && options.onFocus();
		},
	};
}

export class MentionsControl extends FormControl<MentionsRef, string> {
	actions: FormMentionsControlBindActionsOptions;
	constructor(
		/**
		 * При инициализации моделей рекомендуется передавать функцию геттер начального значения,
		 * а не само значение. Так мы получаем "реактивные" модели контролов, которые подписываются
		 * на изменения observable переменных и всегда имеют актуальное значение переменной.
		 * Само значение используется при создании "изолированной" модели контрола или когда модель
		 * инициализируется не observable значениями.
		 */
		valueOrGetter: ValueOrGetter<string>,
		{
			ref,
			onChange,
			onBlur,
			onFocus,
			valueTransform = (v: string) => v,
			...options
		}: MentionsControlOptions<string> = {},
	) {
		super(valueOrGetter, options);

		this.actions = formControlBindActions<typeof this, HTMLInputElement>(this, valueTransform, {
			ref,
			onChange,
			onBlur,
			onFocus,
		});
	}

	get props() {
		return {
			value: this.value,
			...this.actions,
		};
	}
}
