import { action, computed, flow, isFlowCancellationError, makeObservable, observable } from 'mobx';

import { ErrorData, getErrorData } from '@/shared/lib/errors';

import type { Locale as AntLocale } from 'antd/lib/locale';
import type { Locale as DateFnsLocale } from 'date-fns/locale';
import type { CancellablePromise } from 'mobx/dist/internal';

export const LocaleCode = {
	ru: 'ru',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocaleCode = (typeof LocaleCode)[keyof typeof LocaleCode];

export type { AntLocale };
export class LocaleConfig {
	isLoading = false;
	error: ErrorData | null = null;
	antLocale: AntLocale | undefined = undefined;
	dateFnsLocale: DateFnsLocale | undefined = undefined;
	code: LocaleCode;
	private loadLocalePromise: CancellablePromise<void> | null = null;
	constructor(code: LocaleCode) {
		this.code = code;
		makeObservable(this, {
			antLocale: observable.ref,
			error: observable.ref,
			code: observable,
			isLoading: observable,
			setCode: action,
			isReady: computed,
		});
		this.loadLocale(code);
	}

	get isReady() {
		return this.antLocale !== undefined && this.dateFnsLocale !== undefined;
	}

	setCode(code: LocaleCode) {
		this.code = code;
		this.loadLocale(code);
	}

	loadLocale(code: LocaleCode) {
		if (this.loadLocalePromise) {
			this.loadLocalePromise.cancel();
		}
		try {
			this.loadLocalePromise = this.flowLoadLocale(code);
			this.loadLocalePromise.finally(() => (this.loadLocalePromise = null));
		} catch (error) {
			if (!(error instanceof Error && isFlowCancellationError(error))) {
				console.error(error);
			}
		}
	}

	/**
	 * Ожидание окончания загрузки локалей
	 */
	async wait() {
		try {
			await this.loadLocalePromise;
		} catch (error) {
			if (error instanceof Error && isFlowCancellationError(error)) {
				await this.wait();
			} else {
				console.error(error);
			}
		}
		return this;
	}

	private flowLoadLocale = flow(function* loadLocale(this: LocaleConfig, code: LocaleCode) {
		try {
			this.isLoading = true;
			let antLocale;
			let dateFnsLocale;

			if (code === 'ru') {
				antLocale = yield import('antd/locale/ru_RU').then((v) => v.default);
				dateFnsLocale = yield import('date-fns/locale/ru').then((v) => v.ru);
			}

			if (antLocale && dateFnsLocale) {
				this.antLocale = antLocale;
				this.dateFnsLocale = dateFnsLocale;
			}
			this.isLoading = false;
			this.error = null;
		} catch (error) {
			this.error = yield getErrorData(error);
			console.error(error);
		}
	});
}
