import { AppThemeMode } from '@/shared/config/AppConfig';
import { useExtendedTheme } from '@/shared/lib/theme/useExtendedTheme';

import type { ButtonType as AntButtonType } from 'antd/es/button';
export type ButtonVariant = 'secondary' | 'default' | 'primary' | 'success' | 'warning' | 'danger';

type ButtonPrimaryTokens = Partial<
	Record<
		| 'primaryColor'
		| 'colorPrimary'
		| 'colorPrimaryActive'
		| 'colorPrimaryHover'
		| 'defaultGhostBorderColor'
		| 'defaultGhostColor'
		| 'defaultBg'
		| 'defaultBorderColor'
		| 'defaultColor'
		| 'colorText'
		| 'textHoverBg',
		string
	>
>;

type ButtonThemes = Record<AppThemeMode, Record<ButtonVariant, ButtonPrimaryTokens>>;

export type ButtonType = Exclude<AntButtonType, 'primary'> | 'fill';

export function useButtonTheme(variant: ButtonVariant, type: ButtonType, ghost?: boolean) {
	const theme = useExtendedTheme<{}, { Button: ButtonPrimaryTokens }>(
		({ token }, cssKey) => {
			const themes: ButtonThemes = {
				dark: {
					warning: {
						colorPrimary: token.colorWarning,
						colorPrimaryActive: token.colorWarningActive,
						colorPrimaryHover: token.colorWarningHover,
						defaultGhostBorderColor: token.colorWarning,
						defaultGhostColor: token.colorWarning,
						defaultBorderColor: token.colorWarning,
						defaultColor: token.colorWarning,
						colorText: token.colorWarning,
						textHoverBg: token.colorWarningBgHover,
					},
					danger: {
						colorPrimary: token.colorError,
						colorPrimaryActive: token.colorErrorActive,
						colorPrimaryHover: token.colorErrorHover,
						defaultGhostBorderColor: token.colorError,
						defaultGhostColor: token.colorError,
						defaultBorderColor: token.colorError,
						defaultColor: token.colorError,
						colorText: token.colorError,
						textHoverBg: token.colorErrorBgHover,
					},
					default: {
						colorPrimary: token.colorTextBase,
						colorPrimaryActive: token.colorPrimaryHover,
						colorPrimaryHover: token.colorPrimaryActive,
						defaultGhostBorderColor: token.colorBorderSecondary,
						defaultGhostColor: token.colorTextBase,
						defaultBorderColor: token.colorBorderSecondary,
						defaultColor: token.colorTextBase,
						colorText: token.colorTextBase,
						textHoverBg: token.colorBorderSecondary,
					},
					primary: {
						colorPrimary: token.colorPrimary,
						colorPrimaryActive: token.colorPrimaryActive,
						colorPrimaryHover: token.colorPrimaryHover,
						defaultGhostBorderColor: token.colorPrimary,
						defaultGhostColor: token.colorPrimary,
						defaultBorderColor: token.colorPrimary,
						defaultColor: token.colorPrimary,
						colorText: token.colorPrimaryText,
						textHoverBg: token.colorPrimaryBgHover,
					},
					secondary: {
						colorPrimary: token.colorTextTertiary,
						colorPrimaryActive: token.colorTextSecondary,
						colorPrimaryHover: token.colorTextSecondary,
						defaultGhostBorderColor: token.colorTextTertiary,
						defaultGhostColor: token.colorTextTertiary,
						defaultBorderColor: token.colorTextTertiary,
						defaultColor: token.colorTextTertiary,
						colorText: token.colorTextTertiary,
						textHoverBg: token.colorBorderSecondary,
					},
					success: {
						colorPrimary: token.colorSuccess,
						colorPrimaryActive: token.colorSuccessActive,
						colorPrimaryHover: token.colorSuccessHover,
						defaultGhostBorderColor: token.colorSuccess,
						defaultGhostColor: token.colorSuccess,
						defaultBorderColor: token.colorSuccess,
						defaultColor: token.colorSuccess,
						colorText: token.colorSuccessText,
						textHoverBg: token.colorSuccessBgHover,
					},
				},
			};

			return {
				components: {
					Button: {
						...themes[cssKey][variant],
						colorBgContainerDisabled: ghost ? 'transparent' : undefined,
					},
				},
			};
		},
		[variant, ghost],
	);

	const generateSuffix = () => {
		const suffix = `${[variant, type].join('-')}` + (ghost ? '' : '-ghost');
		return suffix;
	};

	return {
		theme,
		prefix: `${theme.cssVar.key}-btn-${generateSuffix()}`,
	};
}
