import { CloseOutlined, HistoryOutlined, RightOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';

import { Button } from '@/shared/ui/Button';

import { MessengerModel } from '../../model/MessengerModel';

import { ChatBot } from './ChatBot';

import { ChatHistory } from './ChatHistory';

import styles from './ai-chat.module.scss';

type AIChatProps = {
	model: MessengerModel;
	type: 'ai' | 'chat';
};

export const AIChat = observer<AIChatProps>(function AIChat({ model, type }) {
	return (
		<div className={styles.sidebar}>
			<Tabs
				className={styles.tabs}
				items={[
					{
						key: 'chatBot',
						label: 'Чат-бот',
						children: <ChatBot model={model} />,
					},
					{
						key: 'history',
						label: (
							<>
								<HistoryOutlined className={styles.icon} /> История чатов
							</>
						),
						disabled: true,
						children: <ChatHistory chats={model.aiChat.items} />,
					},
				]}
				renderTabBar={(props, NavList) => (
					<>
						<NavList {...props} />
						<Button className={styles.button} onClick={model.AIChatIsOpen.disable}>
							{type === 'chat' ? (
								<RightOutlined className={styles.collapse_icon} />
							) : (
								<CloseOutlined className={styles.collapse_icon} />
							)}
						</Button>
					</>
				)}
			/>
		</div>
	);
});
