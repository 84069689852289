import { SecurityScanOutlined, TeamOutlined } from '@ant-design/icons';
import { Flex, Tag, TagProps } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { ChatCategory } from '../../model/MessengerModel';

import styles from './category-tag.module.scss';

export type Props = TagProps & {
	category: Omit<ChatCategory, 'important'>;
};

export const CategoryTag = observer<Props>(function CategoryTag({ category, className, ...props }) {
	return (
		<Tag className={classNames(styles.tag, className)} {...props}>
			{category === 'private' && (
				<Flex gap={8}>
					<TeamOutlined /> Личные
				</Flex>
			)}
			{category === 'incidents' && (
				<Flex gap={8}>
					<SecurityScanOutlined /> Инциденты
				</Flex>
			)}
		</Tag>
	);
});
