import { RouteObject } from 'react-router';

import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { NotFoundPage } from './NotFoundPage';

export const notFoundPageRoute: RouteObject = {
	path: ROUTE_PATHS.notFound.path,
	element: <NotFoundPage />,
};
