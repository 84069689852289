import { Repository } from '@/shared/lib/collections/Repository';

import { UserEntity } from '../UserEntity';

export class UserRepository extends Repository<UserEntity> {
	constructor() {
		super(function createItem(id: string) {
			return new UserEntity(id);
		});
	}
}
